import axios from '../helpers/apiHelper';
import { BASE_URL } from '../helpers/urls';

export const getDashboardCategory = async (queryParams = '') => {
  return await axios.get(`${BASE_URL}/dashboard/category?${queryParams}`);
};

export const getDashboardControls = async (queryParams = '') => {
  return await axios.get(`${BASE_URL}/dashboard/controls/?${queryParams}`);
};

export const getFSDashboardCategory = async (queryParams = '') => {
  return await axios.get(`${BASE_URL}/dashboard/fs/?${queryParams}`);
};

export const getFSControls = async (queryParams = '') => {
  return await axios.get(`${BASE_URL}/dashboard/fs-controls/?${queryParams}`);
};
