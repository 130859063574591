import { useState, useEffect, useCallback } from 'react';

export default function useComponentVisible(
  ref,
  selectedRecommendations,
  rejectedRecommendations
) {
  const [showNotification, setNotification] = useState(false);

  const handleClickOutside = useCallback(
    event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        (selectedRecommendations.length || rejectedRecommendations.length)
      ) {
        setNotification(true);
      } else {
        setNotification(false);
      }
    },
    [ref, selectedRecommendations?.length, rejectedRecommendations?.length]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return { showNotification };
}
