export const navigationItems = [
  {
    label: 'Mapping generation',
    open: true,
    items: [
      {
        label: 'Custom mapping',
        to: '/landingpage?tabId=custom',
      },
      {
        label: 'Predefined mapping',
        to: '/landingpage?tabId=predefined',
      },
    ],
  },
  {
    label: 'Crossmap',
    open: true,
    items: [
      {
        label: 'Custom mapping',
        to: '/crossmap?tabId=custom',
      },
      {
        label: 'Static mapping',
        to: '/crossmap?tabId=static',
      },
    ],
  },
  {
    label: 'Manage',
    open: true,
    items: [
      {
        label: 'User invitations',
        to: '/userInvitations',
      },
    ],
  },
];
