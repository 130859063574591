import React, { useState, useEffect } from 'react';

import './MappingPage.scss';
import {
  Card,
  CardHeader,
  CardBody,
  SidePanel,
  SidePanelContainer,
  CardSkeleton,
} from '@console/pal/Components';
import { getControlMetadata } from '../../api/MappingPage';
import startCase from 'lodash/startCase';

const MetaDataPanel = ({ controlItem, controlTitle = '', onClose }) => {
  const [controlMetaData, setControlMetaData] = useState('');
  const [controlLoader, setControlLoader] = useState(false);

  useEffect(() => {
    if (controlItem) {
      setControlLoader(true);
      getControlMetadata(controlItem)
        .then(result => {
          return result.data;
        })
        .then(data => {
          setControlMetaData(data['control-metadata']);
          setControlLoader(false);
        })
        .catch(err => {
          console.log('err ', err);
          setControlLoader(false);
        });
    }
  }, [controlItem]);

  return (
    <SidePanelContainer
      panelSize="medium"
      includeOverlay={true}
      onCloseClick={() => {
        onClose();
        setControlMetaData('');
      }}
      hideBottomNav={true}>
      <SidePanel title={controlTitle} id="panel-1">
        {controlLoader ? (
          <CardSkeleton stacked={false} />
        ) : (
          <div>
            {controlMetaData && controlMetaData.length > 0 ? (
              <div>
                {controlMetaData.map(x => {
                  return (
                    <Card>
                      <CardHeader
                        title={
                          x.title ||
                          startCase(x.type.replaceAll('_', ' ').toLowerCase())
                        }
                        small
                      />
                      <CardBody short>{x['description']}</CardBody>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <div>Sorry, there is no data!</div>
            )}
          </div>
        )}
      </SidePanel>
    </SidePanelContainer>
  );
};

export default MetaDataPanel;
