import React, { useState, useEffect } from 'react';
import {
  TextInput,
  Form,
  Select,
  SelectItem,
  FileUploader,
  TextArea,
  ProgressIndicator,
  ProgressStep,
  Stack,
  InlineNotification,
} from '@carbon/react';
import { Card, CardHeader, CardBody } from '@console/pal/Components';
import { Button, Row, Column } from '@console/pal/carbon-components-react';
import CustomBreadcrumb from '../common/Breadcrumb';
import { useHistory } from 'react-router-dom';
import { importMapping } from '../../api/ImportMapping.js';
import {
  validateMapping,
  getTargetProfiles,
  fileUpload,
} from '../../api/CreateMapping.js';
import { SOURCE_CONTROL_TYPES } from '../../config/mapping';
import { getEmailID } from '../../api/common';
import { getHost } from '../../helpers/urls';
import './ImportMapping.scss';

const COMPONENT_NAME = 'import-page';

const ImportMapping = props => {
  let { accountDetails = {} } = props;
  let { acc_id = '' } = accountDetails;
  const history = useHistory();
  const [importMappings, setImportMappings] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [targetProfiles, setTargetProfiles] = useState([]);
  const [targetVersions, setTargetVersions] = useState([]);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [error, showError] = useState(false);
  const [errorMessage, showErrorMessage] = useState({});

  useEffect(() => {
    getTargetProfiles().then(result => {
      setTargetProfiles(result?.data?.target_profiles);
    });
  }, []);

  const handleImportMappingChange = e => {
    setImportMappings({
      ...importMappings,
      [e.target.id]: e.target.value,
    });
  };

  const handleGenerateMapping = () => {
    setGenerateFlag(true);
    let formData = new FormData();

    formData.append('mapping_name', importMappings.mapping_name);
    formData.append('version', importMappings.version);
    formData.append('source_type', importMappings.sourceControlType);
    formData.append('target_type', importMappings.targetVersion);
    formData.append('source_profile_version', importMappings.sourceVersion);
    formData.append('target_profile_version', importMappings.targetType);
    formData.append('title', importMappings.description || '');

    window.analytics.track('Started Process', {
      'user.email': getEmailID(),
      'user.bluemixId': getEmailID(),
      productTitle: 'Crosswalks Tool',
      name: 'Import Mapping',
      processType: 'Generate Mapping',
      process: 'Generate',
      resultValue: 'Running mapping generation',
      successFlag: true,
      category: importMappings.mapping_name + '_' + importMappings.version,
      object: JSON.stringify(Object.fromEntries(formData)),
      milestoneName: 'Running mapping generation',
      url: getHost(),
    });

    formData.append('control_text_field', importMappings.controlText || '');
    formData.append('control_id_field', importMappings.controlId || '');
    formData.append(
      'source_category_field',
      importMappings.sourceCategory || ''
    );
    formData.append(
      'source_parent_id_field',
      importMappings.sourceParentId || ''
    );
    formData.append(
      'target_parent_id_field',
      importMappings.targetParentId || ''
    );
    formData.append('target_id_field', importMappings.targetId);
    formData.append('target_id', importMappings.targetId);
    formData.append('file', selectedFiles[0]);

    importMapping(formData, acc_id)
      .then(result => {
        setGenerateFlag(false);
        window.analytics.track('Ended Process', {
          'user.email': getEmailID(),
          'user.bluemixId': getEmailID(),
          productTitle: 'Crosswalks Tool',
          name: 'Import Mapping',
          processType: 'Generate Mapping',
          process: 'Generate',
          resultValue: 'Running mapping generation',
          successFlag: true,
          category: importMappings.mapping_name + '_' + importMappings.version,
          object: JSON.stringify(Object.fromEntries(formData)),
          milestoneName: 'Mapping generation completed',
          url: getHost(),
        });
        history.push('/landingpage');
      })
      .catch(error => {
        setGenerateFlag(false);
        showErrorMessage(error?.response?.data);
      });
  };

  const addFiles = evt => {
    setSelectedFiles(evt.target.files);
    generateMappingColumn(evt.target.files);
  };

  const removeFiles = () => {
    setSelectedFiles([]);
    setColumnList([]);
  };

  function fetchXlsxInfo(data) {
    if (data && data.length > 0) {
      let formData = new FormData();
      formData.append('file', data[0]);
      formData.append('format', 'xlsx');
      fileUpload(formData)
        .then(result => {
          setColumnList(result.data.column_names);
          window.analytics.track('Ran Process', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: 'Generate column names',
            object: JSON.stringify(Object.fromEntries(formData)),
            processType: 'Batch',
            process: 'Inferencing',
            successFlag: true,
            resultValue: 'AI model invoked',
            milestoneName: 'Control Mapping generation triggered',
            url: getHost(),
          });
        })
        .catch(error => console.log(error));
    } else {
      console.log('Please upload a file', '11rem');
    }
  }

  const generateMappingColumn = selectedFiles => {
    fetchXlsxInfo(selectedFiles);
  };

  const validateMappingName = () => {
    showError(false);
    let formData = {
      name: importMappings.mapping_name,
      version: importMappings.version,
      account_id: acc_id,
    };
    window.analytics.track('Started Process', {
      'user.email': getEmailID(),
      'user.bluemixId': getEmailID(),
      productTitle: 'Crosswalks Tool',
      name: 'Validate Mapping',
      processType: 'Validate Mapping',
      process: 'Validate',
      resultValue: 'Validate mapping generation name',
      successFlag: true,
      category: importMappings.mapping_name + '_' + importMappings.version,
      object: formData,
      milestoneName: 'Validate mapping generation name',
      url: getHost(),
    });
    validateMapping(formData)
      .then(result => {
        let { success = '' } = result.data || {};
        if (success === true) {
          setCurrentIndex(currentIndex + 1);
        } else {
          showError(true);
        }
        window.analytics.track('Ended Process', {
          'user.email': getEmailID(),
          'user.bluemixId': getEmailID(),
          productTitle: 'Crosswalks Tool',
          name: 'Validate Mapping',
          processType: 'Validate Mapping',
          process: 'Validate',
          resultValue: 'Validate mapping generation name',
          successFlag: true,
          category: importMappings.mapping_name + '_' + importMappings.version,
          object: formData,
          milestoneName: 'Validate mapping generation name completed',
          url: getHost(),
        });
      })
      .catch(err => {
        console.log(err);
        showError(true);
      });
  };

  const Buttons = () => {
    return (
      <Row>
        <Column lg={12}>
          <div className={`${COMPONENT_NAME}--buttons`}>
            <Button
              kind={'ghost'}
              size="xl"
              className={`${COMPONENT_NAME}--cancel`}
              onClick={() => history.push('/landingpage')}>
              Cancel
            </Button>

            <Button
              size="xl"
              className={`${COMPONENT_NAME}--next`}
              disabled={
                currentIndex === 0
                  ? !importMappings.mapping_name || !importMappings.version
                  : currentIndex === 1
                  ? !importMappings.sourceControlType ||
                    !importMappings.targetType ||
                    !importMappings.sourceVersion
                  : currentIndex === 2
                  ? !importMappings.controlId ||
                    !importMappings.controlText ||
                    !importMappings.sourceCategory ||
                    !importMappings.targetId
                  : currentIndex === 3
                  ? generateFlag
                  : false
              }
              onClick={() => {
                if (currentIndex < 3) {
                  if (currentIndex === 0) {
                    validateMappingName();
                  } else setCurrentIndex(currentIndex + 1);
                } else {
                  handleGenerateMapping();
                }
              }}>
              {currentIndex === 3 ? 'Import' : 'Next'}
            </Button>
            {currentIndex !== 0 && (
              <Button
                size="xl"
                kind="secondary"
                className={`${COMPONENT_NAME}--back`}
                onClick={() => setCurrentIndex(currentIndex - 1)}>
                Back
              </Button>
            )}
          </div>
        </Column>
      </Row>
    );
  };

  const mappingOptions = columnList.map((column, index) => (
    <SelectItem key={index} text={column} value={column} />
  ));

  let targetProfileVersion = targetVersions.find(
    target => target.profile_id === importMappings.targetVersion
  );

  return (
    <div id="import" className={`${COMPONENT_NAME}`}>
      <CustomBreadcrumb title="Import Mapping" id="create" />
      <div className={`${COMPONENT_NAME}--section`}>
        <div style={{ width: '60%' }}>
          <ProgressIndicator currentIndex={currentIndex} spaceEqually={true}>
            <ProgressStep
              label="Details"
              description="The progress indicator will listen for clicks on the steps"
            />
            <ProgressStep
              label="Type"
              description="The progress indicator will listen for clicks on the steps"
            />
            <ProgressStep
              label="Upload Mapping"
              description="The progress indicator will listen for clicks on the steps"
            />
            <ProgressStep
              label="Review"
              description="The progress indicator will listen for clicks on the steps"
            />
          </ProgressIndicator>
          {error ? (
            <div className="m-top">
              <InlineNotification
                lowContrast
                timeout={6000}
                kind="warning"
                title="Mapping name already exists!"
                subtitle=""
              />
            </div>
          ) : (
            <>
              {errorMessage && errorMessage.err && (
                <div className="m-top">
                  <InlineNotification
                    lowContrast
                    timeout={3000}
                    kind="warning"
                    title={errorMessage.err}
                    subtitle=""
                  />
                </div>
              )}{' '}
            </>
          )}

          <Form>
            <Stack gap={5}>
              {currentIndex === 0 ? (
                <div className={`${COMPONENT_NAME}--form-items`}>
                  <div className="m-top" />
                  <p
                    style={{
                      fontSize: '13px',
                      marginBottom: '1rem',
                    }}>
                    Provide a unique name and a description that will help you
                    and other members of your organization to know what is
                    included in the mapping. For example, IBM Cloud services.
                  </p>
                  <TextInput
                    id="mapping_name"
                    required
                    labelText="Name*"
                    onChange={e => handleImportMappingChange(e)}
                    value={importMappings.mapping_name}
                  />
                  <div className="m-top" />
                  <TextInput
                    id="version"
                    required
                    labelText="Version*"
                    onChange={e => handleImportMappingChange(e)}
                    value={importMappings.version}
                  />
                  <div className="m-top" />
                  <TextArea
                    id="description"
                    labelText="Description (optional)"
                    maxCount={100}
                    enableCounter={true}
                    onChange={e => handleImportMappingChange(e)}
                    value={importMappings.description}
                  />
                </div>
              ) : currentIndex === 1 ? (
                <div className={`${COMPONENT_NAME}--form-items`}>
                  <div className="m-top">
                    <Select
                      defaultValue="placeholder-item"
                      id="sourceControlType"
                      onChange={e => handleImportMappingChange(e)}
                      labelText="Source Control Type"
                      value={importMappings.sourceControlType}
                      size="md">
                      <SelectItem
                        disabled
                        hidden
                        text="Choose an option"
                        value="placeholder-item"
                      />
                      {SOURCE_CONTROL_TYPES.map((mapping, index) => (
                        <SelectItem
                          text={mapping.text}
                          value={mapping.value}
                          key={index}
                        />
                      ))}
                    </Select>
                  </div>

                  <div className="m-top">
                    <Select
                      defaultValue="placeholder-item"
                      id="targetType"
                      onChange={e => {
                        let targetVersionList = targetProfiles?.find(
                          x => x.profile_name === e.target.value
                        );
                        setTargetVersions(targetVersionList?.profile_versions);
                        setImportMappings({
                          ...importMappings,
                          targetType: e.target.value,
                          targetVersion:
                            targetVersionList?.profile_versions[0]?.profile_id,
                        });
                      }}
                      labelText="Target Type"
                      value={importMappings.targetType}
                      size="md">
                      <SelectItem
                        disabled
                        hidden
                        text="Choose an option"
                        value="placeholder-item"
                      />
                      {targetProfiles?.map((target, index) => (
                        <SelectItem
                          key={index}
                          text={target.profile_name}
                          value={target.profile_name}
                        />
                      ))}
                    </Select>
                  </div>
                  <div className="m-top">
                    <TextInput
                      id="sourceVersion"
                      required
                      labelText="Source Version"
                      onChange={e => handleImportMappingChange(e)}
                      value={importMappings.sourceVersion}
                    />
                  </div>

                  <div className="m-top">
                    <Select
                      defaultValue="placeholder-item"
                      id="targetVersion"
                      onChange={e => {
                        setImportMappings({
                          ...importMappings,
                          targetVersion: e.target.value,
                        });
                      }}
                      labelText="Target Version"
                      value={importMappings.targetVersion}
                      size="md">
                      <SelectItem
                        disabled
                        hidden
                        text="Choose an option"
                        value="placeholder-item"
                      />
                      {targetVersions.map((target, index) => (
                        <SelectItem
                          key={index}
                          text={target.Version}
                          value={target.profile_id}
                        />
                      ))}
                    </Select>
                  </div>
                </div>
              ) : currentIndex === 2 ? (
                <div className={`${COMPONENT_NAME}--form-items`}>
                  <div className="m-top">
                    <div className="m-top">
                      <div className="cds--file__container">
                        <FileUploader
                          labelTitle="Upload mapping file"
                          buttonLabel="Select file"
                          labelDescription="only .xlsx files"
                          filenameStatus="edit"
                          multiple={false}
                          buttonKind="tertiary"
                          onChange={addFiles}
                          onDelete={removeFiles}
                          onClick={removeFiles}
                          iconDescription="Clear file"
                          accept={['.xlsx']}
                        />
                      </div>
                      {columnList.length > 0 && (
                        <div>
                          <p className="f-weight">
                            Please select matching columns from the Excel for
                            control id, title and description
                          </p>

                          <div className="m-top">
                            <Select
                              id="controlId"
                              onChange={e => handleImportMappingChange(e)}
                              labelText="Select matching column for source control id"
                              value={
                                importMappings.controlId || 'placeholder-item'
                              }
                              size="md">
                              <SelectItem
                                disabled
                                hidden
                                text="Choose an option"
                                value="placeholder-item"
                              />
                              {mappingOptions}
                            </Select>
                          </div>

                          <div className="m-top">
                            <Select
                              id="controlText"
                              onChange={e => handleImportMappingChange(e)}
                              labelText="Select matching column for source control text"
                              value={
                                importMappings.controlText || 'placeholder-item'
                              }
                              size="md">
                              <SelectItem
                                disabled
                                hidden
                                text="Choose an option"
                                value="placeholder-item"
                              />
                              {mappingOptions}
                            </Select>
                          </div>

                          <div className="m-top">
                            <Select
                              id="sourceCategory"
                              onChange={e => handleImportMappingChange(e)}
                              labelText="Select matching column for source category"
                              value={
                                importMappings.sourceCategory ||
                                'placeholder-item'
                              }
                              size="md">
                              <SelectItem
                                disabled
                                hidden
                                text="Choose an option"
                                value="placeholder-item"
                              />
                              {mappingOptions}
                            </Select>
                          </div>

                          <div className="m-top">
                            <Select
                              id="targetId"
                              onChange={e => handleImportMappingChange(e)}
                              labelText="Select matching column for target control id"
                              value={
                                importMappings.targetId || 'placeholder-item'
                              }
                              size="md">
                              <SelectItem
                                disabled
                                hidden
                                text="Choose an option"
                                value="placeholder-item"
                              />
                              {mappingOptions}
                            </Select>
                          </div>
                          <div className="m-top">
                            <Select
                              id="sourceParentId"
                              onChange={e => handleImportMappingChange(e)}
                              labelText="Select matching column for source parent id (optional)"
                              value={
                                importMappings.sourceParentId ||
                                'placeholder-item'
                              }
                              size="md">
                              <SelectItem
                                disabled
                                hidden
                                text="Choose an option"
                                value="placeholder-item"
                              />
                              {mappingOptions}
                            </Select>
                          </div>

                          <div className="m-top">
                            <Select
                              id="targetParentId"
                              onChange={e => handleImportMappingChange(e)}
                              labelText="Select matching column for target parent id (optional)"
                              value={
                                importMappings.targetParentId ||
                                'placeholder-item'
                              }
                              size="md">
                              <SelectItem
                                disabled
                                hidden
                                text="Choose an option"
                                value="placeholder-item"
                              />
                              {mappingOptions}
                            </Select>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="m-top" />
                  <Card>
                    <CardHeader
                      title="Details"
                      onEditClick={() => {
                        setCurrentIndex(0);
                      }}
                      onSaveClick={() => {}}
                      onCancelClick={() => {}}
                      editText="Edit"
                      saveText="Save"
                    />
                    <CardBody>
                      <Row>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Name
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.mapping_name}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Version
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.version || '-'}
                          </div>
                        </Column>
                        <Column lg={6}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Description
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.description || '-'}
                          </div>
                        </Column>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader
                      title="Type"
                      onEditClick={() => {
                        setCurrentIndex(1);
                      }}
                      onSaveClick={() => {}}
                      onCancelClick={() => {}}
                      editText="Edit"
                      saveText="Save"
                    />
                    <CardBody>
                      <Row>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Source type
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.sourceControlType.replaceAll(
                              '_',
                              ' '
                            )}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Target profile
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.targetType || '-'}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Source version
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.sourceVersion || '-'}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Target version
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {targetProfileVersion.Version || '-'}
                          </div>
                        </Column>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader
                      title="Upload Mapping"
                      onEditClick={() => {
                        setCurrentIndex(2);
                      }}
                      onSaveClick={() => {}}
                      onCancelClick={() => {}}
                      editText="Edit"
                      saveText="Save"
                    />
                    <CardBody>
                      <Row>
                        <Column lg={8}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Source control id
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.controlId || '-'}
                          </div>
                        </Column>
                        <Column lg={8}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Source control text
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.controlText || '-'}
                          </div>
                        </Column>
                        <Column lg={8}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Source category
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.sourceCategory || '-'}
                          </div>
                        </Column>
                        <Column lg={8}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Target control id
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.targetId || '-'}
                          </div>
                        </Column>
                        <Column lg={8}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Source parent id
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.sourceParentId || '-'}
                          </div>
                        </Column>
                        <Column lg={8}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Target parent id
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {importMappings.targetParentId || '-'}
                          </div>
                        </Column>
                        <Column lg={8}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Filename
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {selectedFiles[0].name}
                          </div>
                        </Column>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              )}
            </Stack>
          </Form>
          <Buttons />
        </div>
      </div>
    </div>
  );
};
export default ImportMapping;
