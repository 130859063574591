import React, { useState, useEffect } from 'react';
import {
  Select,
  SelectItem,
  RadioButtonGroup,
  RadioButton,
  ComboBox,
  Tooltip,
  Button,
  TextInput,
} from '@carbon/react';
import './MappingPage.scss';
import { SidePanel, SidePanelContainer } from '@console/pal/Components';
import { assignUserRole, getUserRoles } from '../../api/LandingPage';
import {
  focusAreaCategories,
  relationStatusCategories,
} from './MappingPageConfig';
import { getEmailID } from '../../api/common';
import { getHost } from '../../helpers/urls';
import Comments from './Comments';
import { Warning } from '@carbon/icons-react';
import { NestedFilterableDropdown } from '@console/pal/Components';
import { editCategory } from '../../api/MappingPage';

const ControlClassificationPanel = ({
  mid,
  accountId,
  panelType,
  mDetails,
  editMappingRecommendation,
  mappingPayload,
  selectedMappingDetails,
  setMappingPayload,
  refreshData,
  setSidePanel,
  READ_ONLY,
}) => {
  const [reviewer, setReviewer] = useState('');
  const [approver, setApprover] = useState('');
  const [reviewerName, setReviewerName] = useState('');
  const [approverName, setApproverName] = useState('');
  const [reviewers, setReviewers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [typeOfOperation, setTypeOfOperation] = useState('');
  const [newCategory, setNewCategory] = useState({});
  const [categorySaved, setCategorySaved] = useState(false);

  useEffect(() => {
    if (accountId && panelType === 'assignUsers') {
      getUserRoles(accountId).then(result => {
        let { users = [] } = result.data;
        let reviewersList = [];
        let approversList = [];
        for (let list of users) {
          let { roles = [] } = list;
          if (roles.includes('REVIEWER')) {
            reviewersList.push(list);
          }
          if (roles.includes('APPROVER')) {
            approversList.push(list);
          }
        }
        setReviewers(reviewersList);
        setApprovers(approversList);
      });
    }
  }, [accountId, panelType]);

  useEffect(() => {
    if (mDetails?.category) {
      let categoryList = [];
      for (let items of mDetails?.category) {
        let obj1 = {
          category: 'Select category',
          id: items?.type?.replace('_', ' '),
          label: items?.type,
          value: items?.type,
        };
        if (items?.category?.length > 0) categoryList.push(obj1);
        for (let list of items?.category) {
          let obj = {
            category: 'Select category',
            id: list,
            label: list,
            tag: items?.type?.replace('_', ' '),
            value: list,
            parentId: items?.type?.replace('_', ' '),
          };
          categoryList.push(obj);
        }
      }
      setCategories(categoryList);

      let selectedCategory =
        mappingPayload.length &&
        mappingPayload.find(x => x.control_id === selectedMappingDetails.id)
          ? mappingPayload.find(x => x.control_id === selectedMappingDetails.id)
              ?.control_category
          : selectedMappingDetails.control_category;

      let initialCategory = categoryList.filter(x => x.id === selectedCategory);
      setSelectedCategory(initialCategory);
    }
  }, [mappingPayload, selectedMappingDetails, mDetails?.category]);

  const handleChange = e => {
    setNewCategory({
      ...newCategory,
      [e.target.id]: e.target.value,
    });
  };

  const handleRadioButtonChange = e => {
    let classificationList = [...mappingPayload];

    let subControls =
      mDetails?.control_hierarchy[selectedMappingDetails.id]?.sub_control_ids;

    let details = {
      control_category:
        mappingPayload.length &&
        mappingPayload.find(x => x.control_id === selectedMappingDetails.id)
          ? mappingPayload.find(x => x.control_id === selectedMappingDetails.id)
              ?.control_category
          : selectedMappingDetails.control_category,
      control_id: selectedMappingDetails.id,
      sub_control_ids: subControls?.toString(),
    };

    if (e === 'override_child_category') {
      details['override_child_category'] = true;
    } else {
      details['set_default_category'] = true;
    }
    let i = classificationList.findIndex(
      x => x.control_id === selectedMappingDetails.id
    );

    if (i >= 0) {
      let data = classificationList.find(
        x => x.control_id === selectedMappingDetails.id
      );
      if (e === 'override_child_category') {
        data['sub_control_ids'] = subControls?.toString();
        data['control_category'] =
          mappingPayload.length &&
          mappingPayload.find(x => x.control_id === selectedMappingDetails.id)
            ? mappingPayload.find(
                x => x.control_id === selectedMappingDetails.id
              )?.control_category
            : selectedMappingDetails.control_category;
        data['override_child_category'] = true;
        delete data['set_default_category'];
      } else {
        data['sub_control_ids'] = subControls?.toString();
        data['control_category'] =
          mappingPayload.length &&
          mappingPayload.find(x => x.control_id === selectedMappingDetails.id)
            ? mappingPayload.find(
                x => x.control_id === selectedMappingDetails.id
              )?.control_category
            : selectedMappingDetails.control_category;
        data['set_default_category'] = true;
        delete data['override_child_category'];
      }
      classificationList.splice(i, 1);
      classificationList.push(data);
      setMappingPayload(classificationList);
    } else {
      classificationList.push(details);
      setMappingPayload(classificationList);
    }
  };

  const assignUserRoles = formData => {
    assignUserRole(formData, mid)
      .then(res => {
        setSidePanel(false);
        refreshData();
      })
      .catch(err => {
        setSidePanel(false);
      });
  };

  return (
    <SidePanelContainer
      panelSize="medium"
      includeOverlay={true}
      doneText="Save"
      onDoneClick={() => {
        if (panelType === 'classifications') {
          let data = {
            mapping_recommendation: mappingPayload,
          };
          editMappingRecommendation(data);
        } else if (panelType === 'assignUsers') {
          if (reviewer) {
            let formData = {
              user_id: reviewer,
              type: 'REVIEWER',
            };
            assignUserRoles(formData);
          }
          if (approver) {
            let formData = {
              user_id: approver,
              type: 'APPROVER',
            };
            assignUserRoles(formData);
          }
          window.analytics.track('Updated Object', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: 'Assign/ Unassign',
            data: {
              r_user_id: reviewer,
              r_type: 'REVIEWER',
              a_user_id: approver,
              a_type: 'APPROVER',
            },
            object: {
              r_user_id: reviewer,
              r_type: 'REVIEWER',
              a_user_id: approver,
              a_type: 'APPROVER',
            },
            objectType: 'json',
            successFlag: true,
            resultValue: 'Assign/ Unassign users saved',
            milestoneName: 'Assign/ Unassign users saved',
            url: getHost(),
          });
        }
        setSidePanel(false);
      }}
      onCloseClick={() => setSidePanel(false)}>
      <SidePanel
        title={
          panelType === 'comments'
            ? 'Comments'
            : panelType === 'assignUsers'
            ? 'Assign/ Unassign users'
            : 'Control classification'
        }
        id="panel-1">
        {panelType === 'comments' ? (
          <Comments
            accountId={accountId}
            comments={selectedMappingDetails['comments']}
            id={selectedMappingDetails.id}
            // READ_ONLY={
            //   READ_ONLY &&
            //   (mDetails.approver &&
            //     !mDetails.approver.includes(
            //       accountDetails.default_acc_name
            //     ))
            // }
            type="side-panel"
            refreshData={refreshData}
            mid={mid}
          />
        ) : (
          <>
            {panelType === 'assignUsers' ? (
              <>
                <ComboBox
                  onChange={e => {
                    if (e.selectedItem) {
                      setReviewer(e.selectedItem.id);
                      setReviewerName(e.selectedItem.name);
                    }
                  }}
                  id="reviewer-combobox"
                  items={reviewers}
                  selectedItem={{
                    name: reviewerName
                      ? reviewerName
                      : mDetails.reviewer && mDetails.reviewer.length
                      ? mDetails.reviewer[0]
                      : '',
                  }}
                  itemToString={item => (item ? item.name : '')}
                  titleText="Reviewer"
                  placeholder="Select Reviewer"
                />
                <br />
                <ComboBox
                  id="approver-combobox"
                  titleText="Approver"
                  placeholder="Select Approver"
                  items={approvers}
                  selectedItem={{
                    name: approverName
                      ? approverName
                      : mDetails.approver && mDetails.approver.length
                      ? mDetails.approver[0]
                      : '',
                  }}
                  itemToString={item => (item ? item.name : '')}
                  onChange={e => {
                    if (e.selectedItem) {
                      setApprover(e.selectedItem.id);
                      setApproverName(e.selectedItem.name);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <Select
                  id="select-1"
                  disabled={READ_ONLY}
                  labelText="Select focus area"
                  value={
                    mappingPayload.length &&
                    mappingPayload.find(
                      x => x.control_id === selectedMappingDetails.id
                    )
                      ? mappingPayload.find(
                          x => x.control_id === selectedMappingDetails.id
                        ).focus_area
                      : selectedMappingDetails.focus_area
                  }
                  onChange={e => {
                    let classificationList = [...mappingPayload];
                    let details = {
                      focus_area: e.target.value,
                      control_id: selectedMappingDetails.id,
                    };
                    let i = classificationList.findIndex(
                      x => x.control_id === selectedMappingDetails.id
                    );

                    if (i >= 0) {
                      let data = classificationList.find(
                        x => x.control_id === selectedMappingDetails.id
                      );
                      data['focus_area'] = e.target.value;
                      classificationList.splice(i, 1);
                      classificationList.push(data);
                      setMappingPayload(classificationList);
                    } else {
                      classificationList.push(details);
                      setMappingPayload(classificationList);
                    }
                  }}
                  size="md">
                  {focusAreaCategories.map((category, i) => (
                    <SelectItem
                      key={i}
                      text={category.replaceAll('_', ' ')}
                      value={category}
                    />
                  ))}
                </Select>
                <br />
                <Select
                  id="select-2"
                  disabled={READ_ONLY}
                  labelText="Alignment status"
                  value={
                    mappingPayload.length &&
                    mappingPayload.find(
                      x => x.control_id === selectedMappingDetails.id
                    )
                      ? mappingPayload.find(
                          x => x.control_id === selectedMappingDetails.id
                        ).relation_status
                      : selectedMappingDetails.relation_status
                  }
                  onChange={e => {
                    let classificationList = [...mappingPayload];
                    let details = {
                      relation_status: e.target.value,
                      control_id: selectedMappingDetails.id,
                    };
                    let i = classificationList.findIndex(
                      x => x.control_id === selectedMappingDetails.id
                    );

                    if (i >= 0) {
                      let data = classificationList.find(
                        x => x.control_id === selectedMappingDetails.id
                      );
                      data['relation_status'] = e.target.value;
                      classificationList.splice(i, 1);
                      classificationList.push(data);
                      setMappingPayload(classificationList);
                    } else {
                      classificationList.push(details);
                      setMappingPayload(classificationList);
                    }
                  }}
                  size="md">
                  {relationStatusCategories.map((category, i) => (
                    <SelectItem
                      key={i}
                      text={category.replaceAll('_', ' ')}
                      value={category}
                    />
                  ))}
                </Select>
                <br />
                <div className="warningIcon">
                  Control category{' '}
                  <Tooltip
                    align="bottom"
                    label={'Control category is mandatory field!'}>
                    <Button disabled={READ_ONLY} kind={'ghost'} size="sm">
                      <Warning
                        size={20}
                        style={{
                          fill: '#333',
                          cursor: 'pointer',
                        }}
                      />
                    </Button>
                  </Tooltip>
                </div>
                <NestedFilterableDropdown
                  disabled={READ_ONLY}
                  id="nested-list-selector"
                  mode="single"
                  items={categories}
                  onChange={function noRefCheck(e) {
                    let value = e.selectedItems?.[0]?.value;
                    let classificationList = [...mappingPayload];
                    let subControls =
                      mDetails?.control_hierarchy[selectedMappingDetails.id]
                        ?.sub_control_ids;
                    let details = {
                      control_category: value,
                      control_id: selectedMappingDetails.id,
                    };
                    let i = classificationList.findIndex(
                      x => x.control_id === selectedMappingDetails.id
                    );

                    if (i >= 0) {
                      let data = classificationList.find(
                        x => x.control_id === selectedMappingDetails.id
                      );
                      data['control_category'] = value;
                      data['sub_control_ids'] = subControls?.toString();

                      classificationList.splice(i, 1);
                      classificationList.push(data);
                      setMappingPayload(classificationList);
                    } else {
                      classificationList.push(details);
                      setMappingPayload(classificationList);
                    }
                  }}
                  initialSelectedItems={selectedCategory}
                />
                <br />

                {selectedMappingDetails.is_parent && (
                  <RadioButtonGroup
                    legendTest="Group Legend"
                    name="radio-button-group"
                    defaultSelected={
                      mappingPayload.length &&
                      mappingPayload.find(
                        x => x.control_id === selectedMappingDetails.id
                      )
                        ? mappingPayload.find(
                            x => x.control_id === selectedMappingDetails.id
                          )?.override_child_category
                        : selectedMappingDetails.inherit_category
                        ? 'override_child_category'
                        : '' || false
                    }
                    onChange={e => handleRadioButtonChange(e)}
                    orientation="vertical">
                    <RadioButton
                      id="radio-1"
                      labelText="Override sub-controls with same category"
                      value="override_child_category"
                      disabled={
                        READ_ONLY ||
                        selectedMappingDetails.control_category === null
                      }
                    />
                    <RadioButton
                      id="radio-2"
                      labelText="Restore to default"
                      value="set_default_category"
                      disabled={
                        READ_ONLY ||
                        selectedMappingDetails.inherit_category === false
                      }
                    />
                  </RadioButtonGroup>
                  // <Checkbox
                  //   id="checkbox-1"
                  //   labelText="Override sub-controls with same category"
                  //   defaultChecked={
                  //     mappingPayload.length &&
                  //     mappingPayload.find(
                  //       x => x.control_id === selectedMappingDetails.id
                  //     )
                  //       ? mappingPayload.find(
                  //           x => x.control_id === selectedMappingDetails.id
                  //         )?.override_child_category
                  //       : selectedMappingDetails.inherit_category || false
                  //   }
                  //   disabled={READ_ONLY}
                  //   value={
                  //     mappingPayload.length &&
                  //     mappingPayload.find(
                  //       x => x.control_id === selectedMappingDetails.id
                  //     )
                  //       ? mappingPayload.find(
                  //           x => x.control_id === selectedMappingDetails.id
                  //         )?.override_child_category
                  //       : selectedMappingDetails.inherit_category
                  //   }
                  //   onChange={e => {
                  //     let classificationList = [...mappingPayload];

                  //     let subControls =
                  //       mDetails?.control_hierarchy[selectedMappingDetails.id]
                  //         ?.sub_control_ids;

                  //     let details = {
                  //       control_category:
                  //         mappingPayload.length &&
                  //         mappingPayload.find(
                  //           x => x.control_id === selectedMappingDetails.id
                  //         )
                  //           ? mappingPayload.find(
                  //               x => x.control_id === selectedMappingDetails.id
                  //             )?.control_category
                  //           : selectedMappingDetails.control_category,
                  //       control_id: selectedMappingDetails.id,
                  //       sub_control_ids: subControls?.toString(),
                  //     };

                  //     if (!e.target.checked) {
                  //       details['override_child_category'] = true;
                  //     } else {
                  //       details['override_child_category'] = true;
                  //     }
                  //     let i = classificationList.findIndex(
                  //       x => x.control_id === selectedMappingDetails.id
                  //     );

                  //     if (i >= 0) {
                  //       let data = classificationList.find(
                  //         x => x.control_id === selectedMappingDetails.id
                  //       );
                  //       if (e.target.checked) {
                  //         data['sub_control_ids'] = subControls?.toString();
                  //         data['control_category'] =
                  //           mappingPayload.length &&
                  //           mappingPayload.find(
                  //             x => x.control_id === selectedMappingDetails.id
                  //           )
                  //             ? mappingPayload.find(
                  //                 x =>
                  //                   x.control_id === selectedMappingDetails.id
                  //               )?.control_category
                  //             : selectedMappingDetails.control_category;
                  //         data['override_child_category'] = true;
                  //       } else {
                  //         delete data['sub_control_ids'];
                  //         delete data['override_child_category'];
                  //       }
                  //       classificationList.splice(i, 1);
                  //       classificationList.push(data);
                  //       setMappingPayload(classificationList);
                  //     } else {
                  //       classificationList.push(details);
                  //       setMappingPayload(classificationList);
                  //     }
                  //   }}
                  // />
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}>
                  <Button
                    kind="ghost"
                    size="field"
                    onClick={() => {
                      setTypeOfOperation('add');
                    }}>
                    Add
                  </Button>{' '}
                  /{' '}
                  <Button
                    kind="ghost"
                    size="field"
                    onClick={() => {
                      setTypeOfOperation('edit');
                    }}>
                    Edit category
                  </Button>
                </div>

                {categorySaved && (
                  <div className="success-text">
                    Category saved successfully!
                  </div>
                )}

                {typeOfOperation && !categorySaved && (
                  <div className="category-section">
                    {typeOfOperation === 'add' ? (
                      <TextInput
                        required={true}
                        id="category"
                        type="text"
                        labelText={
                          typeOfOperation === 'edit'
                            ? 'Old category'
                            : 'Category name'
                        }
                        onChange={e => handleChange(e)}
                      />
                    ) : (
                      <>
                        <TextInput
                          required={true}
                          id="oldCategory"
                          type="text"
                          labelText={
                            typeOfOperation === 'edit'
                              ? 'Old category'
                              : 'Category name'
                          }
                          onChange={e => handleChange(e)}
                        />
                        <TextInput
                          required={true}
                          id="newCategory"
                          type="text"
                          labelText="New category"
                          onChange={e => handleChange(e)}
                        />
                      </>
                    )}
                    <Button
                      kind="primary"
                      size="field"
                      onClick={() => {
                        let payload = {};
                        if (typeOfOperation === 'add') {
                          payload['category'] = newCategory?.category;
                        } else {
                          payload['category'] = newCategory?.newCategory;
                          payload['old_category'] = newCategory?.oldCategory;
                          payload['source_profile_id'] =
                            mDetails.source_profile_id;
                        }
                        editCategory(accountId, payload)
                          .then(res => {
                            return res.data;
                          })
                          .then(res => {
                            if (res?.success) setCategorySaved(true);
                          })
                          .catch(err => {
                            console.log(err);
                          });
                      }}>
                      Save
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </SidePanel>
    </SidePanelContainer>
  );
};

export default ControlClassificationPanel;
