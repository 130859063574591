import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  EnhancedDataTable,
} from '@console/pal/Components';
import { Row, Column } from '@console/pal/carbon-components-react';
import CustomBreadcrumb from '../common/Breadcrumb';
import { useParams } from 'react-router-dom';
import { getFSDashboardCategory, getFSControls } from '../../api/Dashboard';
import './cloudDashboard.scss';
import {
  DonutChart,
  StackedBarChart,
  SimpleBarChart,
} from '@carbon/charts-react';
import '@carbon/charts/styles.css';
import startCase from 'lodash/startCase';
import _ from 'lodash';
import { Loading } from '@carbon/react';

const COMPONENT_NAME = 'fs-cloud-dashboard';

const donutOptions = {
  resizable: false,
  tooltip: {
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 50,
    },
  },
  legend: {
    alignment: 'center',
    truncation: {
      type: 'mid_line',
      threshold: 25,
      numCharacter: 20,
    },
  },
  donut: {
    center: {
      label: 'Controls',
    },
    alignment: 'center',
  },
  color: {
    scale: {
      'Fs Additional Controls': 'cornflowerblue',
      'Bank Coverage': '#389e6a',
    },
  },
  height: '400px',
};

const donutOptions1 = {
  resizable: false,
  tooltip: {
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 50,
    },
  },
  legend: {
    alignment: 'center',
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 50,
    },
  },
  donut: {
    center: {
      label: 'Controls',
    },
    alignment: 'center',
  },
  color: {
    scale: {
      'Fs Additional Controls': 'cornflowerblue',
      'Bank Coverage': '#389e6a',
    },
  },
  height: '300px',
};

const stackOptions = {
  tooltip: {
    showTotal: true,
    totalLabel: 'Total',
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 50,
    },
  },
  axes: {
    left: {
      scaleType: 'labels',
      truncation: {
        type: 'mid_line',
        threshold: 50,
        numCharacter: 45,
      },
    },
    bottom: {
      stacked: true,
    },
  },
  legend: {
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 45,
    },
  },
  color: {
    scale: {
      "Bank's Coverage": '#389e6a',
      'IBM FS Cloud additional controls': 'cornflowerblue',
      'IBM Cloud FS additional controls': 'cornflowerblue',
    },
  },
  height: '400px',
};

const barOptions = {
  tooltip: {
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 50,
    },
  },
  axes: {
    left: {
      mapsTo: 'group',
      scaleType: 'labels',
      truncation: {
        type: 'mid_line',
        threshold: 50,
        numCharacter: 45,
      },
    },
    bottom: {
      mapsTo: 'value',
    },
  },
  legend: {
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 45,
    },
  },
  color: {
    scale: {
      'Access Control': 'cornflowerblue',
      'System And Services Acquisition': 'cornflowerblue',
      'System And Communications Protection': 'cornflowerblue',
      'System And Information Integrity': 'cornflowerblue',
      'Configuration Management': 'cornflowerblue',
    },
  },
  height: '400px',
};

const FSCloudDashboard = props => {
  const { mid = '', type = '' } = useParams();

  const [donutData, setDonutData] = useState([]);
  const [stackData, setStackedData] = useState([]);
  const [focusAreaCFData, setFocusAreaCFData] = useState([]);
  const [coverageFocusData, setCoverageFocusData] = useState([]);
  const [fsFrameworkDetails, setFsFrameworkDetails] = useState([]);
  const [focusArea, setFocusArea] = useState([]);
  const [controlFamily, setControlFamily] = useState([]);
  const [controlsData, setControlsData] = useState({});
  const [barData, setBarData] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const barChartRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [showPageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    let queryParams = {};
    if (mid && mid !== 'overview') {
      queryParams = { ...queryParams, mapping_request_id: mid };
    }
    const query = new URLSearchParams(queryParams).toString();
    setPageLoader(true);
    getFSDashboardCategory(query)
      .then(result => {
        let { data = {} } = result;
        let {
          fs_category_coverage = [],
          fs_total_coverage = [],
          top_control_family = [],
          focus_area_control_family = [],
        } = data;
        let bankControlsMapping = [];
        let controlAlignmentByDomain = [];
        let donutControlData = [];
        setFocusAreaCFData(focus_area_control_family);
        for (let list in fs_total_coverage) {
          let obj = {
            group: startCase(list.replaceAll('_', ' ').toLowerCase()),
            value: fs_total_coverage[list],
          };
          donutControlData.push(obj);
        }
        for (let list in top_control_family) {
          let { fs_additional_controls = '', name = '' } = top_control_family[
            list
          ];
          let obj = {
            group: startCase(name.toLowerCase()),
            value: fs_additional_controls,
          };
          bankControlsMapping.push(obj);
        }
        setBarData(bankControlsMapping);
        for (let data of fs_category_coverage) {
          let {
            name = '',
            title = '',
            mapped_count = '',
            fs_additional_controls = '',
          } = data;
          let bankObj = {};
          bankObj['group'] = `Bank's Coverage`;
          bankObj['key'] = startCase(title.toLowerCase()) + ' (' + name + ')';
          bankObj['value'] = mapped_count;
          let additionalControlsObj = {};
          additionalControlsObj['group'] = `IBM Cloud FS additional controls`;
          additionalControlsObj['key'] =
            startCase(title.toLowerCase()) + ' (' + name + ')';
          additionalControlsObj['value'] = fs_additional_controls;
          controlAlignmentByDomain.push(bankObj);
          controlAlignmentByDomain.push(additionalControlsObj);
        }

        let coverageByFocusData = _.chain(controlAlignmentByDomain)
          .groupBy('key')
          .map((value, key) => ({ name: key, value: value }))
          .value();
        setCoverageFocusData(coverageByFocusData);
        setControlsData(fs_total_coverage);
        setDonutData(donutControlData);
        setStackedData(controlAlignmentByDomain);
        setPageLoader(false);
      })
      .catch(err => {
        setPageLoader(false);
        console.log('err ', err);
      });

    getFSControls(query)
      .then(result => {
        setLoader(true);
        let { data = {} } = result;
        let modifiedData = [];
        let focusAreas = [];
        let controlFamilies = [];
        let count = 1;
        for (let list of data) {
          let {
            control_family: { name: cName = '', title: cTitle = '' } = {},
            description = '',
            name = '',
            title = '',
            focus_category = '',
          } = list;
          let focusCategoryStr = startCase(
            focus_category.replaceAll('_', ' ').toLowerCase()
          );
          let controlFamilyStr = `${startCase(
            cTitle.toLowerCase()
          )} (${cName})`;

          let obj = {
            id: count,
            name,
            title: startCase(title.toLowerCase()),
            description,
            focusArea: focusCategoryStr,
            controlFamily: controlFamilyStr,
          };
          count++;
          modifiedData.push(obj);

          if (focusAreas.findIndex(x => x.id === focusCategoryStr) === -1) {
            let obj = {
              id: focusCategoryStr,
              label: focusCategoryStr,
            };
            focusAreas.push(obj);
          }
          if (
            controlFamilies.findIndex(x => x.id === controlFamilyStr) === -1
          ) {
            let obj = {
              id: controlFamilyStr,
              label: controlFamilyStr,
            };
            controlFamilies.push(obj);
          }
        }
        setFocusArea(focusAreas);
        setControlFamily(controlFamilies);
        setFsFrameworkDetails(modifiedData);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        console.log('err ', err);
      });
  }, [mid]);

  const tbodies = focusAreaCFData.map((control, index) => {
    let { control_family = [], focus_area = '' } = control;
    const cityRows = control_family.map((details, i) => {
      const controlsAbbreviation =
        i === 0 ? (
          <td rowSpan={control_family.length}>
            {startCase(focus_area.replaceAll('_', ' ').toLowerCase())}
          </td>
        ) : null;
      return (
        <tr key={i}>
          {controlsAbbreviation}
          <td>{startCase(details.name.toLowerCase())}</td>
          <td>{details.count}</td>
        </tr>
      );
    });
    return <tbody key={index}>{cityRows}</tbody>;
  });

  return (
    <div id="create" className={`${COMPONENT_NAME}`}>
      <CustomBreadcrumb
        title="IBM Cloud for FS Framework Details"
        id="fscloud-dashboard-overview"
        mid={mid}
        type={type}
      />
      <div
        style={{
          padding: '0px 30px',
          background: '#fff',
        }}>
        {showPageLoader ? (
          <Loading className="loader-component" withOverlay={false} />
        ) : (
          <>
            <Row>
              <Column lg={6}>
                <Card>
                  <CardHeader title="Top control family with IBM Cloud FS additional controls" />
                  <SimpleBarChart
                    ref={barChartRef}
                    data={barData}
                    options={barOptions}
                  />
                </Card>
              </Column>
              <Column lg={4}>
                <Card>
                  <CardHeader title="Bank's Control Coverage" />
                  <DonutChart data={donutData} options={donutOptions} />
                </Card>
              </Column>
              <Column lg={6}>
                <Card>
                  <CardHeader title="Coverage By Control Families" />
                  <StackedBarChart data={stackData} options={stackOptions} />
                </Card>
              </Column>
              <Column lg={16}>
                <Card>
                  <CardHeader title="IBM FS Controls By Control Family" />
                  <div style={{ padding: '10px' }}>
                    {coverageFocusData &&
                      coverageFocusData.length > 0 &&
                      coverageFocusData
                        .slice(0, showMore ? coverageFocusData.length : 5)
                        .map(x => {
                          return (
                            <div
                              style={{
                                display: 'inline-block',
                                width: '20%',
                                marginBottom: '20px',
                                background: '#fff',
                              }}>
                              <div
                                style={{
                                  padding: '10px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}>
                                {x.name}
                              </div>
                              <DonutChart
                                data={x.value}
                                options={donutOptions1}
                              />
                            </div>
                          );
                        })}
                  </div>

                  {coverageFocusData.length > 5 && (
                    <div
                      style={{
                        marginBottom: '20px',
                        cursor: 'pointer',
                        textAlign: 'right',
                        marginRight: '20px',
                        color: 'blue',
                        textDecoration: 'underline',
                      }}
                      onClick={e => setShowMore(!showMore)}>
                      {showMore ? 'Show Less' : 'Show More'}
                    </div>
                  )}
                </Card>
              </Column>
            </Row>
            <br />
            <Row>
              <Column lg={16}>
                <Card>
                  <CardHeader title="Bank's Control Coverage By Focus Area" />
                  <CardBody>
                    <div className="custom-table">
                      <table>
                        <thead>
                          <tr>
                            <th>IBM Cloud FS Focus Area</th>
                            <th>IBM Cloud FS Control Family</th>
                            <th>Cloud Controls</th>
                          </tr>
                        </thead>
                        {tbodies}
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Column>
            </Row>
            <br />
            <Row>
              <Column lg={16}>
                <Card>
                  <CardHeader title="Summary" />
                  <CardBody>
                    <ul
                      style={{
                        background: '#fff',
                        padding: '20px',
                      }}>
                      <li style={{ marginLeft: '10px', listStyle: 'disc' }}>
                        {controlsData['bank_coverage']} IBM Cloud FS Controls
                        are Covered by Bank Controls and{' '}
                        {controlsData['fs_additional_controls']} are Additional
                        Controls.
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </Column>
            </Row>
            <br />
            <Row>
              <Column lg={16}>
                <Card>
                  <CardHeader title="IBM Cloud FS Framework Details" />
                  <CardBody>
                    {loader ? (
                      <EnhancedDataTable
                        id="basic-table"
                        title="My datatable"
                        description="Loading state with header"
                        headers={[
                          {
                            header: 'IBM Cloud FS Framework EE Control Id',
                            key: 'name',
                          },
                          {
                            header: 'IBM Cloud FS Framework EE Control Name',
                            key: 'title',
                          },
                          {
                            header:
                              'IBM Cloud FS Framework EE Control Description',
                            key: 'description',
                          },
                          {
                            header: 'IBM Cloud FS Focus Area',
                            key: 'focusArea',
                          },
                          {
                            header: 'IBM Cloud FS Control Family',
                            key: 'controlFamily',
                          },
                        ]}
                      />
                    ) : (
                      <EnhancedDataTable
                        id="table-with-inline-filter"
                        headers={[
                          {
                            header: 'IBM Cloud FS Framework EE Control Id',
                            key: 'name',
                          },
                          {
                            header: 'IBM Cloud FS Framework EE Control Name',
                            key: 'title',
                          },
                          {
                            header:
                              'IBM Cloud FS Framework EE Control Description',
                            key: 'description',
                          },
                          {
                            header: 'IBM Cloud FS Focus Area',
                            key: 'focusArea',
                          },
                          {
                            header: 'IBM Cloud FS Control Family',
                            key: 'controlFamily',
                          },
                        ]}
                        rows={fsFrameworkDetails}
                        filters={[
                          {
                            id: 'focusArea-filter',
                            columnKey: 'focusArea',
                            titleText: 'Focus Area:',
                            label: 'Focus Area',
                            items: focusArea,
                          },
                          {
                            id: 'controlFamily-filter',
                            columnKey: 'controlFamily',
                            titleText: 'Control Family:',
                            label: 'Control Family',
                            items: controlFamily,
                          },
                        ]}
                      />
                    )}
                  </CardBody>
                </Card>
              </Column>
            </Row>
          </>
        )}
        <br />
      </div>
    </div>
  );
};
export default FSCloudDashboard;
