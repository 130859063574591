export const serverIP = window.location.hostname.includes('localhost')
  ? ''
  : window.location.hostname;

export function getHost() {
  if (serverIP && serverIP.length > 0) {
    return `https://${serverIP}`;
  } else {
    return '';
  }
}

export const HOST_URL = window.location.hostname.includes('localhost')
  ? 'http://127.0.0.1:4000'
  : '';
export const AUTH_HOST_URL = window.location.hostname.includes('localhost')
  ? 'http://localhost:4000'
  : '';

export const BASE_URL = `${HOST_URL}/crosswalks/v1`;
