import axios from '../helpers/apiHelper';
import { BASE_URL } from '../helpers/urls';

export const getRecommendations = async queryParam => {
  return await axios.get(`${BASE_URL}/resource/mapping-detail/?${queryParam}`);
};

export const getRecommendationControl = async (control_id, queryParam) => {
  return await axios.get(
    `${BASE_URL}/resource/mapping-detail/${control_id}?${queryParam}`
  );
};

export const addComment = async (formData, account_id) => {
  return await axios.post(`${BASE_URL}/resource/comments/`, formData, {
    params: {
      account_id,
    },
  });
};

export const editRecommendations = async (
  mapping_id,
  payload,
  account_id,
  source_profile_id
) => {
  return await axios.put(
    `${BASE_URL}/resource/mapping-recommendation/?mapping_request_id=${mapping_id}&account_id=${account_id}&source_profile_id=${source_profile_id}`,
    payload
  );
};

export const changeMappingStatus = async (data, mid) => {
  return await axios.get(`${BASE_URL}/resource/mapping-request/status/${mid}`, {
    params: { ...data },
  });
};

export const searchMapping = async (search, profile_id) => {
  return await axios.get(
    `${BASE_URL}/resource/controls/search/?search_string=${search}&profile_id=${profile_id}`
  );
};

export const addControlRecommendation = async payload => {
  return await axios.post(
    `${BASE_URL}/resource/mapping-recommendation/add/`,
    payload
  );
};

export const getAllRecommendationList = async queryParam => {
  return await axios.get(
    `${BASE_URL}/resource/mapping-recommendation/?${queryParam}`
  );
};

export const addNewMapping = async (mapping_request_id, payload) => {
  return await axios.post(
    `${BASE_URL}/resource/mapping-request/${mapping_request_id}`,
    payload
  );
};

export const getControlMetadata = async control_id => {
  return await axios.get(`${BASE_URL}/resource/control-metadata/${control_id}`);
};

export const getRulesData = async (mid, control_id, queryParam) => {
  return await axios.get(
    `${BASE_URL}/resource/mapping-request/${mid}/rules/${control_id}?${queryParam}`
  );
};

export const updateRules = async (mid, control_id, payload) => {
  return await axios.post(
    `${BASE_URL}/resource/mapping-request/${mid}/rules/${control_id}`,
    payload
  );
};

export const saveRules = async (mid, payload) => {
  return await axios.put(
    `${BASE_URL}/resource/mapping-request/${mid}/rules`,
    payload
  );
};

export const getCategoryInformation = async source_profile_id => {
  return await axios.get(
    `${BASE_URL}/resource/mapping-detail/category-information/?source_profile_id=${source_profile_id}`
  );
};

export const applyFilter = async (queryParam, payload) => {
  return await axios.post(
    `${BASE_URL}/resource/mapping-recommendation/?${queryParam}`,
    payload
  );
};

export const editCategory = async (accountId, payload = '') => {
  return await axios.put(
    `${BASE_URL}/resource/mapping-recommendation/control-category?account_id=${accountId}`,
    payload
  );
};

export const getRules = async (mid, payload) => {
  return await axios.post(
    `${BASE_URL}/resource/mapping-request/${mid}/rules`,
    payload
  );
};

export const approveAllMapping = async queryParam => {
  return await axios.put(
    `${BASE_URL}/resource/mapping-recommendation/all/?${queryParam}`
  );
};
