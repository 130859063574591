import React, { useCallback, useEffect, useState } from 'react';
import './Crossmap.scss';
import Layout from '../common/Layout';
import {
  Content,
  SelectItem,
  Select,
  InlineNotification,
  Modal,
  ComboBox,
} from '@carbon/react';
import {
  EnhancedDataTable,
  PageHeader,
  SidePanel,
  SidePanelContainer,
} from '@console/pal/Components';
import { Button } from '@console/pal/carbon-components-react';
import {
  getMappings,
  oscalDownload,
  getCrosswalkProfiles,
  createCrosswalk,
  assignUserRole,
  deleteMapping,
  getUserRoles,
} from '../../api/LandingPage';
import { exportProfileDetails } from '../../api/ExportProfile';
import { downloadFile } from '../../helpers/downloadJson';
import { CheckmarkFilled } from '@carbon/icons-react';
import { Link, useLocation } from 'react-router-dom';
import { getEmailID } from '../../api/common';
import { MAPPING_STATUS, MAPPING_TYPE } from '../../config/mapping';
import moment from 'moment';
import startCase from 'lodash/startCase';
import { getHost } from '../../helpers/urls';
const COMPONENT_NAME = 'crosswalks-page';

const headers = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'target_profile_name',
    header: 'Target Type',
  },
  {
    key: 'control_count',
    header: 'Controls',
  },
  {
    key: 'goal_count',
    header: 'Goals',
  },
  {
    key: 'mapping_mode',
    header: 'Mode',
  },
  {
    key: 'statusIcon',
    header: 'Status',
  },
  {
    key: 'created_by',
    header: 'Created By',
  },
  {
    key: 'createdOn',
    header: 'Created On',
  },
  {
    key: 'reviewers',
    header: 'Reviewer',
  },
  {
    key: 'approvers',
    header: 'Approver',
  },
];
const initialCols = [
  'name',
  'target_profile_name',
  'control_count',
  'statusIcon',
  'created_by',
  'createdOn',
  'reviewers',
  'approvers',
];

const Crossmap = props => {
  let { accountDetails = {} } = props;
  let { acc_name = '', acc_id = '', default_acc_id = '' } = accountDetails;
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [targetList, setTargetList] = useState([]);
  const [sourceId, setSourceId] = useState('');
  const [targetId, setTargetId] = useState('');
  const [showToastMessage, setToastMessage] = useState('');
  const [mappingType, setMappingType] = useState('');
  const [mapType, setMapType] = useState('');
  const [custom, setCustom] = useState({ id: 'STATIC', label: 'STATIC' });
  const [filter, setFilter] = useState({ id: 'AVAILABLE', label: 'Available' });
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [rowId, setRowId] = useState('');
  const [reviewers, setReviewers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [reviewer, setReviewer] = useState('');
  const [approver, setApprover] = useState('');
  const [reviewerName, setReviewerName] = useState('');
  const [approverName, setApproverName] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const searchUrl = location.search;
    const params = new URLSearchParams(searchUrl);
    const tabId = params.get('tabId');
    if (tabId === 'custom') {
      setCustom({ id: 'CUSTOM', label: 'CUSTOM' });
      setMappingType('CUSTOM');
      setMapType('CUSTOM');
    } else {
      setCustom({ id: 'STATIC', label: 'STATIC' });
      setMappingType('STATIC');
      setMapType('STATIC');
    }
    localStorage.removeItem('table-with-inline-filter-filters');
    setFilter({
      id: 'ALL',
      label: 'All',
    });
  }, [location.search]);

  const getProfiles = (query, id) => {
    getCrosswalkProfiles(query)
      .then(result => {
        let { sources = [], target = [] } = result.data;
        if (id === 'SOURCE') {
          setSourceList(sources);
        } else {
          setTargetList(target);
          if (target && target.length) {
            let { id = '' } = target[0];
            setTargetId(id);
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getData = useCallback(
    (mappingType = '', search = '') => {
      setLoader(true);
      let queryParams = {
        account_id: acc_id,
        type: 'CROSSWALK',
      };

      if (mappingType) {
        queryParams = {
          ...queryParams,
          mapping_type:
            mappingType === 'CUSTOM' ? 'MAPPING_REQUEST' : 'MAPPING',
        };
      }
      if (search) queryParams = { ...queryParams, search };
      const query = new URLSearchParams(queryParams).toString();
      try {
        getMappings(query).then(result => {
          let { mappings = [] } = result.data;
          setData(mappings);
          setLoader(false);
        });
      } catch (e) {
        setLoader(false);
      }
    },
    [acc_id]
  );

  const getUserList = useCallback(acc_id => {
    getUserRoles(acc_id)
      .then(result => {
        let { users = [] } = result.data;
        let reviewersList = [];
        let approversList = [];
        for (let list of users) {
          let { roles = [] } = list;
          if (roles.includes('REVIEWER')) {
            reviewersList.push(list);
          }
          if (roles.includes('APPROVER')) {
            approversList.push(list);
          }
        }
        setReviewers(reviewersList);
        setApprovers(approversList);
      })
      .catch(err => console.log('err ', err));
  }, []);

  useEffect(() => {
    if (props.accountDetails && props.accountDetails.acc_id) {
      let queryParams = { account_id: props.accountDetails.acc_id };
      const query = new URLSearchParams(queryParams).toString();
      getProfiles(query, 'SOURCE');
      getUserList(props.accountDetails.acc_id);
    }
  }, [getUserList, props.accountDetails]);

  useEffect(() => {
    if (props?.accountDetails?.acc_id && mappingType) {
      getData(mappingType);
    }
  }, [getData, props?.accountDetails?.acc_id, mappingType]);

  useEffect(() => {
    if (data) {
      let updatedRows = [];
      for (let list of data) {
        let obj = { ...list };
        obj['crosswalk_mapping_type'] =
          list.mapping_type === 'MAPPING' ? 'STATIC' : 'CUSTOM';
        obj['name'] = list.source_profile_name;
        obj['name__format'] = (
          <Link
            to={`/crosswalk/mapping-details/${list.mapping_type.toLowerCase()}/${
              list.mid
            }`}>
            {list.source_profile_name}
          </Link>
        );
        obj['statusIcon'] = (
          <div>
            {list.mapping_status === 'APPROVED' ? (
              <CheckmarkFilled className="green" />
            ) : (
              ''
            )}{' '}
            <span>{list.mapping_status.replaceAll('_', ' ')}</span>
          </div>
        );
        obj['createdOn'] = list.created_at
          ? moment
              .utc(list.created_at)
              .local()
              .format('YYYY-MM-DD LT')
          : '-';
        obj['reviewers__format'] = (
          <div>{list.reviewers ? list.reviewers.join(', ') : '-'}</div>
        );
        obj['approvers__format'] = (
          <div>{list.approvers ? list.approvers.join(', ') : '-'}</div>
        );
        updatedRows.push(obj);
      }
      setRows(updatedRows);
    }
  }, [data]);

  const onPanelClose = () => {
    setShowSidePanel(false);
  };

  const assignUsers = formData => {
    assignUserRole(formData, rowId.mid)
      .then(res => {
        getData(mappingType);
        onPanelClose();
      })
      .catch(err => {
        onPanelClose();
      });
  };

  const tableOverflowActions = (_rowId, rowData) => {
    let { requestors = [], approvers = [], reviewers = [] } = rowData;
    if (custom.id === 'CUSTOM') {
      return [
        {
          itemText: 'Assign/ Unassign',
          disabled:
            !(approvers === null || reviewers === null) &&
            (approvers && !approvers.includes(acc_name)) &&
            (reviewers && !reviewers.includes(acc_name)) &&
            (requestors && !requestors.includes(acc_name)),
          onClick: value => {
            window.analytics.track('Ran Process', {
              'user.email': getEmailID(),
              'user.bluemixId': getEmailID(),
              productTitle: 'Crosswalks Tool',
              name: 'Assign/ Unassign',
              object: rowData,
              processType: 'Click',
              process: 'Event Detected',
              successFlag: true,
              resultValue: 'On Assign/ Unassign',
              milestoneName: 'On Assign/ Unassign Users',
              url: getHost(),
            });
            setRowId(rowData);
            setShowSidePanel(true);
          },
        },
        {
          itemText: 'Download Mapping',
          disabled: false,
          onClick: value => {
            let {
              source_profile_name = '',
              mid = '',
              mapping_type = '',
            } = rowData;
            let formData = new FormData();
            formData.append('download_file', true);
            formData.append('mapping_type', mapping_type);
            exportProfileDetails(formData, mid)
              .then(response => {
                window.analytics.track('Exported Object', {
                  'user.email': getEmailID(),
                  'user.bluemixId': getEmailID(),
                  productTitle: 'Crosswalks Tool',
                  name: 'Download Mapping',
                  object: rowData,
                  objectType: 'csv',
                  successFlag: true,
                  resultValue: 'Landing - Download Mapping',
                  milestoneName:
                    'Landing page mapping generation details downloaded',
                  url: getHost(),
                });
                const blob = new Blob([response.data], {
                  type: 'data:text/csv;charset=utf-8,',
                });
                const blobURL = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = `${source_profile_name}.csv`;
                anchor.href = blobURL;
                anchor.dataset.downloadurl = [
                  'text/csv',
                  anchor.download,
                  anchor.href,
                ].join(':');
                anchor.click();
              })
              .catch(err => console.error(err));
          },
        },
        {
          itemText: 'Download Oscal',
          disabled: false,
          onClick: value => {
            let { source_profile_id = '' } = rowData;
            oscalDownload(source_profile_id)
              .then(result => {
                return result.data;
              })
              .then(data => {
                window.analytics.track('Exported Object', {
                  'user.email': getEmailID(),
                  'user.bluemixId': getEmailID(),
                  productTitle: 'Crosswalks Tool',
                  name: 'Download Oscal',
                  object: rowData,
                  objectType: 'json',
                  successFlag: true,
                  resultValue: 'Landing - Download Oscal',
                  milestoneName: 'Landing page oscal details downloaded',
                  url: getHost(),
                });
                downloadFile(data.catalog, 'oscalData');
              });
          },
        },
        {
          itemText: 'Delete',
          disabled: acc_id !== default_acc_id,
          onClick: value => {
            let { source_profile_name = '' } = rowData;
            setRowId(rowData);
            setDeleteModal(true);
            window.analytics.track('Ran Process', {
              'user.email': getEmailID(),
              'user.bluemixId': getEmailID(),
              productTitle: 'Crosswalks Tool',
              name: 'Delete',
              object: rowData,
              processType: 'Click',
              process: 'Event Detected',
              successFlag: true,
              resultValue: `On Delete ${source_profile_name} Mapping`,
              milestoneName: `On Delete ${source_profile_name} from Landing page`,
              url: getHost(),
            });
          },
          isDelete: true,
        },
      ];
    } else {
      return [
        {
          itemText: 'Download Mapping',
          disabled: false,
          onClick: value => {
            let {
              source_profile_name = '',
              mid = '',
              mapping_type = '',
            } = rowData;
            let formData = new FormData();
            formData.append('download_file', true);
            formData.append('mapping_type', mapping_type);
            exportProfileDetails(formData, mid)
              .then(response => {
                window.analytics.track('Exported Object', {
                  'user.email': getEmailID(),
                  'user.bluemixId': getEmailID(),
                  productTitle: 'Crosswalks Tool',
                  name: 'Download Mapping',
                  object: rowData,
                  objectType: 'csv',
                  successFlag: true,
                  resultValue: 'Crossmap - Download Mapping',
                  milestoneName: 'Crossmap generation details downloaded',
                  url: getHost(),
                });
                const blob = new Blob([response.data], {
                  type: 'data:text/csv;charset=utf-8,',
                });
                const blobURL = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = `${source_profile_name}.csv`;
                anchor.href = blobURL;
                anchor.dataset.downloadurl = [
                  'text/csv',
                  anchor.download,
                  anchor.href,
                ].join(':');
                anchor.click();
              })
              .catch(err => console.error(err));
          },
        },
        {
          itemText: 'Download Oscal',
          disabled: false,
          onClick: value => {
            let { source_profile_id = '' } = rowData;
            oscalDownload(source_profile_id)
              .then(result => {
                return result.data;
              })
              .then(data => {
                window.analytics.track('Exported Object', {
                  'user.email': getEmailID(),
                  'user.bluemixId': getEmailID(),
                  productTitle: 'Crosswalks Tool',
                  name: 'Download Oscal',
                  object: rowData,
                  objectType: 'json',
                  successFlag: true,
                  resultValue: 'Crossmap - Download Oscal',
                  milestoneName: 'Crossmap oscal details downloaded',
                  url: getHost(),
                });
                downloadFile(data.catalog, 'oscalData');
              });
          },
        },
      ];
    }
  };

  const tableContent = (
    <EnhancedDataTable
      onFilterChange={e => getData(mappingType, e)}
      size="md"
      id="table-with-inline-filter"
      className={`${COMPONENT_NAME}__datatable`}
      rows={rows}
      headers={
        custom.id === 'CUSTOM'
          ? headers
          : headers.filter(
              x =>
                x.key !== 'last_reviewed_by' &&
                x.key !== 'last_approved_by' &&
                x.key !== 'reviewedOn' &&
                x.key !== 'approvedOn' &&
                x.key !== 'reviewers' &&
                x.key !== 'approvers'
            )
      }
      initialCols={initialCols}
      rowActions={tableOverflowActions}
      filters={[
        {
          id: 'crosswalk_mapping_type-filter',
          titleText: 'Mapping Type:',
          label: 'Mapping Type',
          items: MAPPING_TYPE,
          selectedItem: custom,
          className: 'crosswalk_mapping_type-filter',
          onChange: e => {
            if (!e) {
              setCustom({ id: 'ALL', label: 'All' });
              getData();
            } else {
              setCustom({ id: e, label: e });
              getData(e);
            }

            setFilter({
              id: e === 'STATIC' ? 'AVAILABLE' : 'ALL',
              label: e === 'STATIC' ? 'Available' : 'All',
            });
          },
        },
        {
          id: 'mapping_status-filter',
          columnKey: 'mapping_status',
          titleText: 'Status:',
          label: 'Status',
          selectedItem: filter,
          onChange: e => {
            if (e) {
              setFilter({
                id: e,
                label: startCase(e.toLowerCase()),
              });
            } else {
              setFilter({
                id: 'ALL',
                label: 'All',
              });
            }
          },
          items:
            custom.id === 'CUSTOM'
              ? MAPPING_STATUS
              : [{ id: 'AVAILABLE', label: 'Available' }],
        },
      ]}
    />
  );

  return (
    <Layout>
      <Content id="main-content">
        <PageHeader
          title="Crossmap"
          className={`${COMPONENT_NAME}--page-title`}
        />

        {showToastMessage && (
          <div>
            <InlineNotification
              lowContrast
              timeout={6000}
              kind={showToastMessage === 'success' ? 'success' : 'warning'}
              title={
                showToastMessage === 'success'
                  ? 'Mapping created successfully!'
                  : 'Oops, there was some issue while creating the mapping, please try again!'
              }
              subtitle=""
            />
          </div>
        )}
        <div style={{ marginBottom: '20px' }}>
          <div
            style={{
              display: 'inline-block',
              width: '20%',
              marginRight: '20px',
            }}>
            <Select
              defaultValue="placeholder-item"
              id="sourceId"
              onChange={e => {
                let { value = '' } = e.target;
                setSourceId(value);
                let queryParams = {
                  account_id: props.accountDetails.acc_id,
                  source_profile_id: value,
                };
                const query = new URLSearchParams(queryParams).toString();
                getProfiles(query, 'TARGET');
              }}
              labelText="Source Profile Id"
              value={sourceId}
              size="md">
              <SelectItem
                disabled
                hidden
                text="Choose an option"
                value="placeholder-item"
              />
              {sourceList.map((mapping, index) => (
                <SelectItem
                  text={mapping.name}
                  value={mapping.id}
                  key={index}
                />
              ))}
            </Select>
          </div>
          <div
            className="m-top"
            style={{
              display: 'inline-block',
              width: '20%',
              marginRight: '20px',
            }}>
            <Select
              defaultValue="placeholder-item"
              id="targetId"
              onChange={e => {
                let { value = '' } = e.target;
                setTargetId(value);
              }}
              labelText="Target Profile Id"
              value={targetId}
              size="md">
              <SelectItem
                disabled
                text="Choose an option"
                value="placeholder-item"
              />
              {targetList.map((mapping, index) => (
                <SelectItem
                  text={mapping.name}
                  value={mapping.id}
                  key={index}
                />
              ))}
            </Select>
          </div>
          <div
            className="m-top"
            style={{
              display: 'inline-block',
              width: '20%',
              marginRight: '20px',
            }}>
            <Select
              defaultValue="placeholder-item"
              id="mappingType"
              onChange={e => {
                let { value = '' } = e.target;
                setMapType(value);
              }}
              labelText="Mapping Type"
              value={mapType}
              size="md">
              <SelectItem
                disabled
                text="Choose an option"
                value="placeholder-item"
              />
              <SelectItem text="STATIC" value="STATIC" />
              <SelectItem text="CUSTOM" value="CUSTOM" />
            </Select>
          </div>
          <div
            className="m-top"
            style={{ display: 'inline-block', width: '30%' }}>
            <Button
              size="lg"
              kind="primary"
              disabled={!sourceId || !targetId || acc_id !== default_acc_id}
              className={`${COMPONENT_NAME}--back`}
              onClick={() => {
                setToastMessage('');
                let data = {
                  source_profile_id: sourceId,
                  target_profile_id: targetId,
                  type: mappingType,
                };
                window.analytics.track('Started Process', {
                  'user.email': getEmailID(),
                  'user.bluemixId': getEmailID(),
                  productTitle: 'Crosswalks Tool',
                  name: 'Generate Mapping',
                  processType: 'Generate Mapping',
                  process: 'Generate',
                  resultValue: 'Running mapping generation from crossmap',
                  successFlag: true,
                  category: 'Crossmap',
                  object: data,
                  milestoneName:
                    'Running mapping generation from crossmap page',
                  url: getHost(),
                });
                createCrosswalk(accountDetails.acc_id, data)
                  .then(res => {
                    window.analytics.track('Ended Process', {
                      'user.email': getEmailID(),
                      'user.bluemixId': getEmailID(),
                      productTitle: 'Crosswalks Tool',
                      name: 'Generate Mapping',
                      processType: 'Generate Mapping',
                      process: 'Generate',
                      resultValue: 'Running mapping generation from crossmap',
                      successFlag: true,
                      category: 'Crossmap',
                      object: data,
                      milestoneName:
                        'Running mapping generation from crossmap page',
                      url: getHost(),
                    });
                    setToastMessage('success');
                  })
                  .catch(err => setToastMessage('error'));
              }}>
              Generate Mapping
            </Button>
          </div>
        </div>
        <div>
          {loader ? (
            <EnhancedDataTable
              id="table-with-inline-filter"
              headers={
                custom.id === 'CUSTOM'
                  ? headers
                  : headers.filter(
                      x =>
                        x.key !== 'last_reviewed_by' &&
                        x.key !== 'last_approved_by' &&
                        x.key !== 'reviewedOn' &&
                        x.key !== 'approvedOn' &&
                        x.key !== 'reviewers' &&
                        x.key !== 'approvers'
                    )
              }
              initialCols={initialCols}
            />
          ) : (
            tableContent
          )}
        </div>
      </Content>
      <Modal
        modalHeading={`Delete ${rowId.source_profile_name} mapping`}
        open={deleteModal}
        onRequestClose={() => setDeleteModal(false)}
        onRequestSubmit={() => {
          window.analytics.track('Deleted Object', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: 'Delete mapping',
            data: {
              name: rowId.source_profile_name,
              mid: rowId.mid,
              type: rowId.mapping_type,
            },
            object: { mid: rowId.mid, type: rowId.mapping_type },
            objectType: 'json',
            successFlag: true,
            resultValue: `Deleted ${rowId.source_profile_name} mapping`,
            milestoneName: `Delete ${rowId.source_profile_name} mapping`,
            url: getHost(),
          });
          deleteMapping(rowId.mid, rowId.mapping_type);
          setDeleteModal(false);
          getData(mappingType);
        }}
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        danger>
        <div>
          Are you sure you want to delete {rowId.source_profile_name} mapping.
        </div>
      </Modal>
      <SidePanelContainer
        panelSize="medium"
        includeOverlay={true}
        isOpen={showSidePanel}
        doneText="Submit"
        onCloseClick={onPanelClose}
        onDoneClick={() => {
          if (reviewer) {
            let formData = {
              user_id: reviewer,
              type: 'REVIEWER',
            };
            assignUsers(formData);
          }
          if (approver) {
            let formData = {
              user_id: approver,
              type: 'APPROVER',
            };
            assignUsers(formData);
          }
          window.analytics.track('Updated Object', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: 'Assign/ Unassign',
            data: {
              r_user_id: reviewer,
              r_type: 'REVIEWER',
              a_user_id: approver,
              a_type: 'APPROVER',
            },
            object: {
              r_user_id: reviewer,
              r_type: 'REVIEWER',
              a_user_id: approver,
              a_type: 'APPROVER',
            },
            objectType: 'json',
            successFlag: true,
            resultValue: 'Assign/ Unassign users saved',
            milestoneName: 'Assign/ Unassign users saved',
            url: getHost(),
          });
        }}
        onCancelClick={onPanelClose}>
        <SidePanel title="Assign User" id="panel-1">
          <>
            <ComboBox
              onChange={e => {
                if (e.selectedItem) {
                  setReviewer(e.selectedItem.id);
                  setReviewerName(e.selectedItem.name);
                }
              }}
              id="reviewer-combobox"
              items={reviewers}
              selectedItem={
                reviewerName
                  ? {
                      name: reviewerName,
                    }
                  : {
                      name:
                        rowId.reviewers && rowId.reviewers.length
                          ? rowId.reviewers[0]
                          : '',
                    }
              }
              itemToString={item => (item ? item.name : '')}
              titleText="Reviewer"
              placeholder="Select Reviewer"
            />
            <br />
            <ComboBox
              id="approver-combobox"
              titleText="Approver"
              placeholder="Select Approver"
              items={approvers}
              selectedItem={
                approverName
                  ? { name: approverName }
                  : {
                      name:
                        rowId.approvers && rowId.approvers.length
                          ? rowId.approvers[0]
                          : '',
                    }
              }
              itemToString={item => (item ? item.name : '')}
              onChange={e => {
                if (e.selectedItem) {
                  setApprover(e.selectedItem.id);
                  setApproverName(e.selectedItem.name);
                }
              }}
            />
          </>
        </SidePanel>
      </SidePanelContainer>
    </Layout>
  );
};

export default Crossmap;
