export const SOURCE_CONTROL_TYPES = [
  { value: 'CUSTOMER_CONTROL', text: 'Customer control' },
  { value: 'REGULATION', text: 'Standard regulation' },
];

//Initiated, Ready for Review, In Review, Ready for Approval, Pending Approval, Approved
export const MAPPING_STATUS = [
  { id: 'INITIATED', label: 'Initiated' },
  { id: 'READY_FOR_REVIEW', label: 'Ready for Review' },
  { id: 'IN_REVIEW', label: 'In Review' },
  { id: 'READY_FOR_APPROVAL', label: 'Ready for Approval' },
  { id: 'PENDING_APPROVAL', label: 'Pending Approval' },
  { id: 'APPROVED', label: 'Approved' },
];

export const MAPPING_TYPE = [
  { id: 'STATIC', label: 'STATIC' },
  { id: 'CUSTOM', label: 'CUSTOM' },
];
