import React, { useState, useEffect } from 'react';
import { WorldLevelLayout } from '@console/pal/Layouts';
import { WorldLevelNav } from '@console/pal/Components';
import { IbmCloudSecurityComplianceCenter } from '@carbon/react/icons';
import '../LandingPage/LandingPage.scss';
import { navigationItems } from '../../config/navigationData';
import { Link, useLocation } from 'react-router-dom';

const Layout = props => {
  const location = useLocation();

  const [selectedNav, setSelectedNav] = useState('');

  useEffect(() => {
    const { pathname = '', search = '' } = location;
    setSelectedNav(`${pathname?.trim()}${search?.trim()}` || '/landingpage');
  }, [location]);

  const CustomLinkComponent = ({ to, ...props }) => {
    let label = 'Controls Crosswalk and Crossmap';
    for (let list of navigationItems) {
      for (let item of list.items) {
        if (item.to === to) {
          label = item.label;
        }
      }
    }
    if (!to) {
      to = '/landingpage';
    }

    return (
      <Link
        to={to}
        onClick={() => setSelectedNav(to)}
        className={`${selectedNav === to ? 'active-href' : 'non-active-href'}`}>
        {label === 'Controls Crosswalk and Crossmap' && (
          <div>
            <IbmCloudSecurityComplianceCenter />
          </div>
        )}
        <div>{label}</div>
      </Link>
    );
  };

  return (
    <WorldLevelLayout>
      <WorldLevelNav
        items={navigationItems}
        linkComponent={CustomLinkComponent}
        className="crosswalk-sidenav"
      />
      {props.children}hell
    </WorldLevelLayout>
  );
};

export default Layout;
