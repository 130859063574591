import axios from '../helpers/apiHelper';
import { BASE_URL } from '../helpers/urls';

export const getUserAccount = async () => {
  return await axios.get(`${BASE_URL}/accessgroup/user/`);
};

// return the token from the local storage
export const getAccessToken = () => {
  return localStorage.getItem('access_token') || null;
};

export const getEmailID = () => {
  return sessionStorage.getItem('emailId') || null;
};

export const deleteAllCookies = () => {
  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf('=');
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};
