import React from 'react';
import { PageHeader } from '@console/pal/Components';
import { Button } from '@console/pal/carbon-components-react';
import { useHistory } from 'react-router-dom';
import { getEmailID } from '../../api/common';
import { getHost } from '../../helpers/urls';

const CustomBreadcrumb = props => {
  const history = useHistory();
  let { title = '', id = '', mid = '', type = '' } = props;
  return (
    <PageHeader
      breadcrumbs={
        id === 'create'
          ? [
              {
                onClick: value => {
                  history.push('/landingpage');
                },
                value: 'Crosswalks',
              },
            ]
          : [
              {
                onClick: value => {
                  history.push('/landingpage');
                },
                value: 'Crosswalks',
              },
              {
                onClick: value => {
                  history.push(
                    `/crosswalk/mapping-details/mapping_request/${mid}`
                  );
                },
                value: 'Mapping Details',
              },
            ]
      }
      title={title}>
      {id === 'dashboard-overview' && type !== 'crosswalk' && (
        <Button
          kind="ghost"
          size="field"
          onClick={() => {
            window.analytics.track('Ran Process', {
              'user.email': getEmailID(),
              'user.bluemixId': getEmailID(),
              productTitle: 'Crosswalks Tool',
              name: 'IBM Cloud for FS Framework Details',
              object: {},
              processType: 'Click',
              process: 'Event Detected',
              successFlag: true,
              resultValue:
                'IBM Cloud FS Framework Details clicked from Breadcrumb',
              milestoneName:
                'IBM Cloud FS Framework Details clicked from Breadcrumb',
              url: getHost(),
            });
            history.push(`/crosswalk/fs-dashboard/${type}/${mid}`);
          }}>
          IBM Cloud for FS Framework Details
        </Button>
      )}
      {id === 'fscloud-dashboard-overview' && (
        <Button
          kind="ghost"
          size="field"
          onClick={() => {
            window.analytics.track('Ran Process', {
              'user.email': getEmailID(),
              'user.bluemixId': getEmailID(),
              productTitle: 'Crosswalks Tool',
              name: 'Control Assessment Summary',
              object: {},
              processType: 'Click',
              process: 'Event Detected',
              successFlag: true,
              resultValue: 'Control Assessment Summary clicked from Breadcrumb',
              milestoneName:
                'Control Assessment Summary clicked from Breadcrumb',
              url: getHost(),
            });
            history.push(`/crosswalk/dashboard/${type}/${mid}`);
          }}>
          Control Assessment Summary
        </Button>
      )}
    </PageHeader>
  );
};

export default CustomBreadcrumb;
