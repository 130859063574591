import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  Button,
  OverflowMenuItem,
  SideNav,
  SideNavItems,
  SideNavLink,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  PaginationNav,
  TextInput,
  ContentSwitcher,
  Switch,
  InlineLoading,
  Checkbox,
} from '@carbon/react';
import './MappingPage.scss';
import { Send } from '@carbon/react/icons';
import {
  PageHeader,
  Tag,
  PageHeaderSkeleton,
  EnhancedDataTable,
  ProgressBar,
} from '@console/pal/Components';
import { configNavItems } from './MappingPageConfig';
import {
  getRecommendations,
  editRecommendations,
  changeMappingStatus,
  getRecommendationControl,
  addNewMapping,
  saveRules,
  getCategoryInformation,
  applyFilter,
} from '../../api/MappingPage';
import { useParams } from 'react-router-dom';
import { oscalDownload } from '../../api/LandingPage';
import { downloadFile } from '../../helpers/downloadJson';
import { InlineNotification } from '@carbon/react';
import { exportProfileDetails } from '../../api/ExportProfile';
import { getEmailID } from '../../api/common';
import { getHost } from '../../helpers/urls';
import Comments from './Comments';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import MetaDataPanel from './MetaDataPanel';
import ControlClassificationPanel from './ControlClassificationPanel';
import ApproverModal from './ApproverModal';
import ReviewerModal from './ReviewerModal';
import RulesTabDetails from './RulesTabDetails';
import ClassificationTabDetails from './ClassificationTabDetails';
import RecommendationTabDetails from './RecommendationTabDetails';
import useOutsideAlerter from '../common/useOutsideAlerter';
import CategoryInfoModal from './CategoryInfoModal';
import SaveDataModal from './SaveDataModal';
import { CheckmarkFilled, WarningFilled } from '@carbon/icons-react';

const COMPONENT_NAME = 'mapping-generation-details';

const headers = [
  {
    key: 'key',
    header: 'Id',
  },
  {
    key: 'mapping',
    header: 'Mapping',
  },
  {
    key: 'classifications',
    header: 'Control classification',
  },
  {
    key: 'comments',
    header: 'Comments',
  },
  {
    key: 'review_progress',
    header: 'Review progress',
  },
  {
    key: 'status',
    header: 'Overall status',
  },
];
const initialCols = [
  'key',
  'mapping',
  'classifications',
  'comments',
  'review_progress',
  'status',
];

const MappingPageOne = props => {
  const history = useHistory();
  const { accountDetails = {} } = props;
  const { mid, type } = useParams();
  const { actionsPanel: ActionsPanel } = PageHeader;
  const wrapperRef = useRef(null);
  const LIMIT = 10;

  const [isListView, setIsListView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [mappingDetails, setMappingDetails] = useState([]);
  const [showSidePanel, setSidePanel] = useState(false);
  const [panelType, setPanelType] = useState('');
  const [selectedId, setSelectedId] = useState(0);
  const [showToast, setToastNotification] = useState(false);
  const [mDetails, setMDetails] = useState({});
  const [mappingPayload, setMappingPayload] = useState([]);
  const [newMapping, setNewMapping] = useState({});
  const [READ_ONLY, setReadOnly] = useState(true);
  const [loader, setLoader] = useState(false);
  const [validMapping, setValidMapping] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [metaDataSlider, setMetaDataSlider] = useState(false);
  const [controlItem, setControlItem] = useState('');
  const [controlTitle, setControlTitle] = useState('');
  const [role, setRole] = useState('');
  const [updatedRules, setUpdatedRules] = useState([]);
  const [showUpdateMessage, setUpdateMessage] = useState('');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedRecommendations, setSelectedRecommendations] = useState([]);
  const [rejectedRecommendations, setRejectedRecommendations] = useState([]);
  const [controlRecommendations, setControlRecommendations] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState();
  const [categoryModal, setCategoryModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [offset, setOffset] = useState(0);
  const [listViewData, setListViewData] = useState({});
  const [listViewCount, setListViewCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedControls, setSelectedControls] = useState([]);
  const [filterQuery, setFilterQuery] = useState({});
  const [applyListViewFilter, setApplyListViewFilter] = useState(false);
  const queryParameter = useMemo(() => {
    return {
      recommendationParams: {
        account_id: accountDetails?.acc_id,
        mapping_request_id: mid,
        type: type.toUpperCase(),
      },
      mappingParams: {
        account_id: accountDetails?.acc_id,
        mid: mid,
        type: type.toUpperCase(),
      },
    };
  }, [accountDetails?.acc_id, mid, type]);

  const { showNotification } = useOutsideAlerter(
    wrapperRef,
    selectedRecommendations,
    rejectedRecommendations
  );

  const getRecommendationControlDetails = useCallback(
    controlId => {
      if (queryParameter?.mappingParams) {
        const queryParam = new URLSearchParams(
          queryParameter.mappingParams
        ).toString();
        getRecommendationControl(controlId, queryParam)
          .then(result => {
            let data = result.data || {};
            setControlRecommendations(data);
            setLoader(false);
            return data;
          })
          .catch(error => {
            console.log('error ', error);
            setLoader(false);
            return [];
          });
      }
    },
    [queryParameter?.mappingParams]
  );

  const getCategoryInformationDetails = (source_profile_id = '') => {
    getCategoryInformation(source_profile_id)
      .then(result => {
        setCategoryInfo(result.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getMappingDetails = useCallback(
    (selectedIndex = 0) => {
      if (accountDetails?.acc_id) {
        let queryObj = { ...queryParameter.recommendationParams };
        if (applyListViewFilter) {
          queryObj = {
            ...queryObj,
            ...filterQuery,
          };
        }
        const queryParam = new URLSearchParams(queryObj).toString();
        getRecommendations(queryParam)
          .then(res => {
            let {
              controls = [],
              mapping_request_action_status = '',
              profile_id = '',
              reviewer = [],
              approver = [],
              profile_name = '',
              status = '',
              target_profile_id = '',
              mapping_mode = '',
              total_count = 0,
              control_count = 0,
              requestor = [],
              target_profile_name = '',
              source_profile_id = '',
              control_category_list: { category } = {},
              control_hierarchy,
            } = res.data || {};
            console.log('res ', res.data);
            getCategoryInformationDetails(source_profile_id);
            setMappingDetails(controls);
            setMDetails({
              mapping_request_action_status,
              profile_id,
              reviewer,
              approver,
              profile_name,
              status,
              target_profile_id,
              mapping_mode,
              total_count,
              control_count,
              requestor,
              target_profile_name,
              source_profile_id,
              category,
              control_hierarchy,
            });

            // if (controls && controls.length > 0) {
            //   setLoader(true);
            //   getRecommendationControlDetails(controls[selectedIndex].id);
            // }

            if (
              reviewer &&
              reviewer.includes(accountDetails.default_acc_name) &&
              status !== 'APPROVED' &&
              status !== 'PENDING_APPROVAL' &&
              mapping_request_action_status === 'PENDING_REVIEW'
            ) {
              setReadOnly(false);
            }
            setLoading(false);
            setValidMapping(true);
          })
          .catch(err => {
            setValidMapping(false);
            setLoading(false);
            setMappingDetails([]);
            setMDetails({});
          });
      }
    },
    [
      queryParameter,
      accountDetails,
      applyListViewFilter,
      filterQuery,
      // getRecommendationControlDetails,
      // getRulesDetails,
    ]
  );

  const getAllRecommendations = useCallback(() => {
    let queryObj = {
      ...queryParameter.recommendationParams,
      ...filterQuery,
    };
    // setListViewLoader(true);
    const queryParam = new URLSearchParams(queryObj).toString();
    applyFilter(queryParam)
      .then(res => {
        console.log('RES: ', res.data);
        setListViewData(res.data);
        // setListViewLoader(false);
      })
      .catch(err => {
        console.log(err);
        // setListViewLoader(false);
      });
  }, [queryParameter?.recommendationParams, filterQuery]);

  useEffect(() => {
    // if (Object.keys(listViewData).length) {
    let tableDetails = [];
    let {
      controls = [],
      mapping_request_action_status = '',
      approver = [],
      reviewer = [],
      count = 0,
    } = listViewData || {};
    setListViewCount(count);
    // let READ_ONLY = true;
    if (
      ((reviewer && reviewer.includes(accountDetails.default_acc_name)) ||
        (approver && approver.includes(accountDetails.default_acc_name))) &&
      mapping_request_action_status !== 'COMPLETED'
    ) {
      // READ_ONLY = false;
    }
    for (let i in controls) {
      let obj = {};
      let {
        comments = [],
        source_control_desc = '',
        source_control_id = '',
        source_control_title = '',
        id = '',
        overall_status = '',
        mappings = [],
        status = '',
      } = controls[i];

      let mappingList = [];
      if (mappings.length || mapping_request_action_status === 'IMPORTED') {
        // setIsListView(true);
      }
      for (let val in mappings) {
        let { target_control_id = '', target_control_desc = '' } = mappings[
          val
        ];

        let targetControlDesc = target_control_desc.replace(/\\n/g, '');
        let mapping_details = (
          <div key={Math.random(i) + '_' + id + '_' + source_control_id}>
            <div
              className="flex"
              style={{
                marginBottom: 20,
                marginTop: 10,
              }}>
              <div className="margin-r">
                <p
                  className="max-con font-size"
                  style={{ whiteSpace: 'pre-wrap' }}>
                  {target_control_id?.replace(/\\n/g, '')}
                </p>
              </div>
              <div>
                <p className="font-size" style={{ whiteSpace: 'pre-wrap' }}>
                  {targetControlDesc.slice(0, 120)}
                  {targetControlDesc.length > 120 ? '...' : ''}
                </p>
              </div>
            </div>

            {mappingList.length < mappings.length - 1 && (
              <div
                style={{
                  border: '0.2px solid lightGrey',
                }}
              />
            )}
          </div>
        );
        mappingList.push(mapping_details);
      }

      obj['id'] = Math.random(i) + '_' + id + '_' + source_control_id;
      obj['control_id'] = id;
      obj['source_control_id'] = source_control_id;
      obj['key'] = (
        <div
          style={{
            marginTop: 10,
          }}>
          <div className="margin-r">
            <Button
              style={{ padding: '0px' }}
              kind="ghost"
              onClick={() => {
                setLoader(true);
                setIsListView(false);
                setSelectedIndex(parseInt(pageNum - 1 + i));
                // getRecommendationControlDetails(id);
                // getRulesDetails(id);
                setUpdateMessage('');
              }}>
              {source_control_id}
            </Button>
          </div>
          {source_control_title &&
            source_control_title !== source_control_desc &&
            source_control_title !== source_control_id && (
              <div
                style={{
                  marginBottom: '10px',
                  whiteSpace: 'pre-wrap',
                }}>
                {source_control_title?.replace(/\\n/g, '')}
              </div>
            )}
          {source_control_desc && (
            <div
              style={{
                marginBottom: '10px',
                whiteSpace: 'pre-wrap',
              }}>
              {source_control_desc.replace(/\\n/g, '').slice(0, 120)}
              {source_control_desc.length > 120 ? '...' : ''}
            </div>
          )}
        </div>
      );
      obj['mapping'] = mappingList;
      let classifications = (
        <Button
          kind="ghost"
          onClick={() => {
            setSelectedId(i);
            setSidePanel(true);
            setPanelType('classifications');
            window.analytics.track('Ran Process', {
              'user.email': getEmailID(),
              'user.bluemixId': getEmailID(),
              productTitle: 'Crosswalks Tool',
              name: 'Classification',
              object: {},
              processType: 'Click',
              process: 'Event Detected',
              successFlag: true,
              resultValue: 'Classification clicked from Mapping details page',
              milestoneName: 'Classification clicked from Mapping details page',
              url: getHost(),
            });
          }}
          disabled={
            reviewer && !reviewer.includes(accountDetails.default_acc_name)
          }
          className="more-button">
          Classification
        </Button>
      );
      obj['classifications'] = classifications;
      obj['comments'] = (
        <Comments
          comments={comments}
          id={id}
          // READ_ONLY={
          //   READ_ONLY &&
          //   (approver &&
          //     !approver.includes(accountDetails.default_acc_name))
          // }
          setSelectedId={setSelectedId}
          setSidePanel={setSidePanel}
          setPanelType={setPanelType}
          i={i}
          type="table-component"
          accountId={accountDetails.acc_id}
          refreshData={() => {
            getMappingDetails();
            getAllRecommendations();
          }}
          mid={mid}
        />
      );
      let percentage = 0;
      let alignmentStatus = false;
      let details = mappingDetails.find(x => x.id === id);
      console.log('details ', details);
      let { control_category = '', relation_status = '' } = details || {};

      if (
        mappings.length > 0 &&
        relation_status &&
        relation_status !== 'DEFAULT' &&
        control_category &&
        control_category !== null
      ) {
        percentage = 100;
        alignmentStatus = true;
      } else if (
        (relation_status === 'NO_DATA' ||
          relation_status === 'NOT_APPLICABLE' ||
          relation_status === 'CUSTOMER_RESPONSIBILITY' ||
          relation_status === 'NEEDS_FURTHER_DISCUSSION') &&
        control_category &&
        control_category !== null
      ) {
        percentage = 100;
        alignmentStatus = true;
      } else if (
        (relation_status === 'MET' || relation_status === 'PARTIAL') &&
        control_category &&
        control_category !== null
      ) {
        percentage = 66;
        alignmentStatus = true;
      } else if (
        (mappings.length > 0 &&
          relation_status &&
          relation_status !== 'DEFAULT') ||
        (mappings.length > 0 && control_category) ||
        (relation_status && relation_status !== 'DEFAULT' && control_category)
      ) {
        percentage = 66;
      } else if (
        mappings.length > 0 ||
        (relation_status && relation_status !== 'DEFAULT') ||
        (control_category && control_category !== null)
      ) {
        percentage = 33;
      }
      if (relation_status !== 'DEFAULT') {
        alignmentStatus = true;
      }
      obj['review_progress'] = (
        <div style={{ padding: '0px 20px' }}>
          <div className="mb1">
            Mapping{' '}
            {mappings.length > 0 ? (
              <CheckmarkFilled className="green" />
            ) : (
              <WarningFilled
                style={{
                  fill: '#F7B401',
                }}
              />
            )}
          </div>
          <div className="mb1">
            Alignment status{' '}
            {alignmentStatus ? (
              <CheckmarkFilled className="green" />
            ) : (
              <WarningFilled
                style={{
                  fill: '#F7B401',
                }}
              />
            )}
          </div>
          <div className="mb1">
            Control category{' '}
            {control_category && control_category !== null ? (
              <CheckmarkFilled className="green" />
            ) : (
              <WarningFilled
                style={{
                  fill: '#F7B401',
                }}
              />
            )}
          </div>
          <ProgressBar
            kind="percent"
            label=""
            locale="en"
            segments={[
              {
                status: 'red',
                value: 0,
              },
              {
                status: 'yellow',
                value: 45,
              },
              {
                status: 'blue',
                value: 75,
              },
              {
                status: 'green',
                value: 90,
              },
            ]}
            value={percentage}
            width="12rem"
          />
        </div>
      );
      obj['status__format'] = overall_status && (
        <Tag
          type={overall_status === 'REJECTED' ? 'red' : 'green'}
          title="Clear Filter">
          {overall_status}
        </Tag>
      );

      obj['status'] = overall_status;
      obj['alignment_status'] = status;
      tableDetails.push(obj);
    }
    setTableData(tableDetails);
    // }
  }, [
    listViewData,
    accountDetails,
    mid,
    type,
    offset,
    pageNum,
    mappingDetails,
    getRecommendationControlDetails,
    getMappingDetails,
    // getRulesDetails,
    getAllRecommendations,
  ]);

  useEffect(() => {
    if (isListView && accountDetails?.acc_id) {
      getAllRecommendations();
    } else {
      getMappingDetails();
    }
  }, [
    isListView,
    accountDetails?.acc_id,
    getAllRecommendations,
    getMappingDetails,
  ]);

  useEffect(() => {
    if (selectedTab === 0 && mappingDetails[selectedIndex]?.id) {
      getRecommendationControlDetails(mappingDetails[selectedIndex].id);
    }
  }, [
    selectedTab,
    selectedIndex,
    mappingDetails,
    getRecommendationControlDetails,
  ]);

  const editMappingRecommendation = useCallback(
    data => {
      window.analytics.track('Started Process', {
        'user.email': getEmailID(),
        'user.bluemixId': getEmailID(),
        productTitle: 'Crosswalks Tool',
        name: 'Edit Mapping',
        processType: 'Edit Mapping Recommendations',
        process: 'Edit',
        resultValue: 'Running edit mapping generation',
        successFlag: true,
        category: mid,
        object: data,
        milestoneName: 'Running edit mapping recommendation details',
        url: getHost(),
      });
      editRecommendations(
        mid,
        data,
        accountDetails.acc_id,
        mDetails.source_profile_id
      )
        .then(result => {
          setSidePanel(false);
          getMappingDetails(selectedIndex);
          getAllRecommendations();
          window.analytics.track('Ended Process', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: 'Edit Mapping',
            processType: 'Edit Mapping Recommendations',
            process: 'Edit',
            resultValue: 'Completed edit mapping generation',
            successFlag: true,
            category: mid,
            object: data,
            milestoneName: 'Completed editing mapping recommendation details',
            url: getHost(),
          });
          setSelectedRecommendations([]);
          setRejectedRecommendations([]);
          setSelectedControls([]);
          setMappingPayload([]);
        })
        .catch(error => {
          console.log('error ', error);
          setSelectedRecommendations([]);
          setRejectedRecommendations([]);
          setSelectedControls([]);
        });
    },
    [
      accountDetails.acc_id,
      getAllRecommendations,
      getMappingDetails,
      mDetails.source_profile_id,
      mid,
      selectedIndex,
    ]
  );

  const assignUsers = formData => {
    changeMappingStatus(formData, mid)
      .then(res => {
        setToastNotification(true);
        getMappingDetails(selectedIndex);
        getAllRecommendations();
        setConfirmModal(false);
        setConfirmationModal(false);
        window.analytics.track('Updated Object', {
          'user.email': getEmailID(),
          'user.bluemixId': getEmailID(),
          productTitle: 'Crosswalks Tool',
          name: 'Change Mapping Status',
          data: formData,
          object: formData,
          objectType: 'json',
          successFlag: true,
          resultValue: 'Mapping status changed',
          milestoneName: 'MApping status changed',
          url: getHost(),
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const addNewMappingDetail = () => {
    window.analytics.track('Started Process', {
      'user.email': getEmailID(),
      'user.bluemixId': getEmailID(),
      productTitle: 'Crosswalks Tool',
      name: 'Add Mapping',
      processType: 'Add Mapping Controls',
      process: 'Add',
      resultValue: 'Running add mapping controls',
      successFlag: true,
      category: mid,
      object: {
        mid: mid,
        mappingDetails: newMapping,
      },
      milestoneName: 'Running add mapping controls',
      url: getHost(),
    });
    addNewMapping(mid, newMapping)
      .then(res => {
        getMappingDetails(selectedIndex);
        getAllRecommendations();
        window.analytics.track('Ended Process', {
          'user.email': getEmailID(),
          'user.bluemixId': getEmailID(),
          productTitle: 'Crosswalks Tool',
          name: 'Add Mapping',
          processType: 'Add Mapping Controls',
          process: 'Add',
          resultValue: 'Completed add mapping controls',
          successFlag: true,
          category: mid,
          object: res,
          milestoneName: 'Completed add mapping controls',
          url: getHost(),
        });
      })
      .catch(err => console.log(err));
  };

  const tableBatchActions = () => {
    return [
      {
        kind: 'primary',
        label: 'Approve',
        onClick: function onClick(e) {
          let payload = [];
          for (let mapping of e) {
            let { control_id = '' } = mapping;
            let obj = {};
            obj['control_id'] = control_id;
            obj['overall_status'] = 'APPROVED';
            payload.push(obj);
          }
          let data = {
            mapping_recommendation: payload,
          };
          editMappingRecommendation(data);
        },
      },
      {
        kind: 'tertiary',
        label: 'Reject',
        onClick: function onClick(e) {
          let payload = [];
          for (let mapping of e) {
            let { control_id = '' } = mapping;
            let obj = {};
            obj['control_id'] = control_id;
            obj['overall_status'] = 'REJECTED';
            payload.push(obj);
          }
          let data = {
            mapping_recommendation: payload,
          };
          editMappingRecommendation(data);
        },
      },
    ];
  };

  const handleExternalFilterChange = useCallback(
    e => {
      console.log(e);
      if (Object.keys(e).length > 0) {
        let payload = {
          limit: LIMIT,
          offset: offset,
          ...e,
        };
        if (JSON.stringify(payload) !== JSON.stringify(filterQuery)) {
          setFilterQuery(payload);
        }
        setPageNum(1);
      }
    },
    [offset, filterQuery]
  );

  const handlePageChange = useCallback(
    e => {
      if (Object.keys(e).length > 0) {
        console.log(e);
        setPageNum(e.page);
        setOffset((e.page - 1) * LIMIT);
        let payload = {
          ...filterQuery,
          limit: 10,
          offset: (e.page - 1) * LIMIT,
        };
        if (e.page !== 1 || filterQuery?.offset === 10) setFilterQuery(payload);
      }
    },
    [filterQuery]
  );

  const tableContent = (
    <EnhancedDataTable
      size="md"
      id="table-with-filter-panel"
      className={`${COMPONENT_NAME}__datatable`}
      rows={tableData}
      headers={headers}
      initialCols={initialCols}
      batchActions={
        mDetails.mapping_request_action_status === 'PENDING_APPROVAL' &&
        mDetails.approver &&
        mDetails.approver.includes(accountDetails.default_acc_name)
          ? tableBatchActions
          : false
      }
      hasSearch={true}
      debounceTime={3000}
      totalRowCount={listViewCount}
      isLastPage={Math.ceil(listViewCount / 10) === pageNum}
      pageNumber={pageNum}
      onPaginationChange={handlePageChange}
      initialPageSize={10}
      filterPanelMode="batch"
      onExternalFilterChange={handleExternalFilterChange}
      filters={[
        {
          id: 'mapping-filter',
          columnKey: 'mapping_status',
          titleText: 'Mapping:',
          filterType: 'combobox',
          items: [
            {
              id: 'REVIEWED',
              label: 'Has mapping',
            },
            {
              id: 'NOT_REVIEWED',
              label: 'No mapping',
            },
          ],
        },
        {
          id: 'status-filter',
          columnKey: 'alignment_status',
          titleText: 'Alignment status:',
          filterType: 'combobox',
          items: [
            {
              id: 'REVIEWED',
              label: 'With status',
            },
            {
              id: 'NOT_REVIEWED',
              label: 'Without Status',
            },
          ],
        },
        {
          id: 'category-filter',
          columnKey: 'category_status',
          titleText: 'Category:',
          label: 'Category:',
          filterType: 'combobox',
          items: [
            {
              id: 'REVIEWED',
              label: 'With category',
            },
            {
              id: 'NOT_REVIEWED',
              label: 'Without category',
            },
          ],
        },
        {
          id: 'overall-status-filter',
          columnKey: 'overall_status',
          titleText: 'Overall status:',
          label: 'Overall status:',
          filterType: 'combobox',
          items: [
            {
              id: 'IN_REVIEW',
              label: 'In Review',
            },
            {
              id: 'REVIEWED',
              label: 'Reviewed',
            },
            {
              id: 'APPROVED',
              label: 'Approved',
            },
            {
              id: 'REJECTED',
              label: 'Rejected',
            },
          ],
        },
      ]}
    />
  );

  let unReviewedControls = [];
  if (role === 'REVIEWER') {
    unReviewedControls = tableData.filter(
      y => y?.mapping?.length === 0 && y?.alignment_status === 'DEFAULT'
    );
  } else if (role === 'APPROVER' || role === 'COMPLETED') {
    unReviewedControls = tableData.filter(
      x => x.status !== 'APPROVED' && x.status !== 'REJECTED'
    );
  }

  const saveData = () => {
    if (mappingPayload?.length) {
      let data = {
        mapping_recommendation: mappingPayload,
      };
      editMappingRecommendation(data);
    }

    if (updatedRules?.length) {
      let controlIds = [...updatedRules];
      let controls = [];
      var grouped = _.mapValues(
        _.groupBy(controlIds, 'recommendation_id'),
        clist =>
          clist.map(mappingDetails =>
            _.omit(mappingDetails, 'recommendation_id')
          )
      );

      for (let items in grouped) {
        let obj = {};
        obj['control_id'] = items;
        obj['rules'] = grouped[items];
        controls.push(obj);
      }

      let data = { controls: controls };
      if (controls.length) {
        saveRules(mid, data)
          .then(result => {
            let { success = '' } = result?.data || {};
            if (success) {
              // setUpdatedRules([]);
              setUpdateMessage('Successfully updated the Rules!');
              setTimeout(() => {
                setUpdateMessage('');
              }, 5000);
              // getMappingDetails(selectedIndex);
            } else {
              setUpdateMessage(
                'Sorry, there was some issue while updating the rules. Please try again after sometime!'
              );
            }
          })
          .catch(err => {
            console.log('Error while updating rules ', err);
          });
      } else {
        setUpdateMessage("You haven't made any changes to the rules");
      }
    }
  };

  return (
    <div id="mapping-page">
      {!loading ? (
        <PageHeader
          isSticky={true}
          breadcrumbs={[
            {
              onClick: value => {
                history.push(
                  `/${
                    mDetails.mapping_mode === 'CROSSWALK'
                      ? 'crossmap'
                      : 'landingpage'
                  }?tabId=${
                    type === 'mapping'
                      ? mDetails.mapping_mode === 'CROSSWALK'
                        ? 'static'
                        : 'predefined'
                      : 'custom'
                  }`
                );
              },
              value:
                mDetails.mapping_mode === 'CROSSWALK'
                  ? 'Crossmap'
                  : 'Crosswalks',
            },
            {
              onClick: value => {
                history.push('#');
              },
              value: mDetails.profile_name,
            },
          ]}
          title={mDetails.profile_name}>
          {mDetails.approver &&
            mDetails.approver.includes(accountDetails.default_acc_name) &&
            mDetails.status !== 'APPROVED' && (
              <>
                <Button
                  className="btn--Rmargin"
                  disabled={
                    // categoryInfo?.category_information_provided === 'false' ||
                    READ_ONLY &&
                    mDetails.mapping_request_action_status === 'PENDING_REVIEW'
                  }
                  onClick={() => {
                    setRole('COMPLETED');
                    let unReviewedControls = tableData.filter(
                      x => x.status !== 'APPROVED' && x.status !== 'REJECTED'
                    );
                    if (
                      unReviewedControls.length &&
                      !(
                        mDetails.approver &&
                        mDetails.approver.includes(
                          accountDetails.default_acc_name
                        ) &&
                        mDetails.reviewer &&
                        mDetails.reviewer.includes(
                          accountDetails.default_acc_name
                        )
                      )
                    ) {
                      setConfirmModal(true);
                    } else {
                      setConfirmationModal(true);
                    }
                  }}>
                  Approve
                </Button>
                {!(
                  mDetails.approver &&
                  mDetails.approver.includes(accountDetails.default_acc_name) &&
                  mDetails.reviewer &&
                  mDetails.reviewer.includes(accountDetails.default_acc_name)
                ) && (
                  <Button
                    className="btn--Rmargin"
                    renderIcon={Send}
                    iconDescription="Add"
                    kind="tertiary"
                    disabled={
                      READ_ONLY &&
                      mDetails.mapping_request_action_status ===
                        'PENDING_REVIEW'
                    }
                    onClick={() => {
                      setRole('APPROVER');
                      setConfirmationModal(true);
                    }}>
                    Send to reviewer
                  </Button>
                )}
              </>
            )}
          {!(
            mDetails.reviewer &&
            mDetails.reviewer.includes(accountDetails.default_acc_name) &&
            mDetails.approver &&
            mDetails.approver.includes(accountDetails.default_acc_name)
          ) &&
            mDetails.reviewer &&
            mDetails.reviewer.includes(accountDetails.default_acc_name) &&
            mDetails.mapping_request_action_status !== 'PENDING_APPROVAL' &&
            mDetails.mapping_request_action_status !== 'COMPLETED' && (
              <Button
                className="btn--Rmargin"
                renderIcon={Send}
                iconDescription="Add"
                kind="tertiary"
                disabled={
                  // categoryInfo?.category_information_provided === 'false' ||
                  READ_ONLY
                }
                onClick={() => {
                  setRole('REVIEWER');
                  let unReviewedControls = tableData.filter(
                    y =>
                      y?.mapping?.length === 0 &&
                      y?.alignment_status === 'DEFAULT'
                  );
                  if (unReviewedControls.length === 0) {
                    let data = {
                      action_status: 'PENDING_APPROVAL',
                      account_id: accountDetails.acc_id,
                    };
                    assignUsers(data);
                  } else {
                    setConfirmModal(true);
                  }
                }}>
                Send to approver
              </Button>
            )}

          <ActionsPanel
            id="actionPanel"
            ariaLabel="download mapping"
            selectorPrimaryFocus="#example-action-panel-item-1">
            <OverflowMenuItem
              id="example-action-panel-item-3"
              itemText="Assign/ Unassign"
              disabled={
                !(mDetails.approver === null || mDetails.reviewer === null) &&
                (mDetails.approver &&
                  !mDetails.approver.includes(
                    accountDetails.default_acc_name
                  )) &&
                (mDetails.reviewer &&
                  !mDetails.reviewer.includes(
                    accountDetails.default_acc_name
                  )) &&
                (mDetails.requestor &&
                  !mDetails.requestor.includes(accountDetails.default_acc_name))
              }
              onClick={() => {
                window.analytics.track('Ran Process', {
                  'user.email': getEmailID(),
                  'user.bluemixId': getEmailID(),
                  productTitle: 'Crosswalks Tool',
                  name: 'Assign/ Unassign',
                  object: {},
                  processType: 'Click',
                  process: 'Event Detected',
                  successFlag: true,
                  resultValue: 'On Assign/ Unassign',
                  milestoneName: 'On Assign/ Unassign Users',
                  url: getHost(),
                });
                setSidePanel(true);
                setPanelType('assignUsers');
              }}
            />
            <OverflowMenuItem
              id="example-action-panel-item-1"
              itemText="Download Mapping"
              onClick={() => {
                let formData = new FormData();
                formData.append('download_file', true);
                formData.append('mapping_type', type);
                let rowData = {
                  source_profile_name: mDetails.target_profile_id,
                  mid: mid,
                  mapping_type: type,
                };
                exportProfileDetails(formData, mid)
                  .then(response => {
                    window.analytics.track('Exported Object', {
                      'user.email': getEmailID(),
                      'user.bluemixId': getEmailID(),
                      productTitle: 'Crosswalks Tool',
                      name: 'Download Mapping',
                      object: rowData,
                      objectType: 'csv',
                      successFlag: true,
                      resultValue: 'Mapping Generation - Download Mapping',
                      milestoneName:
                        'Mapping generation page mapping details downloaded',
                      url: getHost(),
                    });
                    const blob = new Blob([response.data], {
                      type: 'data:text/csv;charset=utf-8,',
                    });
                    const blobURL = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.download = `${mDetails.profile_name}.csv`;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                      'text/csv',
                      anchor.download,
                      anchor.href,
                    ].join(':');
                    anchor.click();
                  })
                  .catch(err => console.error(err));
              }}
            />
            <OverflowMenuItem
              id="example-action-panel-item-2"
              itemText="Download Oscal"
              onClick={() => {
                let { source_profile_id = '' } = mDetails;
                oscalDownload(source_profile_id)
                  .then(result => {
                    return result.data;
                  })
                  .then(data => {
                    window.analytics.track('Exported Object', {
                      'user.email': getEmailID(),
                      'user.bluemixId': getEmailID(),
                      productTitle: 'Crosswalks Tool',
                      name: 'Download Oscal',
                      object: mDetails,
                      objectType: 'json',
                      successFlag: true,
                      resultValue: 'Mapping Generation - Download Oscal',
                      milestoneName:
                        'Mapping generation page oscal details downloaded',
                      url: getHost(),
                    });
                    downloadFile(data.catalog, 'oscalData');
                  });
              }}
            />
          </ActionsPanel>
        </PageHeader>
      ) : (
        <div style={{ marginTop: '50px' }}>
          <PageHeaderSkeleton actions breadcrumbs locale="en" />
        </div>
      )}

      <SideNav
        isFixedNav
        expanded={true}
        isChildOfHeader={false}
        hasScrollContent={true}
        aria-label="Side navigation">
        <SideNavItems className="nav-link-top-margin">
          {configNavItems
            .filter(
              x =>
                !(
                  x.id === 'export-v1' &&
                  x.id === 'export-v2' &&
                  mDetails.mapping_mode === 'CROSSWALK'
                )
            )
            .filter(x => !(type === 'mapping' && x.id === 'dashboard'))
            .map((item, index) => {
              return (
                <SideNavLink activeHref={true}>
                  {item.id === 'export-v2' &&
                  categoryInfo?.category_information_provided === 'false' ? (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setCategoryModal(true);
                      }}>
                      {item.title}
                    </div>
                  ) : (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (mappingPayload?.length || updatedRules?.length) {
                          setSaveModal(true);
                        } else {
                          if (
                            item.id === 'export-v1' ||
                            item.id === 'export-v2'
                          ) {
                            history.push(`${item.link}/${type}/${mid}`);
                          } else if (item.id === 'dashboard') {
                            history.push(
                              `${
                                item.link
                              }/${mDetails?.mapping_mode?.toLowerCase() ||
                                'mapping'}/${mid}`
                            );
                          } else {
                            history.push(item.link);
                          }
                        }
                      }}>
                      {item.title}
                    </div>
                  )}
                </SideNavLink>
              );
            })}
        </SideNavItems>
      </SideNav>

      {/* <DataGridComponent
        rows={tableData}
        headers={headers}
        initialCols={initialCols}
      /> */}
      <div className="mapping--page">
        {showToast && mDetails.status !== 'APPROVED' && (
          <InlineNotification
            style={{ marginBottom: '15px' }}
            lowContrast
            timeout={3000}
            kind="success"
            title="Success"
            subtitle={
              role === 'COMPLETED'
                ? 'Mapping is approved'
                : `Mapping is sent for ${
                    role === 'REVIEWER' ? 'approval' : 'reviewer'
                  }.`
            }
          />
        )}
        {/* {categoryInfo?.category_information_provided === 'false' && (
          <InlineNotification
            style={{ marginBottom: '15px' }}
            lowContrast
            timeout={3000}
            kind="warning"
            title="Warning"
            subtitle={"Control id's with no category information."}
          />
        )} */}
        {mDetails.status === 'APPROVED' && (
          <div style={{ marginBottom: '20px' }}>
            <InlineNotification
              lowContrast
              kind="warning"
              iconDescription="describes the close button"
              subtitle={'Approved!'}
              timeout={0}
              title="Mapping status: "
            />
          </div>
        )}
        <ContentSwitcher
          style={{ width: '60%', display: 'inline-flex' }}
          selectedIndex={isListView ? 0 : 1}
          onChange={e => {
            if (e.index === 0) {
              setIsListView(true);
            } else {
              setIsListView(false);
            }
          }}>
          <Switch size="sm" name="list-view" text="List view" />
          <Switch size="sm" name="detailed-view" text="Detailed view" />
        </ContentSwitcher>
        {mDetails.mapping_mode === 'INTERACTIVE' && (
          <div style={{ marginBottom: '20px' }}>
            <p className="m-top f-weight">
              Please provide additional controls to generate a mapping
            </p>

            <div className="create-mapping-field">
              <TextInput
                id="control_id"
                labelText="Input control id"
                onChange={e =>
                  setNewMapping({
                    ...newMapping,
                    [e.target.id]: e.target.value,
                  })
                }
                value={newMapping.control_id}
              />
            </div>

            <div className="create-mapping-field">
              <TextInput
                id="control_title"
                labelText="Input control title (optional)"
                onChange={e =>
                  setNewMapping({
                    ...newMapping,
                    [e.target.id]: e.target.value,
                  })
                }
                value={newMapping.control_title}
              />
            </div>

            <div className="create-mapping-field">
              <TextInput
                id="control_text"
                labelText="Input control text"
                onChange={e =>
                  setNewMapping({
                    ...newMapping,
                    [e.target.id]: e.target.value,
                  })
                }
                value={newMapping.control_text}
              />
            </div>

            <div className="create-mapping-field">
              <TextInput
                id="control_category"
                labelText="Input control category (optional)"
                onChange={e =>
                  setNewMapping({
                    ...newMapping,
                    [e.target.id]: e.target.value,
                  })
                }
                value={newMapping.control_category}
              />
            </div>

            <div className="create-mapping-field">
              <TextInput
                id="source_parent_id"
                labelText="Input source parent id"
                onChange={e =>
                  setNewMapping({
                    ...newMapping,
                    [e.target.id]: e.target.value,
                  })
                }
                value={newMapping.source_parent_id}
              />
            </div>

            <Button
              type="primary"
              className="add-mapping-button"
              disabled={!(newMapping.control_id || newMapping.control_text)}
              onClick={() => {
                addNewMappingDetail();
              }}>
              Add Controls
            </Button>
          </div>
        )}
        <div style={{ float: 'right' }}>
          <Button
            size="md"
            kind="primary"
            disabled={READ_ONLY}
            className={`${COMPONENT_NAME}--save-btn`}
            onClick={saveData}>
            Save
          </Button>
        </div>

        {!validMapping && (
          <div className="top-margin">
            You are not valid to view this mapping
          </div>
        )}
        {isListView ? (
          <>
            {loading ? (
              <InlineLoading
                status="active"
                iconDescription="Loading"
                description="Loading list view data..."
              />
            ) : (
              <div id="mappingpage-table" className="mt10 table-b-padding">
                {tableContent}
              </div>
            )}
          </>
        ) : (
          <div className="card-t-padding mt10">
            {mDetails.status === 'INITIATED' && (
              <InlineNotification
                lowContrast
                kind="warning"
                title="Mapping is being initiated! Please refresh the page after sometime"
                subtitle=""
              />
            )}
            {showNotification &&
              (selectedRecommendations?.length > 0 ||
                rejectedRecommendations?.length > 0) && (
                <InlineNotification
                  lowContrast
                  timeout={5000}
                  kind="warning"
                  subtitle="Save the controls to ensure that the associated rules will be visible in the rules tab"
                />
              )}
            <div className="top-margin-1 bottom-pad">
              <div className="listview-filters">
                <Checkbox
                  id="checkbox-1"
                  labelText="Enable selected list view filters"
                  value={applyListViewFilter}
                  onChange={e => {
                    setApplyListViewFilter(e.target.checked);
                  }}
                />
                {mappingDetails.length > 1 && (
                  <PaginationNav
                    totalItems={
                      mDetails?.control_count || mDetails?.total_count
                    }
                    className="mapping-pagination"
                    onChange={index => {
                      setLoader(true);
                      setSelectedIndex(index);
                      // getRecommendationControlDetails(mappingDetails[index].id);
                      // getRulesDetails(mappingDetails[index].id);
                      setUpdateMessage('');
                    }}
                    page={selectedIndex}
                    itemsShown={4}
                  />
                )}
              </div>
              {mappingDetails && mappingDetails[selectedIndex] && (
                <div className="flex space--between backgroundGrey">
                  <div className="width--max" id="acceptedTagId">
                    <p className="data-styling">
                      <div style={{ fontWeight: 'bold' }}>
                        {mappingDetails[selectedIndex].source_control_id}
                      </div>
                      {mappingDetails[selectedIndex].source_control_title &&
                        mappingDetails[selectedIndex].source_control_id !==
                          mappingDetails[selectedIndex].source_control_title &&
                        mappingDetails[selectedIndex].source_control_desc !==
                          mappingDetails[selectedIndex].source_control_title &&
                        mappingDetails[
                          selectedIndex
                        ].source_control_desc?.slice(0, 200) !==
                          mappingDetails[selectedIndex]
                            .source_control_title && (
                          <div
                            style={{
                              marginBottom: '10px',
                              fontSize: '14px',
                              whiteSpace: 'pre-wrap',
                            }}>
                            {mappingDetails[
                              selectedIndex
                            ].source_control_title?.replace(/\\n/g, '')}
                          </div>
                        )}
                      {mappingDetails[selectedIndex].source_control_desc && (
                        <div
                          style={{
                            margin: '10px 0px',
                            fontSize: '14px',
                            whiteSpace: 'pre-wrap',
                          }}>
                          {mappingDetails[
                            selectedIndex
                          ]?.source_control_desc?.replace(/\\n/g, '')}
                        </div>
                      )}
                    </p>
                  </div>
                  <div
                    style={{
                      width: '70%',
                      paddingTop: '10px',
                      margin: '0px 20px',
                    }}>
                    <Tabs
                      defaultSelectedIndex={selectedTab}
                      onChange={e => setSelectedTab(e.selectedIndex)}>
                      <TabList aria-label="List of tabs">
                        <Tab>Recommendations</Tab>
                        <Tab>Control classification</Tab>
                        <Tab
                          className={`${
                            type === 'mapping' ? 'hidden-rule' : ''
                          }`}>
                          Rules
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <div ref={wrapperRef}>
                            <RecommendationTabDetails
                              controlRecommendations={controlRecommendations}
                              mid={mid}
                              READ_ONLY={READ_ONLY}
                              loader={loader}
                              accountId={accountDetails.acc_id}
                              mDetails={mDetails}
                              selectedRecommendations={selectedRecommendations}
                              selectedControls={selectedControls}
                              rejectedRecommendations={rejectedRecommendations}
                              selectedIndex={selectedIndex}
                              mappingDetails={mappingDetails}
                              refreshData={() => {
                                getMappingDetails(selectedIndex);
                                getAllRecommendations();
                              }}
                              openInfoSlider={item => {
                                setMetaDataSlider(true);
                                setControlItem(item);
                              }}
                              setRejectedRecommendations={value =>
                                setRejectedRecommendations(value)
                              }
                              setSelectedRecommendations={value =>
                                setSelectedRecommendations(value)
                              }
                              setSelectedControls={value =>
                                setSelectedControls(value)
                              }
                              setMappingPayload={value =>
                                setMappingPayload(value)
                              }
                              mappingPayload={mappingPayload}
                            />
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <ClassificationTabDetails
                            loader={loader}
                            READ_ONLY={READ_ONLY}
                            selectedMappingDetails={
                              mappingDetails[selectedIndex]
                            }
                            mappingPayload={mappingPayload}
                            mDetails={mDetails}
                            setMappingPayload={value =>
                              setMappingPayload(value)
                            }
                            refreshData={() => {
                              getMappingDetails(selectedIndex);
                              getAllRecommendations();
                            }}
                            mid={mid}
                            accountId={accountDetails.acc_id}
                          />
                        </TabPanel>
                        <TabPanel
                          className={`${
                            type === 'mapping' ? 'hidden-rule' : ''
                          }`}>
                          <RulesTabDetails
                            READ_ONLY={READ_ONLY}
                            accountId={accountDetails?.acc_id}
                            updatedRules={updatedRules}
                            showUpdateMessage={showUpdateMessage}
                            selectedMappingDetails={
                              mappingDetails[selectedIndex]
                            }
                            mid={mid}
                            selectedTab={selectedTab}
                            setUpdatedRules={value => setUpdatedRules(value)}
                            setUpdateMessage={value => setUpdateMessage(value)}
                            selectedControls={selectedControls}
                          />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showSidePanel && (
          <ControlClassificationPanel
            accountId={accountDetails.acc_id}
            panelType={panelType}
            mappingPayload={mappingPayload}
            selectedMappingDetails={mappingDetails[selectedId]}
            editMappingRecommendation={editMappingRecommendation}
            mDetails={mDetails}
            mid={mid}
            setMappingPayload={value => setMappingPayload(value)}
            refreshData={() => {
              if (panelType === 'assignUsers') {
                getMappingDetails(selectedId);
              } else if (panelType === 'comments') {
                getMappingDetails(selectedId);
                getAllRecommendations();
              } else {
                getAllRecommendations();
              }
            }}
            setSidePanel={bool => setSidePanel(bool)}
            READ_ONLY={READ_ONLY}
          />
        )}
        {metaDataSlider && (
          <MetaDataPanel
            controlItem={controlItem}
            controlTitle={controlTitle}
            onClose={() => {
              setMetaDataSlider(false);
              setControlTitle('');
              setControlItem('');
            }}
          />
        )}
      </div>
      {confirmModal && (
        <ReviewerModal
          confirmModal={confirmModal}
          role={role}
          setConfirmModal={bool => setConfirmModal(bool)}
          mDetails={mDetails}
          unReviewedControls={unReviewedControls}
          accountDetails={accountDetails}
          assignUsers={data => assignUsers(data)}
        />
      )}
      {confirmationModal && (
        <ApproverModal
          confirmationModal={confirmationModal}
          role={role}
          mid={mid}
          setConfirmationModal={bool => setConfirmationModal(bool)}
          mDetails={mDetails}
          unReviewedControls={unReviewedControls}
          accountDetails={accountDetails}
          assignUsers={data => assignUsers(data)}
          editMappingRecommendation={data => editMappingRecommendation(data)}
        />
      )}
      {categoryModal && (
        <CategoryInfoModal
          categoryModal={categoryModal}
          categoryInfo={categoryInfo}
          setCategoryModal={bool => setCategoryModal(bool)}
        />
      )}
      {saveModal && (
        <SaveDataModal
          saveModal={saveModal}
          setSaveModal={bool => setSaveModal(bool)}
          saveData={saveData}
        />
      )}
    </div>
  );
};

export default MappingPageOne;
