import React, { useState, useEffect, useCallback } from 'react';
import {
  Content,
  Modal,
  Tabs,
  TabList,
  TabPanel,
  Tab,
  TabPanels,
  ComboBox,
  ProgressIndicator,
  ProgressStep,
} from '@carbon/react';
import {
  SidePanel,
  SidePanelContainer,
  EnhancedDataTable,
  PageHeader,
} from '@console/pal/Components';
import { CheckmarkFilled } from '@carbon/icons-react';
import Quickstart from '../common/Quickstart';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {
  getMappings,
  oscalDownload,
  deleteMapping,
  getUserRoles,
  assignUserRole,
  getMappingHistory,
} from '../../api/LandingPage';
import { exportProfileDetails } from '../../api/ExportProfile';
import { downloadFile } from '../../helpers/downloadJson';
import { MAPPING_STATUS } from '../../config/mapping';
import { getEmailID } from '../../api/common';
import { getHost } from '../../helpers/urls';
import moment from 'moment';

const COMPONENT_NAME = 'mapping-generation';

const headers = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'target_profile_name',
    header: 'Target Type',
  },
  {
    key: 'control_count',
    header: 'Controls',
  },
  {
    key: 'goal_count',
    header: 'Goals',
  },
  {
    key: 'mapping_mode',
    header: 'Mode',
  },
  {
    key: 'statusIcon',
    header: 'Status',
  },
  {
    key: 'created_by',
    header: 'Created By',
  },
  {
    key: 'createdOn',
    header: 'Created On',
  },
  {
    key: 'last_reviewed_by',
    header: 'Last Reviewed By',
  },
  {
    key: 'reviewedOn',
    header: 'Reviewed On',
  },
  {
    key: 'last_approved_by',
    header: 'Last Approved By',
  },
  {
    key: 'approvedOn',
    header: 'Approved On',
  },
  {
    key: 'reviewers',
    header: 'Reviewer',
  },
  {
    key: 'approvers',
    header: 'Approver',
  },
];
const initialCols = [
  'name',
  'target_profile_name',
  'control_count',
  'statusIcon',
  'created_by',
  'createdOn',
  'reviewers',
  'approvers',
];

const LandingContent = props => {
  let { accountDetails = {} } = props;
  let {
    acc_id = '',
    acc_name = '',
    default_acc_name = '',
    default_acc_id = '',
  } = accountDetails;
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [rowId, setRowId] = useState('');
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [reviewers, setReviewers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [reviewer, setReviewer] = useState('');
  const [approver, setApprover] = useState('');
  const [reviewerName, setReviewerName] = useState('');
  const [approverName, setApproverName] = useState('');
  const [mappingType, setMappingType] = useState('');
  const [mappingHistory, setMappingHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchUrl = location?.search;
    const params = new URLSearchParams(searchUrl);
    const tabId = params.get('tabId');
    if (tabId === 'predefined') {
      setMappingType('PREDEFINED');
    } else {
      setMappingType('CUSTOM');
    }
  }, [location?.search]);

  const getData = useCallback(
    (mappingType = '', search = '') => {
      let queryParams = {
        account_id: acc_id,
        type: mappingType,
      };
      setLoader(true);
      if (search) queryParams = { ...queryParams, search };
      const query = new URLSearchParams(queryParams).toString();
      try {
        getMappings(query).then(result => {
          let { mappings = [] } = result.data;
          setData(mappings);
          setLoader(false);
        });
      } catch (error) {
        setLoader(false);
      }
    },
    [acc_id]
  );

  const getUserList = useCallback(() => {
    getUserRoles(acc_id)
      .then(result => {
        let { users = [] } = result.data;
        let reviewersList = [];
        let approversList = [];
        for (let list of users) {
          let { roles = [] } = list;
          if (roles.includes('REVIEWER')) {
            reviewersList.push(list);
          }
          if (roles.includes('APPROVER')) {
            approversList.push(list);
          }
        }
        setReviewers(reviewersList);
        setApprovers(approversList);
      })
      .catch(err => console.log('err ', err));
  }, [acc_id]);

  useEffect(() => {
    if (props?.accountDetails?.acc_id) {
      getUserList();
    }
  }, [getUserList, props?.accountDetails?.acc_id]);

  useEffect(() => {
    if (props?.accountDetails?.acc_id && mappingType) {
      getData(mappingType);
    }
  }, [getData, props?.accountDetails?.acc_id, mappingType]);

  useEffect(() => {
    if (data) {
      let updatedRows = [];
      for (let list of data) {
        let obj = { ...list };
        obj['name'] = list.source_profile_name;
        obj['name__format'] = (
          <Link
            to={`/crosswalk/mapping-details/${list.mapping_type.toLowerCase()}/${
              list.mid
            }`}>
            {list.source_profile_name}
          </Link>
        );
        obj['statusIcon'] = (
          <div>
            {list.mapping_status === 'APPROVED' ? (
              <CheckmarkFilled className="green" />
            ) : (
              ''
            )}{' '}
            <span>{list.mapping_status.replaceAll('_', ' ')}</span>
          </div>
        );

        obj['createdOn'] = list.created_at
          ? moment
              .utc(list.created_at)
              .local()
              .format('YYYY-MM-DD LT')
          : '-';
        obj['approvedOn'] = list.last_approved_at
          ? moment
              .utc(list.last_approved_at)
              .local()
              .format('YYYY-MM-DD LT')
          : '-';
        obj['reviewedOn'] = list.last_reviewed_at
          ? moment
              .utc(list.last_reviewed_at)
              .local()
              .format('YYYY-MM-DD LT')
          : '-';
        obj['reviewers__format'] = (
          <div>{list.reviewers ? list.reviewers.join(', ') : '-'}</div>
        );
        obj['approvers__format'] = (
          <div>{list.approvers ? list.approvers.join(', ') : '-'}</div>
        );
        updatedRows.push(obj);
      }
      setRows(updatedRows);
    }
  }, [data]);

  const onPanelClose = () => {
    setShowSidePanel(false);
  };

  const assignUsers = formData => {
    assignUserRole(formData, rowId.mid)
      .then(res => {
        getData(mappingType);
        onPanelClose();
      })
      .catch(err => {
        onPanelClose();
      });
  };

  const tableOverflowActions = useCallback(
    (_rowId, rowData) => {
      let { approvers = [], reviewers = [], created_by = '' } = rowData;

      if (mappingType === 'CUSTOM') {
        return [
          {
            itemText: 'Assign/ Unassign',
            disabled:
              !(approvers === null || reviewers === null) &&
              (approvers && !approvers.includes(acc_name)) &&
              (reviewers && !reviewers.includes(acc_name)) &&
              created_by !== default_acc_name,
            onClick: value => {
              window.analytics.track('Ran Process', {
                'user.email': getEmailID(),
                'user.bluemixId': getEmailID(),
                productTitle: 'Crosswalks Tool',
                name: 'Assign/ Unassign',
                object: rowData,
                processType: 'Click',
                process: 'Event Detected',
                successFlag: true,
                resultValue: 'On Assign/ Unassign',
                milestoneName: 'On Assign/ Unassign Users',
                url: getHost(),
              });
              setRowId(rowData);
              setShowSidePanel(true);
            },
          },
          {
            itemText: 'Download Mapping',
            disabled: false,
            onClick: value => {
              let {
                source_profile_name = '',
                mid = '',
                mapping_type = '',
              } = rowData;
              let formData = new FormData();
              formData.append('download_file', true);
              formData.append('mapping_type', mapping_type);
              exportProfileDetails(formData, mid)
                .then(response => {
                  window.analytics.track('Exported Object', {
                    'user.email': getEmailID(),
                    'user.bluemixId': getEmailID(),
                    productTitle: 'Crosswalks Tool',
                    name: 'Download Mapping',
                    object: rowData,
                    objectType: 'csv',
                    successFlag: true,
                    resultValue: 'Landing - Download Mapping',
                    milestoneName:
                      'Landing page mapping generation details downloaded',
                    url: getHost(),
                  });
                  const blob = new Blob([response.data], {
                    type: 'data:text/csv;charset=utf-8,',
                  });
                  const blobURL = window.URL.createObjectURL(blob);
                  const anchor = document.createElement('a');
                  anchor.download = `${source_profile_name}.csv`;
                  anchor.href = blobURL;
                  anchor.dataset.downloadurl = [
                    'text/csv',
                    anchor.download,
                    anchor.href,
                  ].join(':');
                  anchor.click();
                })
                .catch(err => console.error(err));
            },
          },
          {
            itemText: 'Download Oscal',
            disabled: false,
            onClick: value => {
              let { source_profile_id = '' } = rowData;
              oscalDownload(source_profile_id)
                .then(result => {
                  return result.data;
                })
                .then(data => {
                  window.analytics.track('Exported Object', {
                    'user.email': getEmailID(),
                    'user.bluemixId': getEmailID(),
                    productTitle: 'Crosswalks Tool',
                    name: 'Download Oscal',
                    object: rowData,
                    objectType: 'json',
                    successFlag: true,
                    resultValue: 'Landing - Download Oscal',
                    milestoneName: 'Landing page oscal details downloaded',
                    url: getHost(),
                  });
                  downloadFile(data.catalog, 'oscalData');
                });
            },
          },
          {
            itemText: 'Delete',
            disabled: created_by !== default_acc_name,
            onClick: value => {
              let { source_profile_name = '' } = rowData;
              setRowId(rowData);
              setDeleteModal(true);
              window.analytics.track('Ran Process', {
                'user.email': getEmailID(),
                'user.bluemixId': getEmailID(),
                productTitle: 'Crosswalks Tool',
                name: 'Delete',
                object: rowData,
                processType: 'Click',
                process: 'Event Detected',
                successFlag: true,
                resultValue: `On Delete ${source_profile_name} Mapping`,
                milestoneName: `On Delete ${source_profile_name} from Landing page`,
                url: getHost(),
              });
            },
            isDelete: true,
          },
        ];
      } else {
        return [
          {
            itemText: 'Download Mapping',
            disabled: false,
            onClick: value => {
              let {
                source_profile_name = '',
                mid = '',
                mapping_type = '',
              } = rowData;
              let formData = new FormData();
              formData.append('download_file', true);
              formData.append('mapping_type', mapping_type);
              exportProfileDetails(formData, mid)
                .then(response => {
                  window.analytics.track('Exported Object', {
                    'user.email': getEmailID(),
                    'user.bluemixId': getEmailID(),
                    productTitle: 'Crosswalks Tool',
                    name: 'Download Mapping',
                    object: rowData,
                    objectType: 'csv',
                    successFlag: true,
                    resultValue: 'Landing - Download Mapping',
                    milestoneName:
                      'Landing page mapping generation details downloaded',
                    url: getHost(),
                  });
                  const blob = new Blob([response.data], {
                    type: 'data:text/csv;charset=utf-8,',
                  });
                  const blobURL = window.URL.createObjectURL(blob);
                  const anchor = document.createElement('a');
                  anchor.download = `${source_profile_name}.csv`;
                  anchor.href = blobURL;
                  anchor.dataset.downloadurl = [
                    'text/csv',
                    anchor.download,
                    anchor.href,
                  ].join(':');
                  anchor.click();
                })
                .catch(err => console.error(err));
            },
          },
          {
            itemText: 'Download Oscal',
            disabled: false,
            onClick: value => {
              let { source_profile_id = '' } = rowData;
              oscalDownload(source_profile_id)
                .then(result => {
                  return result.data;
                })
                .then(data => {
                  window.analytics.track('Exported Object', {
                    'user.email': getEmailID(),
                    'user.bluemixId': getEmailID(),
                    productTitle: 'Crosswalks Tool',
                    name: 'Download Oscal',
                    object: rowData,
                    objectType: 'json',
                    successFlag: true,
                    resultValue: 'Landing - Download Oscal',
                    milestoneName: 'Landing page oscal details downloaded',
                    url: getHost(),
                  });
                  downloadFile(data.catalog, 'oscalData');
                });
            },
          },
        ];
      }
    },
    [acc_name, default_acc_name, mappingType]
  );

  const generateRowDetails = rowId => {
    let rowDetails = rows.find(x => x.id === rowId);
    let { mid = '', mapping_status = '' } = rowDetails || {};
    let mDetails = mappingHistory;
    let index = mDetails.findIndex(x => x.id === mid);
    if (index < 0) {
      getMappingHistory(mid, mapping_status)
        .then(result => {
          return result.data;
        })
        .then(data => {
          let index = mDetails.findIndex(x => x.id === mid);
          if (index < 0)
            mDetails.push({
              id: mid,
              data: data.history,
            });
        })
        .catch(err => {
          console.log('err ', err);
        });
    }
    setMappingHistory(mDetails);

    return (
      <div style={{ padding: '20px 0px' }}>
        <h6>Mapping Generation Details</h6>
        <br />
        <ProgressIndicator
          currentIndex={`${
            rowDetails.mapping_status === 'APPROVED'
              ? '3'
              : rowDetails.mapping_status === 'READY_FOR_REVIEW'
              ? '2'
              : '1'
          }`}
          spaceEqually={true}>
          <ProgressStep
            label="Created By"
            description="The progress indicator will listen for clicks on the steps"
            secondaryLabel={`${rowDetails.created_by} on ${
              rowDetails.createdOn
            }`}
          />
          <ProgressStep
            label="Reviewed By"
            description="The progress indicator will listen for clicks on the steps"
            secondaryLabel={`${rowDetails.last_reviewed_by} on ${
              rowDetails.reviewedOn
            }`}
          />
          <ProgressStep
            label="Approved By"
            description="The progress indicator will listen for clicks on the steps"
            secondaryLabel={`${rowDetails.last_approved_by} on ${
              rowDetails.approvedOn
            }`}
          />
        </ProgressIndicator>
        <br />
      </div>
    );
  };

  const RenderDatatable = () => {
    if (loader) {
      return (
        <EnhancedDataTable
          id="basic-table"
          headers={
            mappingType === 'CUSTOM'
              ? headers
              : headers.filter(
                  x =>
                    x.key !== 'last_reviewed_by' &&
                    x.key !== 'last_approved_by' &&
                    x.key !== 'reviewedOn' &&
                    x.key !== 'approvedOn' &&
                    x.key !== 'reviewers' &&
                    x.key !== 'approvers' &&
                    x.key !== 'statusIcon'
                )
          }
          initialCols={
            mappingType === 'CUSTOM'
              ? initialCols
              : initialCols.filter(x => x !== 'reviewers' && x !== 'approvers')
          }
        />
      );
    } else {
      return (
        <EnhancedDataTable
          onFilterChange={e => getData(mappingType, e)}
          actions={
            mappingType === 'CUSTOM' && [
              {
                kind: 'primary',
                label: 'Create',
                onClick: function handleCreate() {
                  window.analytics.track('Ran Process', {
                    'user.email': getEmailID(),
                    'user.bluemixId': getEmailID(),
                    productTitle: 'Crosswalks Tool',
                    name: 'Create',
                    object: { name: 'Create', event: 'Click' },
                    processType: 'Click',
                    process: 'Event Detected',
                    successFlag: true,
                    resultValue: 'On Create button click',
                    milestoneName: 'On Create button click from landing page',
                    url: getHost(),
                  });
                  history.push('./create');
                },
                disabled: acc_id !== default_acc_id,
              },
              {
                kind: 'secondary',
                label: 'Import',
                onClick: function handleImport() {
                  window.analytics.track('Ran Process', {
                    'user.email': getEmailID(),
                    'user.bluemixId': getEmailID(),
                    productTitle: 'Crosswalks Tool',
                    name: 'Import Mapping',
                    object: {
                      name: 'Import Mapping',
                      event: 'Click',
                    },
                    processType: 'Click',
                    process: 'Event Detected',
                    successFlag: true,
                    resultValue: 'On Import Mapping button click',
                    milestoneName:
                      'On Import Mapping button click from landing page',
                    url: getHost(),
                  });
                  history.push('./import-mapping');
                },
                disabled: acc_id !== default_acc_id,
              },
            ]
          }
          size="md"
          id="table-with-inline-filter"
          className={`${COMPONENT_NAME}__datatable`}
          rows={rows.filter(x => x.type === mappingType)}
          headers={
            mappingType === 'CUSTOM'
              ? headers
              : headers.filter(
                  x =>
                    x.key !== 'last_reviewed_by' &&
                    x.key !== 'last_approved_by' &&
                    x.key !== 'reviewedOn' &&
                    x.key !== 'approvedOn' &&
                    x.key !== 'reviewers' &&
                    x.key !== 'approvers' &&
                    x.key !== 'statusIcon'
                )
          }
          initialCols={
            mappingType === 'CUSTOM'
              ? initialCols
              : initialCols.filter(x => x !== 'reviewers' && x !== 'approvers')
          }
          filters={
            mappingType === 'PREDEFINED'
              ? []
              : [
                  {
                    id: 'mapping_status-filter',
                    columnKey: 'mapping_status',
                    titleText: 'Status:',
                    label: 'Status',
                    items: MAPPING_STATUS,
                  },
                ]
          }
          rowDetail={
            mappingType === 'CUSTOM'
              ? rowId => generateRowDetails(rowId)
              : false
          }
          rowActions={tableOverflowActions}
        />
      );
    }
  };

  return (
    <Content id="main-content">
      <PageHeader
        title="Mapping generation"
        className={`${COMPONENT_NAME}--page-title`}
      />
      <Quickstart />
      <br />
      <Tabs
        selectedIndex={mappingType === 'CUSTOM' ? 0 : 1}
        onChange={e => {
          let { selectedIndex } = e;
          if (selectedIndex === 1) {
            setMappingType('PREDEFINED');
            history.push({
              pathname: '/landingpage',
              search: '?tabId=predefined',
            });
          } else {
            setMappingType('CUSTOM');
            history.push({
              pathname: '/landingpage',
              search: '?tabId=custom',
            });
          }
        }}>
        <TabList aria-label="List of tabs">
          <Tab>Custom</Tab>
          <Tab>Predefined</Tab>
        </TabList>
        <TabPanels style={{ background: '#fff', padding: '10px' }}>
          <TabPanel>
            <RenderDatatable />
          </TabPanel>
          <TabPanel>
            <RenderDatatable />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal
        modalHeading={`Delete ${rowId.source_profile_name} mapping`}
        open={deleteModal}
        onRequestClose={() => setDeleteModal(false)}
        onRequestSubmit={() => {
          window.analytics.track('Deleted Object', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: 'Delete mapping',
            data: {
              name: rowId.source_profile_name,
              mid: rowId.mid,
              type: rowId.mapping_type,
            },
            object: { mid: rowId.mid, type: rowId.mapping_type },
            objectType: 'json',
            successFlag: true,
            resultValue: `Deleted ${rowId.source_profile_name} mapping`,
            milestoneName: `Delete ${rowId.source_profile_name} mapping`,
            url: getHost(),
          });
          deleteMapping(rowId.mid, rowId.mapping_type);
          setDeleteModal(false);
          getData(mappingType);
        }}
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        danger>
        <div>
          Are you sure you want to delete {rowId.source_profile_name} mapping.
        </div>
      </Modal>
      <SidePanelContainer
        panelSize="medium"
        includeOverlay={true}
        isOpen={showSidePanel}
        doneText="Submit"
        onCloseClick={onPanelClose}
        onDoneClick={() => {
          if (reviewer) {
            let formData = {
              user_id: reviewer,
              type: 'REVIEWER',
            };
            assignUsers(formData);
          }
          if (approver) {
            let formData = {
              user_id: approver,
              type: 'APPROVER',
            };
            assignUsers(formData);
          }
          window.analytics.track('Updated Object', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: 'Assign/ Unassign',
            data: {
              r_user_id: reviewer,
              r_type: 'REVIEWER',
              a_user_id: approver,
              a_type: 'APPROVER',
            },
            object: {
              r_user_id: reviewer,
              r_type: 'REVIEWER',
              a_user_id: approver,
              a_type: 'APPROVER',
            },
            objectType: 'json',
            successFlag: true,
            resultValue: 'Assign/ Unassign users saved',
            milestoneName: 'Assign/ Unassign users saved',
            url: getHost(),
          });
        }}
        onCancelClick={onPanelClose}>
        <SidePanel title="Assign User" id="panel-1">
          <>
            <ComboBox
              onChange={e => {
                if (e.selectedItem) {
                  setReviewer(e.selectedItem.id);
                  setReviewerName(e.selectedItem.name);
                }
              }}
              id="reviewer-combobox"
              items={reviewers}
              selectedItem={
                reviewerName
                  ? {
                      name: reviewerName,
                    }
                  : {
                      name:
                        rowId.reviewers && rowId.reviewers.length
                          ? rowId.reviewers[0]
                          : '',
                    }
              }
              itemToString={item => (item ? item.name : '')}
              titleText="Reviewer"
              placeholder="Select Reviewer"
            />
            <br />
            <ComboBox
              id="approver-combobox"
              titleText="Approver"
              placeholder="Select Approver"
              items={approvers}
              selectedItem={
                approverName
                  ? { name: approverName }
                  : {
                      name:
                        rowId.approvers && rowId.approvers.length
                          ? rowId.approvers[0]
                          : '',
                    }
              }
              itemToString={item => (item ? item.name : '')}
              onChange={e => {
                if (e.selectedItem) {
                  setApprover(e.selectedItem.id);
                  setApproverName(e.selectedItem.name);
                }
              }}
            />
          </>
        </SidePanel>
      </SidePanelContainer>
    </Content>
  );
};

export default LandingContent;
