import React, { useState, useEffect, useCallback } from 'react';
import {
  Content,
  Form,
  TextInput,
  Tag,
  MultiSelect,
  InlineNotification,
} from '@carbon/react';
import {
  SidePanel,
  SidePanelContainer,
  EnhancedDataTable,
  PageHeader,
} from '@console/pal/Components';
import { CheckmarkFilled, WarningFilled } from '@carbon/icons-react';
import { getUsers, inviteUser, updateUser } from '../../api/UserInvitation';
import capitalize from 'lodash/capitalize';
import { getEmailID } from '../../api/common';
import { getHost } from '../../helpers/urls';
import './userInvitation.scss';

const COMPONENT_NAME = 'user-invitation';
const UserContent = props => {
  const { accountDetails = {} } = props;
  let { acc_id = '', default_acc_id = '' } = accountDetails;
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [sidePanelDetails, setSidePanelDetails] = useState({});
  const [rows, setRows] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [showToast, setToastNotification] = useState({ kind: '', message: '' });
  const [errorMessage, setErrorMessage] = useState([]);
  const [loader, setLoader] = useState(false);

  const getUserDetails = useCallback(() => {
    setLoader(true);
    getUsers(accountDetails.acc_id)
      .then(result => {
        let { invitations = [] } = result.data;
        let tableData = [];

        for (let user of invitations) {
          let { email, roles, status, id, user_name = '' } = user;
          let roleIds = [];
          for (let roleDetails of roles) {
            let { id = '' } = roleDetails;
            roleIds.push(id);
          }
          let obj = {
            id: id,
            username: user_name || email,
            email: email,
            role: roleIds.join(','),
            status: status === 'COMPLETED' ? 'Active' : capitalize(status),
            status__format: (
              <div>
                {status === 'COMPLETED' ? (
                  <CheckmarkFilled className="green" />
                ) : (
                  <WarningFilled className="yellow" />
                )}{' '}
                <span>
                  {status === 'COMPLETED' ? 'Active' : capitalize(status)}
                </span>
              </div>
            ),
            role__format: (
              <>
                {roles.map(role => {
                  return (
                    <Tag
                      type={
                        role.id === 2
                          ? 'blue'
                          : role.id === 3
                          ? 'green'
                          : 'grey'
                      }
                      size="md">
                      {role.id === 2
                        ? 'Reviewer'
                        : role.id === 3
                        ? 'Approver'
                        : 'Requestor'}
                    </Tag>
                  );
                })}
              </>
            ),
          };
          tableData.push(obj);
        }
        setRows(tableData);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        console.log(err);
      });
  }, [accountDetails.acc_id]);

  useEffect(() => {
    if (accountDetails.acc_id) {
      getUserDetails();
    }
  }, [getUserDetails, accountDetails]);

  useEffect(() => {
    if (sidePanelDetails.role && sidePanelDetails.role.length > 0) {
      let roles = [];
      for (let role of sidePanelDetails.role.split(',')) {
        let obj = {
          id: role,
          label:
            role === '2' ? 'Reviewer' : role === '3' ? 'Approver' : 'Requestor',
        };
        roles.push(obj);
      }
      setUserRole(roles);
    } else {
      setUserRole([]);
    }
  }, [sidePanelDetails]);

  const headers = [
    {
      key: 'username',
      header: 'Username',
    },
    {
      key: 'email',
      header: 'Email',
    },
    {
      key: 'status',
      header: 'Status',
    },
    {
      key: 'role',
      header: 'Role',
    },
  ];

  const initialCols = ['username', 'email', 'role', 'status'];

  const tableOverflowActions = (_rowId, rowData) => {
    let { status = '', role = '', email = '' } = rowData;
    return [
      {
        itemText: status === 'Active' ? 'Edit' : 'Resend invite',
        disabled: acc_id !== default_acc_id,
        onClick: value => {
          if (status === 'Active') {
            setShowSidePanel(true);
            setSidePanelDetails({
              ...rowData,
            });
          } else {
            let formData = {
              email: email,
              account_id: accountDetails.acc_id,
              role: role === 'Approver' ? '3' : '2',
            };
            sendUserInvitation(formData, 'Resend invite');
          }
          window.analytics.track('Ran Process', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: status === 'Active' ? 'Edit' : 'Resend invite',
            object: {},
            processType: 'Click',
            process: 'Event Detected',
            successFlag: true,
            resultValue:
              status === 'Active'
                ? 'Edit clicked from User Invitations page'
                : 'Resend invite clicked from User Invitations page',
            milestoneName:
              status === 'Active'
                ? 'Edit clicked from User Invitations page'
                : 'Resend invite clicked from User Invitations page',
            url: getHost(),
          });
        },
      },
    ];
  };

  const userActiveList = (
    <EnhancedDataTable
      size="md"
      id="table-with-inline-filter"
      className={`userInvitation__datatable`}
      rows={rows}
      headers={headers}
      initialCols={initialCols}
      rowActions={tableOverflowActions}
      actions={[
        {
          kind: 'primary',
          label: 'Invite user',
          onClick: function handleCreate() {
            setSidePanelDetails({});
            setShowSidePanel(true);
            window.analytics.track('Ran Process', {
              'user.email': getEmailID(),
              'user.bluemixId': getEmailID(),
              productTitle: 'Crosswalks Tool',
              name: 'Invite user',
              object: {},
              processType: 'Click',
              process: 'Event Detected',
              successFlag: true,
              resultValue: 'Invite user clicked from User Invitations page',
              milestoneName: 'Invite user clicked from User Invitations page',
              url: getHost(),
            });
          },
          disabled: acc_id !== default_acc_id,
        },
      ]}
      filters={[
        {
          id: 'status-filter',
          columnKey: 'status',
          titleText: 'Status:',
          label: 'Status',
          items: [
            { id: 'Active', label: 'Active' },
            { id: 'Pending', label: 'Pending' },
          ],
        },
        {
          id: 'role-filter',
          columnKey: 'role',
          titleText: 'Role:',
          label: 'Role',
          items: [
            { id: '3', label: 'Approver' },
            { id: '2', label: 'Reviewer' },
          ],
        },
      ]}
    />
  );

  const onPanelClose = () => {
    setShowSidePanel(false);
    setSidePanelDetails({});
    setErrorMessage([]);
  };

  const sendUserInvitation = (formData = {}, status = '') => {
    setToastNotification({ kind: '', message: '' });
    inviteUser(formData)
      .then(result => {
        let { data = {} } = result;
        let { success = '', err = '' } = data;
        if (success === 'true' || status === 'Resend invite') {
          setToastNotification({
            kind: 'success',
            message: 'User invitation is sent successfully!',
          });
          onPanelClose();
          getUserDetails();
        } else {
          setToastNotification({ kind: 'warning', message: err });
        }
      })
      .catch(err => {
        console.log(err);
        setToastNotification({
          kind: 'warning',
          message: err?.response?.data?.err,
        });
      });
  };

  const updateUserInvitation = (invitationId = '', formData = {}) => {
    setToastNotification({ kind: '', message: '' });
    updateUser(invitationId, formData)
      .then(result => {
        let { data = {} } = result;
        let { success = '', err = '' } = data;
        if (success === true) {
          setToastNotification({
            kind: 'success',
            message: 'User invitation is updated successfully!',
          });
          onPanelClose();
          getUserDetails();
        } else {
          setToastNotification({ kind: 'warning', message: err });
        }
      })
      .catch(err => {
        console.log(err);
        setToastNotification({
          kind: 'warning',
          message: err?.response?.data?.err,
        });
      });
  };

  return (
    <Content id="main-content" className={COMPONENT_NAME}>
      <PageHeader
        title="User invitation"
        className={`${COMPONENT_NAME}--page-title`}
      />
      {loader ? (
        <EnhancedDataTable
          id="basic-table"
          headers={headers}
          initialCols={initialCols}
        />
      ) : (
        userActiveList
      )}
      <SidePanelContainer
        includeOverlay={true}
        panelSize="medium"
        isOpen={showSidePanel}
        doneText="Submit"
        onCloseClick={onPanelClose}
        disabled={acc_id !== default_acc_id}
        onDoneClick={() => {
          let errors = [];
          let { email = '', role, status = '', id = '' } =
            sidePanelDetails || {};
          if (email && role) {
            let formData = {
              email: email,
              role_id: role,
              account_id: accountDetails.acc_id,
            };
            window.analytics.track('Updated Object', {
              'user.email': getEmailID(),
              'user.bluemixId': getEmailID(),
              productTitle: 'Crosswalks Tool',
              name: 'Invite user',
              data: formData,
              object: formData,
              objectType: 'json',
              successFlag: true,
              resultValue: 'Invite users saved',
              milestoneName: 'Invite users saved',
              url: getHost(),
            });

            if (status === 'Active') {
              updateUserInvitation(id, formData);
            } else {
              sendUserInvitation(formData, 'New User');
            }
          } else if (!email && !role) {
            errors.push('email', 'role');
          } else if (!email) {
            errors.push('email');
          } else if (!role) {
            errors.push('role');
          }
          setErrorMessage(errors);
        }}
        onCancelClick={onPanelClose}>
        <SidePanel
          title={`${
            sidePanelDetails && sidePanelDetails.username
              ? 'Edit user'
              : 'Invite user'
          }`}
          id="panel-1">
          {showToast.kind === 'warning' ? (
            <div style={{ marginBottom: '15px' }}>
              <InlineNotification
                lowContrast
                timeout={6000}
                kind={'warning'}
                title={'Warning!'}
                subtitle={showToast.message}
              />
            </div>
          ) : (
            showToast.kind === 'success' && (
              <div style={{ marginBottom: '15px' }}>
                <InlineNotification
                  lowContrast
                  timeout={6000}
                  kind={'success'}
                  title={'Success!'}
                  subtitle={showToast.message}
                />
              </div>
            )
          )}
          <Form>
            <TextInput
              required
              labelText="Username"
              placeholder="Enter username..."
              id="invite-user"
              value={
                sidePanelDetails && sidePanelDetails.username
                  ? sidePanelDetails.username
                  : ''
              }
              onChange={e => {
                setSidePanelDetails({
                  ...sidePanelDetails,
                  username: e.target.value,
                });
              }}
            />
            <br />
            <TextInput
              required
              labelText="Email id"
              placeholder="Enter email id..."
              id="invite-user"
              value={
                sidePanelDetails && sidePanelDetails.email
                  ? sidePanelDetails.email
                  : ''
              }
              onChange={e => {
                setSidePanelDetails({
                  ...sidePanelDetails,
                  email: e.target.value,
                });
              }}
            />
            {errorMessage.includes('email') && (
              <div className="error-message">Please enter valid email id</div>
            )}
            <br />
            <MultiSelect
              required
              labelText="Role"
              placeholder="User role"
              titleText="Role"
              items={[
                { id: '2', label: 'Reviewer' },
                { id: '3', label: 'Approver' },
              ]}
              onChange={({ selectedItems }) => {
                let roles = [];
                for (let role of selectedItems) {
                  let { id = '' } = role;
                  roles.push(id);
                }
                setSidePanelDetails({
                  ...sidePanelDetails,
                  role: roles.join(','),
                });
              }}
              selectedItems={userRole}
            />
            {errorMessage.includes('role') && (
              <div className="error-message">Please select valid user role</div>
            )}
          </Form>
        </SidePanel>
      </SidePanelContainer>
    </Content>
  );
};

export default UserContent;
