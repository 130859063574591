import React, { useState, useCallback, useEffect } from 'react';
import { PaginationNav, InlineLoading } from '@carbon/react';
import './MappingPage.scss';
import { Toggle } from '@console/pal/carbon-components-react';
import { InlineNotification } from '@carbon/react';
import { getRules, getRulesData } from '../../api/MappingPage';

const RulesTabDetails = ({
  READ_ONLY,
  updatedRules,
  showUpdateMessage,
  selectedMappingDetails,
  setUpdatedRules,
  setUpdateMessage,
  mid,
  selectedTab,
  selectedControls,
  accountId,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rulesData, setRulesData] = useState([]);
  const [rulesLoader, setRulesLoader] = useState(false);

  const getRulesDetails = useCallback(
    controlId => {
      let queryObj = {
        account_id: accountId,
      };
      setRulesLoader(true);
      const queryParam = new URLSearchParams(queryObj).toString();
      getRulesData(mid, controlId, queryParam)
        .then(result => {
          let { rules = [] } = result.data || {};
          console.log('Rules ', rules);
          setRulesData(rules);
          setRulesLoader(false);
        })
        .catch(err => {
          console.log(err);
          setRulesLoader(false);
        });
    },
    [mid, accountId]
  );

  useEffect(() => {
    if (selectedTab === 2 && mid && selectedControls?.length) {
      let controls = [];
      let filteredControls = selectedControls.filter(
        x => x.id === selectedMappingDetails?.id
      );
      for (let list of filteredControls) {
        controls.push(list?.control_id);
      }
      let data = { controls: controls };
      getRules(mid, data)
        .then(result => {
          return result?.data;
        })
        .then(json => {
          let { rules = [] } = json || {};
          console.log('Rules 2 ', rules);
          setRulesData(rules);
        })
        .catch(err => {
          console.log('Error while updating rules ', err);
        });
    } else if (selectedTab === 2 && mid && selectedMappingDetails?.id) {
      getRulesDetails(selectedMappingDetails?.id);
    }
  }, [
    selectedTab,
    mid,
    selectedControls,
    selectedMappingDetails?.id,
    getRulesDetails,
  ]);

  const displayRules = useCallback(
    rules => {
      let controlIds = [...updatedRules];
      return (
        <div>
          {rules
            .slice(10 * currentPage, 10 * (currentPage + 1))
            .map((rule, id) => {
              let isSuppressed =
                controlIds.findIndex(
                  x =>
                    x.control_id === rule.control_id &&
                    x.recommendation_id === selectedMappingDetails.id &&
                    x.is_suppress === false
                ) > -1
                  ? true
                  : controlIds.findIndex(
                      x =>
                        x.control_id === rule.control_id &&
                        x.recommendation_id === selectedMappingDetails.id &&
                        x.is_suppress === true
                    ) > -1
                  ? false
                  : rule.is_rule_required;
              return (
                <div
                  key={id}
                  className="backgroundWhite top-margin card-margin-r rule-details">
                  {controlIds.findIndex(
                    x =>
                      x.control_id === rule.control_id &&
                      x.recommendation_id === selectedMappingDetails.id &&
                      x.is_suppress === true
                  ) > -1 && (
                    <InlineNotification
                      style={{ marginBottom: '15px' }}
                      lowContrast
                      timeout={1000}
                      hideCloseButton={true}
                      kind="warning"
                      title="You are opting to exclude this rule from the Control library and profile."
                    />
                  )}
                  <div
                    style={{
                      borderBottom: '1px solid #ddd',
                      paddingBottom: '8px',
                    }}>
                    <div
                      style={{
                        display: 'inline-block',
                        width: '90%',
                        fontWeight: '600',
                      }}>
                      {rule.rule_id}
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginTop: '-16px',
                      }}>
                      <Toggle
                        toggled={isSuppressed}
                        disabled={READ_ONLY}
                        id={rule.control_id}
                        onToggle={(e, event) => {
                          setUpdateMessage('');
                          let index = controlIds.findIndex(
                            x =>
                              x.control_id === event &&
                              x.recommendation_id === selectedMappingDetails.id
                          );

                          if (index > -1) {
                            controlIds.splice(index, 1);
                          }
                          controlIds.push({
                            control_id: event,
                            is_suppress: !e,
                            recommendation_id: selectedMappingDetails.id,
                          });

                          setUpdatedRules(controlIds);
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="div-left">Description</div>
                    <div className="div-right">{rule.rule_description}</div>
                  </div>
                  <div>
                    <div className="div-left">FS Mapping</div>
                    <div className="div-right">{rule.controls.join(', ')}</div>
                  </div>
                  {rule.properties?.component_name && (
                    <div>
                      <div className="div-left">Component name</div>
                      <div className="div-right">
                        {rule.properties?.component_name}
                      </div>
                    </div>
                  )}
                  {rule.parameters &&
                    rule.parameters.length > 0 &&
                    rule.parameters.map((parameter, index) => (
                      <div key={index}>
                        <div>
                          <div className="div-left">Parameter name</div>
                          <div className="div-right">
                            {parameter.parameter_display_name}
                          </div>
                        </div>
                        <div>
                          <div className="div-left">Parameter type</div>
                          <div className="div-right">
                            {parameter.parameter_type}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              );
            })}
          <PaginationNav
            totalItems={Math.ceil(rules.length / 10)}
            className="mapping-pagination"
            onChange={index => {
              setCurrentPage(index);
            }}
            page={currentPage}
            itemsShown={4}
          />
        </div>
      );
    },
    [
      currentPage,
      updatedRules,
      READ_ONLY,
      selectedMappingDetails,
      setUpdateMessage,
      setUpdatedRules,
    ]
  );

  return (
    <div>
      {rulesLoader ? (
        <InlineLoading
          status="active"
          iconDescription="Loading"
          description="Loading rules..."
        />
      ) : (
        <>
          {rulesData && rulesData.length > 0 ? (
            <div className="rules-tab">
              {showUpdateMessage && (
                <InlineNotification
                  style={{ marginBottom: '15px' }}
                  lowContrast
                  timeout={3000}
                  kind={
                    showUpdateMessage === 'Successfully updated the Rules!'
                      ? 'success'
                      : 'warning'
                  }
                  title={showUpdateMessage}
                />
              )}

              {displayRules(rulesData)}
            </div>
          ) : (
            <div className="rules-tab">
              <div className="backgroundWhite top-margin card-margin-r rule-details">
                Rule data is not available. Please select controls from the
                recommendations tab to view associated rules.
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RulesTabDetails;
