import axios from '../helpers/apiHelper';
import { BASE_URL } from '../helpers/urls';

export const importMapping = async (formData, account_id) => {
  return await axios.post(`${BASE_URL}/resource/import/`, formData, {
    params: {
      account_id,
    },
  });
};
