import React, { useState } from 'react';
import { UserAvatarFilled, Send } from '@carbon/react/icons';
import { Button, TextArea } from '@carbon/react';
import { addComment } from '../../api/MappingPage';
import { getEmailID } from '../../api/common';
import { getHost } from '../../helpers/urls';
import moment from 'moment';

const Comments = props => {
  let {
    comments = [],
    id = '',
    READ_ONLY = false,
    setSidePanel,
    setSelectedId,
    setPanelType,
    refreshData,
    i,
    type = '',
    accountId = '',
    mid = '',
  } = props;
  console.log("Comments ", comments, typeof comments);
  const [commentColumnText, setCommentColumnText] = useState('');

  const addMappingComment = formData => {
    addComment(formData, accountId)
      .then(result => {
        if (setSidePanel) setSidePanel(false);
        setCommentColumnText('');
        refreshData();
      })
      .catch(error => {
        console.log('error ', error);
      });
  };

  return (
    <div>
      <div className="space--between y--content">
        <div style={{ marginTop: 20, position: 'relative' }}>
          <TextArea
            rows={2}
            className="add-comment-box"
            key={`key_comment`}
            id={`key_comments_id`}
            type="text"
            placeholder="Enter your comments"
            value={commentColumnText}
            onChange={e => {
              setCommentColumnText(e.target.value);
            }}
            disabled={READ_ONLY}
          />
          <Send
            size={18}
            className="add-comment-icon"
            style={{ color: '#0F61FE' }}
            onClick={() => {
              let formData = {
                comment_text: commentColumnText,
                control_id: id,
                mapping_request_id: mid,
              };
              if (!READ_ONLY) addMappingComment(formData);
            }}
          />
        </div>
      </div>
      {comments && type === 'table-component' && comments?.length > 0 && (
        <div className="comment-container">
          <div className="user-container">
            <UserAvatarFilled size={32} />
          </div>
          <div>
            <div className="comment-title">{comments[0]?.publisher}</div>
            <div className="comment-description">{comments[0]?.comment}</div>
            <div className="comment-time">
              {moment
                .utc(comments[0]?.created_at)
                .local()
                .fromNow()}
            </div>
          </div>
        </div>
      )}
      {comments && type === 'table-component' && comments?.length > 1 && (
        <div className="flex space--between y--content">
          <Button
            kind="ghost"
            onClick={() => {
              setSelectedId(i);
              if (setSidePanel) setSidePanel(true);
              setPanelType('comments');
              window.analytics.track('Ran Process', {
                'user.email': getEmailID(),
                'user.bluemixId': getEmailID(),
                productTitle: 'Crosswalks Tool',
                name: 'Show all comments',
                object: {},
                processType: 'Click',
                process: 'Event Detected',
                successFlag: true,
                resultValue:
                  'Show all comments clicked from Mapping details page',
                milestoneName:
                  'Show all comments clicked from Mapping details page',
                url: getHost(),
              });
            }}
            className="more-button">
            Show all comments
          </Button>
        </div>
      )}

      {comments && comments?.length > 0 &&
        type === 'side-panel' &&
        comments.map(comment => (
          <div className="comment-container">
            <div className="user-container">
              <UserAvatarFilled size={32} />
            </div>
            <div>
              <div className="comment-title">
                {comment.publisher}
                <span className="side-comment-time">
                  {moment
                    .utc(comment.created_at)
                    .local()
                    .fromNow()}
                </span>
              </div>
              <div className="comment-description">{comment.comment}</div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Comments;
