import { AUTH_HOST_URL } from './urls';
import { getHost } from '../helpers/urls';

const axiosInterceptor = require('axios');
export const axiosWithoutToken = axiosInterceptor.create();
export const axios = axiosInterceptor.create({
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

const RETRY_CONST = 10;
var _retry_count = 0;

async function refreshTokens() {
  let response = await axiosWithoutToken.post(
    AUTH_HOST_URL + `/api/refresh-token`,
    { withCredentials: true }
  );
  response = response.data;
  if (response && response.accessToken) {
    localStorage.setItem('access_token', response.accessToken);
    localStorage.setItem('refresh_token', response.refreshToken);
    return response.accessToken;
  } else {
    return localStorage.getItem('access_token');
  }
}

export async function accessToken() {
  let response = await axiosWithoutToken.get(`${AUTH_HOST_URL}/api/user`, {
    withCredentials: true,
  });
  response = response.data;
  if (response && response.accessToken) {
    localStorage.setItem('access_token', response.accessToken);
    localStorage.setItem('refresh_token', response.refreshToken);
    localStorage.setItem('username', response.user.name);
    localStorage.setItem('_retry_count', 0);
    console.log('Service login ', response);
    let emailId = sessionStorage.getItem('emailId');
    let name = sessionStorage.getItem('name');
    window.analytics.track('Service Login', {
      productTitle: 'Crosswalks Tool',
      'user.bluemixId': response.email.id || emailId,
      'user.email': response.email.id || emailId,
      'user.name': response.user.name || name,
      'user.firstSeen': '',
      'user.role': '',
      'user.tenantId': '',
      'meta.successFlag': true,
      url: getHost(),
      name: 'Service Login',
    });
    return response.accessToken;
  } else {
    return localStorage.getItem('access_token');
  }
}

axios.interceptors.request.use(
  async function(config) {
    config.headers = config.headers || {};
    var access_token = localStorage.getItem('access_token');
    if (access_token) {
      config.headers.Authorization = 'Bearer ' + access_token;
    } else {
      const access_token = await accessToken();
      config.headers.Authorization = 'Bearer ' + access_token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    _retry_count = 0;
    localStorage.setItem('_retry_count', _retry_count);
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    console.log('error ', error);

    if (error.response.status === 401) {
      _retry_count = Number.parseInt(localStorage.getItem('_retry_count'));
      if (_retry_count > RETRY_CONST) {
        localStorage.clear();
        return Promise.reject(error);
      }
      _retry_count++;
      localStorage.setItem('_retry_count', _retry_count);
      originalRequest._retry = true;
      const access_token = await refreshTokens();
      originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axios;
