import React from 'react';
import { Modal } from '@carbon/react';
import './MappingPage.scss';

const ReviewerModal = ({
  role,
  confirmModal,
  setConfirmModal,
  mDetails,
  accountDetails,
  assignUsers,
  unReviewedControls,
}) => {
  return (
    <Modal
      open={confirmModal}
      modalHeading={`Please review all controls before ${
        role === 'COMPLETED' || role === 'APPROVER'
          ? 'approving'
          : 'sending it to approver'
      }.`}
      onRequestClose={() => setConfirmModal(false)}
      onRequestSubmit={() => {
        let data = {
          action_status:
            mDetails.mapping_request_action_status === 'PENDING_APPROVAL' ||
            (mDetails.approver &&
              mDetails.approver.includes(accountDetails.default_acc_name) &&
              mDetails.reviewer &&
              mDetails.reviewer.includes(accountDetails.default_acc_name))
              ? 'COMPLETED'
              : 'PENDING_APPROVAL',
          account_id: accountDetails.acc_id,
        };
        assignUsers(data);
      }}
      primaryButtonText="Proceed"
      secondaryButtonText="Cancel">
      <div style={{ marginBottom: '15px' }}>
        {role === 'COMPLETED' || role === 'APPROVER'
          ? 'The following controls are pending approval. To approve them, please review and select the checkbox next to each control in the list view.'
          : 'Below controls are not yet reviewed. You can go ahead and proceed'}
      </div>
      <p>
        Controls:{' '}
        {unReviewedControls &&
          unReviewedControls.length > 0 &&
          unReviewedControls.map((x, i) => {
            return (
              <span>
                {x.source_control_id}
                {unReviewedControls.length - 1 !== i ? ', ' : ''}
              </span>
            );
          })}
      </p>
      {(role === 'COMPLETED' || role === 'APPROVER') && (
        <p style={{ marginTop: '15px' }}>
          By clicking "proceed", all these controls will be automatically
          approved.
        </p>
      )}
      <div />
    </Modal>
  );
};

export default ReviewerModal;
