export const configNavItems = [
  { id: 'create', title: 'Create', link: '/create' },
  {
    id: 'export-v1',
    title: 'Export profile (v1)',
    link: '/crosswalk/export-profile',
  },
  {
    id: 'export-v2',
    title: 'Export control library (v2)',
    link: '/crosswalk/export-custom-control-library',
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    link: '/crosswalk/dashboard',
  },
];

export const focusAreaCategories = [
  'ACTIVE_MONITORING_AND_RESPONSE',
  'ADVANCED_DATA_PROTECTION',
  'AUTOMATED_APPLICATION_WORKLOAD_PROTECTION',
  'ENHANCED_AUTHENTICATION_AND_ACCESS_MANAGEMENT',
  'FOCUSED_RISK_MANAGEMENT_AND_COMPLIANCE',
  'OPERATIONAL_EXCELLENCE',
  'UNIFIED_INFRASTRUCTURE_SECURITY_AND_RESILIENCE',
  'OTHERS',
];

export const relationStatusCategories = [
  'DEFAULT',
  'MET',
  'CUSTOMER_RESPONSIBILITY',
  'PARTIAL',
  'NEEDS_FURTHER_DISCUSSION',
  'NOT_APPLICABLE',
  'CONTRACT',
  'NO_DATA',
];
