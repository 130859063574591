import React from 'react';
import {
  ExpandableTile,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent,
} from '@console/pal/carbon-components-react';

const COMPONENT_NAME = 'quickstart';

const Quickstart = () => {
  return (
    <ExpandableTile
      light
      tileCollapsedIconText="Interact to Expand tile"
      tileExpandedIconText="Interact to Collapse tile"
      tileExpandedLabel="Show less"
      tileCollapsedLabel="Show more"
      tileMaxHeight={0}
      tilePadding={10}
      key="mapping-generation"
      className={`${COMPONENT_NAME}-tile`}>
      <TileAboveTheFoldContent>
        <div style={{ height: '50px', fontWeight: 'bold' }}>Quick start</div>
      </TileAboveTheFoldContent>
      <TileBelowTheFoldContent>
        <div
          style={{
            height: '130px',
            fontFamily: 'IBM Plex Serif',
          }}>
          <p style={{ fontSize: '14px', marginBottom: '5px' }}>
            - Crosswalk is an AI driven platform that provides mapping from one
            control framework to another.
          </p>
          <p style={{ fontSize: '14px', marginBottom: '5px' }}>
            - Crosswalk RBAC supports Requestor, Reviewer and Approver roles and
            it allows a user to have multiple roles in the system.
          </p>
          <p style={{ fontSize: '14px' }}>
            - Mapping workflow starts with AI recommendation and augments with
            human input to get final mapping.
          </p>
        </div>
      </TileBelowTheFoldContent>
    </ExpandableTile>
  );
};

export default Quickstart;
