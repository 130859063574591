import React, { useState, useEffect } from 'react';
import {
  Header,
  HeaderName,
  HeaderNavigation,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  Switcher,
  SwitcherItem,
  SwitcherDivider,
  Dropdown,
  Theme,
} from '@carbon/react';
import { Search, Notification } from '@carbon/icons-react';
import { UserAvatarFilled, ArrowRight } from '@carbon/react/icons';
import { deleteAllCookies } from '../../api/common';
import './HeaderComponent.scss';
import { getHost } from '../../helpers/urls';

const HeaderComponent = props => {
  const [showPanel, setPanel] = useState(false);
  const [account, setAccount] = useState({});

  useEffect(() => {
    setAccount(props.accountDetails);
  }, [props.accountDetails]);

  const logoutUser = () => {
    let emailId = sessionStorage.getItem('emailId');
    window.analytics.track('Service Logout', {
      productTitle: 'Crosswalks Tool',
      'user.bluemixId': emailId,
      'user.email': emailId,
      'user.firstSeen': '',
      'user.role': '',
      'user.tenantId': '',
      'meta.successFlag': true,
      url: getHost(),
      name: 'Service Logout',
    });
    localStorage.clear();
    sessionStorage.clear();
    deleteAllCookies();
    window.location.assign(
      '/api/appid/logout'
    );
  };

  const action = () => {
    setPanel(!showPanel);
  };

  return (
    <Header aria-label="IBM Platform Name">
      <HeaderName href="#" prefix="">
        IBM Cloud
      </HeaderName>

      <HeaderGlobalBar>
        <HeaderNavigation aria-label="IBM Cloud">
          <Theme theme="g100">
            {props.userData && props.userData['accounts'] && (
              <Dropdown
                style={{ marginTop: '4px', minWidth: '200px' }}
                id="default"
                titleText=""
                label=""
                selectedItem={account}
                onChange={e => {
                  let { selectedItem = {} } = e;
                  setAccount(selectedItem);
                  sessionStorage.setItem(
                    'active_account_id',
                    selectedItem.acc_id
                  );

                  props.setAccountDetails &&
                    props.setAccountDetails(selectedItem);
                }}
                items={props.userData['accounts']}
                itemToString={item => (item ? item.acc_name : '')}
              />
            )}
          </Theme>
        </HeaderNavigation>
        <HeaderGlobalAction aria-label="Search" onClick={() => {}}>
          <Search size={20} style={{ fill: '#fff' }} />
        </HeaderGlobalAction>
        <HeaderGlobalAction aria-label="Notification" onClick={() => {}}>
          <Notification size={20} style={{ fill: '#fff' }} />
        </HeaderGlobalAction>
        <HeaderGlobalAction
          aria-label="User account"
          onClick={() => action()}
          tooltipAlignment="end">
          <UserAvatarFilled size={20} style={{ fill: '#fff' }} />
        </HeaderGlobalAction>
      </HeaderGlobalBar>
      <HeaderPanel
        direction="top"
        aria-label="Header Panel"
        expanded={showPanel}
        className="crosswalk-user-account-details">
        <Switcher aria-label="Switcher Container">
          <SwitcherItem isSelected aria-label="User account" href="#">
            {props.userData['name']}
          </SwitcherItem>
          <SwitcherDivider />
          <SwitcherItem href="#" aria-label="Log out" onClick={logoutUser}>
            Log out <ArrowRight style={{ verticalAlign: 'middle' }} />
          </SwitcherItem>
          <SwitcherItem />
        </Switcher>
      </HeaderPanel>
    </Header>
  );
};

export default HeaderComponent;
