import React, { useState, useEffect } from 'react';
import './app.scss';
import HeaderComponent from './Components/Header';
import LandingPage from './Components/LandingPage';
import MappingPage from './Components/MappingPage';
import LoginPage from './Components/LoginPage';
import Create from './Components/Create';
import UserInvitation from './Components/UserInvitation';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ExportProfile from './Components/ExportProfile';
import './app.scss';
import Dashboard from './Components/Dashboard';
import FSCloudDashboard from './Components/FSCloudDashboard';
import { getUserAccount } from './api/common';
import ImportMapping from './Components/ImportMapping';
import ExportCustomControlLibrary from './Components/ExportCustomControlLibrary';
import Crossmap from './Components/Crossmap';

const App = () => {
  const [defaultAccount, setDefaultAccount] = useState({});
  const [userData, setUserData] = useState([]);

  const setAccountDetails = defaultAccount => {
    let username = sessionStorage.getItem('name') || '';
    let default_account_id = sessionStorage.getItem('default_account_id') || '';
    defaultAccount = {
      ...defaultAccount,
      default_acc_name: username,
      default_acc_id: default_account_id,
    };
    setDefaultAccount(defaultAccount);
  };

  useEffect(() => {
    getUserAccount()
      .then(result => {
        let { data = {} } = result || {};
        let {
          default_account_id = '',
          accounts = [],
          name = '',
          email = '',
        } = data;
        let defaultAccount = accounts.find(
          x => x.acc_id === default_account_id
        );
        let userSelectedAccount = sessionStorage.getItem('active_account_id');
        if (userSelectedAccount) {
          defaultAccount = accounts.find(x => x.acc_id === userSelectedAccount);
        } else {
          sessionStorage.setItem('active_account_id', default_account_id);
          sessionStorage.setItem('default_account_id', default_account_id);
          sessionStorage.setItem('name', name);
          sessionStorage.setItem('emailId', email);
        }
        setUserData(data);
        defaultAccount = {
          ...defaultAccount,
          default_acc_name: name,
          default_acc_id: default_account_id,
        };
        setDefaultAccount(defaultAccount);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Router>
      <HeaderComponent
        setAccountDetails={setAccountDetails}
        accountDetails={defaultAccount}
        userData={userData}
      />
      <Switch>
        <Route exact path="/landingpage">
          <LandingPage accountDetails={defaultAccount} />
        </Route>
        <Route exact path="/crosswalk/mapping-details/:type/:mid">
          <MappingPage accountDetails={defaultAccount} />
        </Route>
        <Route exact path="/userInvitations">
          <UserInvitation accountDetails={defaultAccount} />
        </Route>
        <Route exact path="/create">
          <Create accountDetails={defaultAccount} />
        </Route>
        <Route exact path="/crosswalk/export-profile/:type/:mid">
          <ExportProfile />
        </Route>
        <Route exact path="/crossmap">
          <Crossmap accountDetails={defaultAccount} />
        </Route>
        <Route exact path="/crosswalk/dashboard/:type/:mid">
          <Dashboard accountDetails={defaultAccount} />
        </Route>
        <Route exact path="/crosswalk/fs-dashboard/:type/:mid">
          <FSCloudDashboard accountDetails={defaultAccount} />
        </Route>
        <Route exact path="/import-mapping">
          <ImportMapping accountDetails={defaultAccount} />
        </Route>
        <Route exact path="/crosswalk/export-custom-control-library/:type/:mid">
          <ExportCustomControlLibrary />
        </Route>
        <Route path="/">
          <LoginPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
