import React, { useState } from 'react';
import {
  Button,
  Tooltip,
  Checkbox,
  TextInput,
  InlineLoading,
} from '@carbon/react';
import './MappingPage.scss';
import { Tag, EnhancedDataTable } from '@console/pal/Components';
import { searchMapping, addControlRecommendation } from '../../api/MappingPage';
import { getEmailID } from '../../api/common';
import { Information } from '@carbon/icons-react';
import { getHost } from '../../helpers/urls';
import { InlineNotification } from '@carbon/react';

const COMPONENT_NAME = 'mapping-generation-details';

const RecommendationTabDetails = ({
  READ_ONLY,
  loader,
  controlRecommendations,
  mid,
  accountId,
  mDetails,
  selectedRecommendations,
  rejectedRecommendations,
  selectedIndex,
  mappingDetails,
  refreshData,
  openInfoSlider,
  setRejectedRecommendations,
  setSelectedRecommendations,
  setMappingPayload,
  mappingPayload,
  selectedControls,
  setSelectedControls,
}) => {
  const controlsHeader = [
    {
      key: 'name',
      header: 'Name',
    },
    {
      key: 'description',
      header: 'Description',
    },
  ];

  const [controlsData, setControlsData] = useState([]);
  const [controlsLoading, setControlsLoading] = useState(false);
  const [mappingText, setMappingText] = useState('');

  const addMappingRecommendation = () => {
    setControlsLoading(true);
    window.analytics.track('Started Process', {
      'user.email': getEmailID(),
      'user.bluemixId': getEmailID(),
      productTitle: 'Crosswalks Tool',
      name: 'Search Mapping',
      processType: 'Search Mapping Controls',
      process: 'Search',
      resultValue: 'Running search mapping controls',
      successFlag: true,
      category: mid,
      object: {
        searchText: mappingText,
        target_profile_id: mDetails.target_profile_id,
      },
      milestoneName: 'Running search mapping controls',
      url: getHost(),
    });
    searchMapping(mappingText, mDetails.target_profile_id)
      .then(result => {
        let { controls = [] } = result.data || {};
        setControlsData(controls);
        setControlsLoading(false);
        window.analytics.track('Ended Process', {
          'user.email': getEmailID(),
          'user.bluemixId': getEmailID(),
          productTitle: 'Crosswalks Tool',
          name: 'Search Mapping',
          processType: 'Search Mapping Controls',
          process: 'Search',
          resultValue: 'Completed search mapping controls',
          successFlag: true,
          category: mid,
          object: result.data,
          milestoneName: 'Completed search mapping controls',
          url: getHost(),
        });
      })
      .catch(err => setControlsLoading(false));
  };

  const newControlsActions = () => {
    return [
      {
        kind: 'primary',
        label: 'Add',
        onClick: function onClick(e) {
          let targetControls = [];
          for (let control of e) {
            let { name = '' } = control;
            targetControls.push(name);
          }
          let payload = {
            mapping_request_id: mid,
            account_id: accountId,
            source_control_id: mappingDetails[selectedIndex].id,
            target_control_name: targetControls,
          };
          window.analytics.track('Started Process', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: 'Add new controls',
            processType: 'Add new controls',
            process: 'Add',
            resultValue: 'Running add new controls',
            successFlag: true,
            category: mid,
            object: payload,
            milestoneName: 'Running add new controls',
            url: getHost(),
          });
          addControlRecommendation(payload)
            .then(res => {
              refreshData();
              window.analytics.track('Ended Process', {
                'user.email': getEmailID(),
                'user.bluemixId': getEmailID(),
                productTitle: 'Crosswalks Tool',
                name: 'Add new controls',
                processType: 'Add new controls',
                process: 'Add',
                resultValue: 'Completed running add new controls',
                successFlag: true,
                category: mid,
                object: payload,
                milestoneName: 'Completed running add new controls',
                url: getHost(),
              });
            })
            .catch(err => {
              console.log(err);
            });
        },
      },
    ];
  };

  return (
    <div>
      {loader ? (
        <InlineLoading
          status="active"
          iconDescription="Loading"
          description="Loading recommendations data..."
        />
      ) : (
        <div>
          {controlRecommendations &&
            controlRecommendations.length > 0 &&
            controlRecommendations.map((item, detailTextIndex) => {
              let targetControlDesc = item.target_control_desc.replace(
                /\\n/g,
                ''
              );
              return (
                <div
                  className="backgroundWhite top-margin"
                  key={`key_${detailTextIndex}`}
                  style={{
                    marginBottom:
                      detailTextIndex === controlRecommendations.length - 1
                        ? '2rem'
                        : null,
                  }}>
                  {rejectedRecommendations?.includes(item.id) && (
                    <InlineNotification
                      lowContrast
                      hideCloseButton={true}
                      kind="warning"
                      title="Please note that deselecting this control will result in the removal of associated rules from the rules tab."
                    />
                  )}
                  <div className="flex space-between">
                    <div
                      style={{
                        padding: '20px',
                      }}>
                      <Checkbox
                        labelText=""
                        defaultChecked={
                          item['action_status'] === 'ACCEPTED' ||
                          item['action_status'] === 'ADDED' ||
                          selectedRecommendations.includes(item.id) ||
                          false
                        }
                        disabled={READ_ONLY}
                        key={`checkbox_id_${selectedIndex}_${detailTextIndex}_${
                          item.control_id
                        }`}
                        id={`checkbox_id_${selectedIndex}_${detailTextIndex}_${
                          item.control_id
                        }`}
                        onChange={e => {
                          let recommendations = [...selectedRecommendations];
                          let rejected = [...rejectedRecommendations];
                          let controls = [...selectedControls];
                          let obj = {};
                          if (e.target.checked) {
                            obj = {
                              control_id: mappingDetails[selectedIndex].id,
                              status: 'ACCEPTED',
                              mapping_recommendation_id: item.id,
                            };
                            recommendations.push(item.id);
                            controls.push({
                              id: mappingDetails[selectedIndex].id,
                              control_id: item.control_id,
                            });
                            let i = rejected.findIndex(x => x === item.id);
                            if (i >= 0) rejected.splice(i, 1);
                          } else {
                            obj = {
                              control_id: mappingDetails[selectedIndex].id,
                              status: 'REJECTED',
                              mapping_recommendation_id: item.id,
                            };
                            let i = recommendations.findIndex(
                              x => x === item.id
                            );
                            if (i >= 0) recommendations.splice(i, 1);
                            let j = controls.findIndex(
                              x => x.control_id === item.control_id
                            );
                            if (j >= 0) controls.splice(j, 1);
                            rejected.push(item.id);
                            setRejectedRecommendations();
                          }
                          setSelectedRecommendations(recommendations);
                          setSelectedControls(controls);
                          setRejectedRecommendations(rejected);

                          let payload = [...mappingPayload];
                          const index = payload.findIndex(x => {
                            return (
                              x.control_id ===
                                mappingDetails[selectedIndex].id &&
                              x.mapping_recommendation_id === item.id
                            );
                          });
                          if (index !== -1) {
                            payload.splice(index, 1);
                          }
                          payload.push(obj);
                          setMappingPayload(payload);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: '95%',
                        paddingRight: '10px',
                      }}>
                      <h3 className="cm2-configuration card-t-padding">
                        {item.target_control_id}{' '}
                        {item.score && (
                          <Tag
                            type={item.score > 0.5 ? 'green' : 'red'}
                            size="sm"
                            title="Score">
                            {(item.score * 100)
                              .toFixed(2)
                              .replace(/[.,]00$/, '')}
                            %
                          </Tag>
                        )}
                        <div
                          style={{
                            display: 'inline-block',
                            float: 'right',
                            marginRight: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={() => openInfoSlider(item.control_id)}>
                          <Information
                            size={20}
                            style={{
                              fill: '#333',
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                      </h3>
                      <div className="cm2-configuration top-margin">
                        {item.target_control_title && (
                          <p
                            style={{
                              marginBottom: '5px',
                              whiteSpace: 'pre-wrap',
                            }}>
                            {item.target_control_title}
                          </p>
                        )}
                        {item.target_control_title !==
                          item.target_control_desc && (
                          <p
                            style={{ whiteSpace: 'pre-wrap' }}
                            dangerouslySetInnerHTML={{
                              __html: targetControlDesc,
                            }}
                          />
                        )}
                      </div>
                      <div className="top-margin cm2-configuration flex flex-direction space--between bottom-pad2">
                        <div id="goalsTagId">
                          {item.goals && item.goals.length > 0 && (
                            <p className="font--weight">Rule IDs:</p>
                          )}

                          {item.goals &&
                            item.goals.length > 0 &&
                            item.goals.map((goalItem, i) => (
                              <Tooltip
                                key={i}
                                align="bottom"
                                label={goalItem.description}>
                                <Button kind={'ghost'} size="sm">
                                  <Tag
                                    className="sb-tooltip-trigger"
                                    size="sm"
                                    title="Clear Filter"
                                    type="cool-gray">
                                    {goalItem.name}
                                  </Tag>
                                </Button>
                              </Tooltip>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {mDetails.status !== 'APPROVED' && (
            <div
              className="backgroundWhite top-margin card-padding-r"
              style={{
                marginBottom: '6rem',
                marginRight: '2rem',
              }}>
              <div
                className="flex  card-t-padding flex-direction"
                style={{
                  margin: '25px',
                  paddingBottom: '25px',
                }}>
                <h3 style={{ marginBottom: '10px' }}>Add new control</h3>
                <div
                  id="button-nist-4"
                  style={{ paddingBottom: '20px' }}
                  className="top-bottom-margin">
                  <TextInput
                    labelText={
                      mDetails.target_profile_name || 'NIST-4 Controls'
                    }
                    id="nist-4"
                    value={mappingText}
                    onChange={e => {
                      setMappingText(e.target.value);
                    }}
                  />
                </div>
                <Button
                  className="align-self"
                  disabled={mappingText.length === 0}
                  onClick={() => addMappingRecommendation()}>
                  Search
                </Button>
              </div>

              {mappingText.length > 0 &&
                (controlsLoading || controlsData.length > 0) && (
                  <EnhancedDataTable
                    size="md"
                    id="table-with-actions"
                    batchActions={newControlsActions}
                    className={`${COMPONENT_NAME}__simple_datatable`}
                    loading={controlsLoading}
                    rows={controlsData}
                    headers={controlsHeader}
                  />
                )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RecommendationTabDetails;
