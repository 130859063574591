import React, { useState, useEffect, useRef, useCallback } from 'react';
import { EnhancedDataTable } from '@console/pal/Components';
import { Card, CardHeader, CardBody } from '@console/pal/Components';
import { Loading } from '@carbon/react';
import { Row, Column } from '@console/pal/carbon-components-react';
import CustomBreadcrumb from '../common/Breadcrumb';
import { useParams } from 'react-router-dom';
import {
  getDashboardCategory,
  getDashboardControls,
} from '../../api/Dashboard';
import './dashboard.scss';
import {
  SimpleBarChart,
  DonutChart,
  StackedBarChart,
} from '@carbon/charts-react';
import startCase from 'lodash/startCase';
import { getEmailID } from '../../api/common';
import '@carbon/charts/styles.css';
import { getHost } from '../../helpers/urls';

const COMPONENT_NAME = 'dashboard';

const donutOptions = {
  resizable: true,
  tooltip: {
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 50,
    },
  },
  legend: {
    alignment: 'center',
    truncation: {
      type: 'mid_line',
      threshold: 25,
      numCharacter: 20,
    },
  },
  donut: {
    center: {
      label: 'Controls',
    },
    alignment: 'center',
  },
  color: {
    scale: {
      Met: '#389e6a',
      Partial: 'gold',
      'Bank Responsibility': '#dc143c',
      'Customer Responsibility': '#dc143c',
      Default: 'cornflowerblue',
      'Needs further discussion': 'orange',
      Contract: 'lightcoral',
      'No Data': 'grey',
    },
  },
  height: '400px',
};

const barOptions = {
  tooltip: {
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 50,
    },
  },
  axes: {
    left: {
      mapsTo: 'group',
      scaleType: 'labels',
      truncation: {
        type: 'mid_line',
        threshold: 50,
        numCharacter: 45,
      },
    },
    bottom: {
      mapsTo: 'value',
    },
  },
  color: {
    scale: {
      Met: '#389e6a',
      Partial: 'gold',
      'Bank Responsibility': '#dc143c',
      'Customer Responsibility': '#dc143c',
      Default: 'cornflowerblue',
      'Needs further discussion': 'orange',
      Contract: 'lightcoral',
      'No Data': 'grey',
    },
  },
  height: '400px',
};

const stackOptions = {
  tooltip: {
    showTotal: true,
    totalLabel: 'Total',
    truncation: {
      type: 'mid_line',
      threshold: 50,
      numCharacter: 50,
    },
  },
  axes: {
    left: {
      scaleType: 'labels',
      truncation: {
        type: 'mid_line',
        threshold: 50,
        numCharacter: 45,
      },
    },
    bottom: {
      stacked: true,
    },
  },
  color: {
    scale: {
      Met: '#389e6a',
      Partial: 'gold',
      'Bank Responsibility': '#dc143c',
      'Customer Responsibility': '#dc143c',
      Default: 'cornflowerblue',
      'Needs further discussion': 'orange',
      Contract: 'lightcoral',
      'No Data': 'grey',
    },
  },
  height: '400px',
};

const headers = [
  {
    key: 'name',
    header: 'Control Id',
  },
  {
    key: 'title',
    header: 'Control Name',
  },
  {
    key: 'description',
    header: 'Control Description',
  },
  {
    key: 'domain',
    header: 'Control Domain',
  },
];

const initialCols = [
  'name',
  'title',
  'controlObjective',
  'description',
  'domain',
  'fsCloudFramework',
  'comments',
];

const Dashboard = () => {
  const { mid = '', type = '' } = useParams();
  const [rows, setRows] = useState([]);
  const [barData, setBarData] = useState([]);
  const [donutData, setDonutData] = useState([]);
  const [stackData, setStackedData] = useState([]);
  const [controlDetails, setControlDetails] = useState({});
  const [clicked, setClicked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showPageLoader, setPageLoader] = useState(true);

  const chartRef = useRef(null);
  const donutChartRef = useRef(null);
  const barChartRef = useRef(null);

  const chartOnClick = useCallback(
    ({ detail }) => {
      setRows([]);
      setLoader(true);
      let queryParams = { target: false };
      setClicked(true);
      if (detail.datum.data?.sharedStackKey) {
        queryParams = {
          ...queryParams,
          category: detail.datum.data.sharedStackKey
            .replaceAll(/ /g, '_')
            .toUpperCase(),
          relation_status: detail.datum.group
            .replaceAll(/ /g, '_')
            .toUpperCase(),
        };
      } else if (detail.datum?.group) {
        queryParams = {
          ...queryParams,
          relation_status: detail.datum.group
            .replaceAll(/ /g, '_')
            .toUpperCase(),
        };
      } else if (detail.datum?.data?.group) {
        queryParams = {
          ...queryParams,
          relation_status: detail.datum.data.group
            .replaceAll(/ /g, '_')
            .toUpperCase(),
        };
      }
      if (mid && mid !== 'overview') {
        queryParams = { ...queryParams, mapping_request_id: mid };
      }
      window.analytics.track('Read Object', {
        'user.email': getEmailID(),
        'user.bluemixId': getEmailID(),
        productTitle: 'Crosswalks Tool',
        name: 'On Dashboard chart click',
        data: queryParams,
        object: queryParams,
        objectType: 'json',
        successFlag: true,
        resultValue: 'On Dashboard chart clicked',
        milestoneName: 'On Dashboard chart clicked',
        url: getHost(),
      });
      const query = new URLSearchParams(queryParams).toString();
      getDashboardControls(query)
        .then(result => {
          let { data = {} } = result;
          let tableData = [];
          let count = 1;
          for (let list of data) {
            let obj = {
              ...list,
              id: 'key_' + count,
            };
            tableData.push(obj);
            count++;
          }
          setRows(tableData);
          setLoader(false);
        })
        .catch(err => {
          console.log('err ', err);
          setRows([]);
          setLoader(false);
        });
    },
    [mid]
  );

  useEffect(() => {
    chartRef?.current?.chart?.services?.events?.addEventListener(
      'bar-click',
      chartOnClick
    );
    donutChartRef?.current?.chart?.services?.events?.addEventListener(
      'pie-slice-click',
      chartOnClick
    );
    barChartRef?.current?.chart?.services?.events?.addEventListener(
      'bar-click',
      chartOnClick
    );
  }, [chartRef, barChartRef, donutChartRef, chartOnClick]);

  // Unmount
  useEffect(
    () => () => {
      if (chartRef.current) {
        chartRef?.current?.chart?.services?.events?.removeEventListener(
          'bar-click',
          chartOnClick
        );
      }
      if (donutChartRef.current) {
        donutChartRef?.current?.chart?.services?.events?.removeEventListener(
          'pie-slice-click',
          chartOnClick
        );
      }
      if (barChartRef.current) {
        barChartRef?.current?.chart?.services?.events?.removeEventListener(
          'bar-click',
          chartOnClick
        );
      }
    },
    [chartOnClick]
  );

  useEffect(() => {
    setPageLoader(true);
    let queryParams = {};
    if (mid && mid !== 'overview') {
      queryParams = { ...queryParams, mapping_request_id: mid };
    }
    const query = new URLSearchParams(queryParams).toString();

    getDashboardCategory(query)
      .then(result => {
        let { data = {} } = result;
        let { score = {}, controls = [] } = data || {};
        let bankControlsMapping = [];
        let controlAlignmentByDomain = [];
        let donutControlData = [];
        for (let list in score) {
          let obj = {
            group: startCase(list.replaceAll('_', ' ').toLowerCase()),
            value: score[list],
          };
          bankControlsMapping.push(obj);
          if (
            list === 'MET' ||
            list === 'DEFAULT' ||
            list === 'NEEDS_FURTHER_DISCUSSION'
          ) {
            donutControlData.push(obj);
          }
        }
        for (let data of controls) {
          let { name = '', score = {} } = data;
          for (let list in score) {
            let obj = {};
            obj['group'] = startCase(list.replaceAll('_', ' ').toLowerCase());
            obj['key'] = startCase(name.replaceAll('_', ' ').toLowerCase());
            obj['value'] = score[list];
            controlAlignmentByDomain.push(obj);
          }
        }
        setDonutData(donutControlData);
        setBarData(bankControlsMapping);
        setStackedData(controlAlignmentByDomain);
        setControlDetails(data);
        setPageLoader(false);
      })
      .catch(err => {
        console.log('err ', err);
        setPageLoader(false);
      });
  }, [mid]);

  return (
    <div id="create" className={`${COMPONENT_NAME}`}>
      <CustomBreadcrumb
        title="Control Assessment Summary | Overview"
        id="dashboard-overview"
        mid={mid}
        type={type}
      />
      <div
        style={{
          padding: '0px 30px',
          background: '#fff',
        }}>
        {showPageLoader ? (
          <Loading className="loader-component" withOverlay={false} />
        ) : (
          <>
            <Row>
              <Column lg={4}>
                <Card>
                  <CardHeader title="Total Bank's Controls" />
                  <CardBody>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                      {controlDetails?.count}
                    </div>
                  </CardBody>
                </Card>
              </Column>
              <Column lg={4}>
                <Card>
                  <CardHeader title="In-Scope Bank's Controls" />
                  <CardBody>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                      {controlDetails?.in_scope_controls}
                    </div>
                  </CardBody>
                </Card>
              </Column>
              <Column lg={4}>
                <Card>
                  <CardHeader title="Bank's Control Mapped" />
                  <CardBody>
                    {controlDetails?.count > 0 && (
                      <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                        {controlDetails.controls_mapped}(
                        {Math.round(
                          ((controlDetails.controls_mapped /
                            controlDetails.in_scope_controls) *
                            100 +
                            Number.EPSILON) *
                            100
                        ) / 100}
                        %)
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Column>
              <Column lg={4}>
                <Card>
                  <CardHeader title="Controls required further info" />
                  <CardBody>
                    {controlDetails?.count > 0 && (
                      <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                        {controlDetails.controls_need_further_discussion}(
                        {Math.round(
                          ((controlDetails.controls_need_further_discussion /
                            controlDetails.in_scope_controls) *
                            100 +
                            Number.EPSILON) *
                            100
                        ) / 100}
                        %)
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Column>
            </Row>
            <br />
            <Row>
              <Column lg={4}>
                <Card>
                  <CardHeader title="Bank's Control Mapping" />
                  <DonutChart
                    ref={donutChartRef}
                    data={donutData}
                    options={donutOptions}
                  />
                </Card>
              </Column>
              <Column lg={4}>
                <Card>
                  <CardHeader title="Bank's Controls Alignment" />
                  <SimpleBarChart
                    ref={barChartRef}
                    data={barData}
                    options={barOptions}
                  />
                </Card>
              </Column>

              <Column lg={8}>
                <Card>
                  <CardHeader title="Bank Control's Alignment By Domain" />
                  <StackedBarChart
                    ref={chartRef}
                    data={stackData}
                    options={stackOptions}
                  />
                </Card>
              </Column>
            </Row>
            <br />
            {clicked && (
              <>
                <Card style={{ position: 'relative' }}>
                  <CardHeader title="Bank's Control Details" />
                  <div
                    style={{
                      position: 'absolute',
                      right: '20px',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      top: '12px',
                      fontSize: '20px',
                    }}
                    onClick={() => setClicked(false)}>
                    X
                  </div>
                  <div style={{ padding: '15px' }}>
                    {loader ? (
                      <EnhancedDataTable
                        id="basic-table"
                        title="My datatable"
                        description="Loading state with header"
                        headers={headers}
                        initialCols={initialCols}
                      />
                    ) : (
                      <EnhancedDataTable
                        size="md"
                        id="table-with-inline-filter"
                        className={`${COMPONENT_NAME}__datatable`}
                        rows={rows}
                        headers={headers}
                        initialCols={initialCols}
                      />
                    )}
                  </div>
                </Card>
                <br />
                <br />
              </>
            )}

            <Card>
              <CardHeader title="Summary" />
              <CardBody>
                <ul
                  style={{
                    background: '#fff',
                    padding: '20px',
                  }}>
                  <li style={{ marginLeft: '10px', listStyle: 'disc' }}>
                    {controlDetails?.in_scope_controls} Controls out of{' '}
                    {controlDetails?.count} Bank Controls are considered for
                    Control Assessment.
                  </li>
                  <li style={{ marginLeft: '10px', listStyle: 'disc' }}>
                    {controlDetails?.controls_mapped} out of{' '}
                    {controlDetails?.in_scope_controls} Client Controls met with
                    IBM Cloud FS Framework
                  </li>
                  <li style={{ marginLeft: '10px', listStyle: 'disc' }}>
                    Of the{' '}
                    {controlDetails?.count - controlDetails?.in_scope_controls}{' '}
                    excluded Controls:-{' '}
                    {barData
                      .filter(
                        x =>
                          x.group !== 'MET' &&
                          x.group !== 'DEFAULT' &&
                          x.group !== 'NEEDS_FURTHER_DISCUSSION'
                      )
                      .map(x => (
                        <p style={{ fontSize: '14px' }}>
                          {x.value} controls are related to{' '}
                          {startCase(x.group.toLowerCase())}
                        </p>
                      ))}
                  </li>
                  <p style={{ fontSize: '12px', marginTop: '10px' }}>
                    Note: Considered Client's Controls Only includes 'Met',
                    'Default' and 'Need further info' categories.
                  </p>
                </ul>
              </CardBody>
            </Card>
          </>
        )}
        <br />
      </div>
    </div>
  );
};
export default Dashboard;
