import axios from '../helpers/apiHelper';
import { BASE_URL } from '../helpers/urls';

export const createMapping = async (formData, account_id) => {
  return await axios.post(`${BASE_URL}/resource/mapping-request/`, formData, {
    params: {
      account_id,
    },
  });
};

export const validateMapping = async formData => {
  return await axios.post(`${BASE_URL}/profile/validate`, formData);
};

export const fileUpload = async formData => {
  return await axios.post(`${BASE_URL}/resource/file-parser/`, formData);
};

export const getTargetProfiles = async () => {
  return await axios.get(`${BASE_URL}/profile/target-profile/`);
};
