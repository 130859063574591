import axios from '../helpers/apiHelper';
import { BASE_URL } from '../helpers/urls';

export const exportProfileDetails = async (formData, mid) => {
  return await axios.post(`${BASE_URL}/scc-profiles/export/${mid}`, formData);
};

export const getMappingName = async (mid, queryParams) => {
  return await axios.get(`${BASE_URL}/resource/mapping-name/${mid}`, {
    params: { ...queryParams },
  });
};

export const exportCustomControlLibraryDetails = async (formData, mid) => {
  return await axios.post(
    `${BASE_URL}/scc-profiles/scc-v2/export/${mid}`,
    formData
  );
};

export const getCustomControlMappingName = async (mid, queryParams) => {
  return await axios.get(`${BASE_URL}/resource/scc-v2/mapping-name/${mid}`, {
    params: { ...queryParams },
  });
};

export const getRegion = async (accId, apikey) => {
  return await axios.get(
    `${BASE_URL}/scc-profiles/export/region-instance?account_id=${accId}&api_key=${apikey}`
  );
};
