import React, { useState } from 'react';
import { TextInput, Modal } from '@carbon/react';
// import { approveAllMapping } from '../../api/MappingPage';
import './MappingPage.scss';

const ApproverModal = ({
  role,
  mid,
  confirmationModal,
  setConfirmationModal,
  mDetails,
  accountDetails,
  assignUsers,
  unReviewedControls,
  editMappingRecommendation,
}) => {
  const [mappingName, setMappingName] = useState('');
  const [error, setError] = useState(false);

  return (
    <Modal
      open={confirmationModal}
      onRequestClose={() => setConfirmationModal(false)}
      onRequestSubmit={() => {
        if (mDetails.profile_name !== mappingName && role === 'COMPLETED') {
          setError(true);
        } else if (
          mDetails.profile_name === mappingName &&
          role === 'COMPLETED'
        ) {
          setError(false);
          let data = {
            action_status: 'COMPLETED',
            account_id: accountDetails.acc_id,
          };
          if (unReviewedControls?.length) {
            let payload = [];
            for (let mapping of unReviewedControls) {
              let { control_id = '' } = mapping;
              let obj = {};
              obj['control_id'] = control_id;
              obj['overall_status'] = 'APPROVED';
              payload.push(obj);
            }
            let updatedData = {
              mapping_recommendation: payload,
            };
            editMappingRecommendation(updatedData);
          }
          // let queryObj = {
          //   account_id: accountDetails.acc_id,
          //   mapping_request_id: mid,
          //   status: 'APPROVED',
          // };

          // const queryParam = new URLSearchParams(queryObj).toString();
          // approveAllMapping(queryParam)
          //   .then(res => {
          //     assignUsers(data);
          //   })
          //   .catch(err => {
          //     console.log(err);
          //   });
          assignUsers(data);
        } else if (role === 'APPROVER') {
          setError(false);
          let data = {
            action_status: 'PENDING_REVIEW',
            account_id: accountDetails.acc_id,
          };
          assignUsers(data);
        }
      }}
      primaryButtonText="Proceed"
      secondaryButtonText="Cancel">
      <div style={{ marginBottom: '15px' }}>
        {role === 'APPROVER'
          ? 'By clicking "proceed", the mapping will be sent to the reviewer.'
          : 'By clicking "proceed", the mapping will be approved, and no further updates will be allowed.'}
      </div>
      {role === 'COMPLETED' && (
        <>
          <p>
            <TextInput
              id="mappingName"
              required
              labelText="Mapping name*"
              onChange={e => setMappingName(e?.target?.value)}
              value={mappingName}
            />
          </p>
          {error && (
            <div style={{ color: 'red', paddingTop: '5px' }}>
              Mapping name is not matching!
            </div>
          )}
        </>
      )}
      <div />
    </Modal>
  );
};

export default ApproverModal;
