import React from 'react';
import UserContent from './content';
import Layout from '../common/Layout';

const UserInvitation = props => {
  return (
    <Layout>
      <UserContent accountDetails={props.accountDetails} />
    </Layout>
  );
};

export default UserInvitation;
