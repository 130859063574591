import React from 'react';
import { Modal } from '@carbon/react';

const CategoryInfoModal = ({
  categoryInfo,
  categoryModal,
  setCategoryModal,
}) => {
  return (
    <Modal
      open={categoryModal}
      onRequestClose={() => setCategoryModal(false)}
      passiveModal
      modalHeading="Category information is missing, you cannot proceed further!"
    />
  );
};

export default CategoryInfoModal;
