import axios from '../helpers/apiHelper';
import { BASE_URL } from '../helpers/urls';

export const getUsers = async account_id => {
  return await axios.get(
    `${BASE_URL}/accessgroup/invitations/?account_id=${account_id}`
  );
};

export const inviteUser = async formData => {
  return await axios.post(`${BASE_URL}/accessgroup/invitations/`, null, {
    params: { ...formData },
  });
};

export const updateUser = async (invitationId, formData) => {
  return await axios.put(
    `${BASE_URL}/accessgroup/invitations/${invitationId}`,
    null,
    {
      params: { ...formData },
    }
  );
};
