import React, { useState, useEffect } from 'react';
import {
  TextInput,
  Form,
  FormGroup,
  Select,
  SelectItem,
  RadioButtonGroup,
  RadioButton,
  FileUploader,
  TextArea,
  ProgressIndicator,
  ProgressStep,
  Stack,
  InlineNotification,
} from '@carbon/react';
import { Card, CardHeader, CardBody } from '@console/pal/Components';
import { Button, Row, Column } from '@console/pal/carbon-components-react';
import CustomBreadcrumb from '../common/Breadcrumb';
import { useHistory } from 'react-router-dom';
import {
  createMapping,
  fileUpload,
  getTargetProfiles,
  validateMapping,
} from '../../api/CreateMapping';
import { SOURCE_CONTROL_TYPES } from '../../config/mapping';
import { getEmailID } from '../../api/common';
import { getHost } from '../../helpers/urls';
import './Create.scss';

const COMPONENT_NAME = 'create-page';

const Create = props => {
  let { accountDetails = {} } = props;
  let { acc_id = '' } = accountDetails;
  const history = useHistory();
  const [createMappings, setCreateMappings] = useState({});
  const [isBatchMode, setIsBatchMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [targetProfiles, setTargetProfiles] = useState([]);
  const [targetVersions, setTargetVersions] = useState([]);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [error, showError] = useState(false);
  const [errorMessage, showErrorMessage] = useState({});

  useEffect(() => {
    getTargetProfiles().then(result => {
      setTargetProfiles(result?.data?.target_profiles);
    });
  }, []);

  const handleCreateMappingChange = e => {
    setCreateMappings({
      ...createMappings,
      [e.target.id]: e.target.value,
    });
  };

  const handleRadioButtonChange = e => {
    setCreateMappings({
      ...createMappings,
      controlId: '',
      controlText: '',
      controlTitle: '',
      controlCategory: '',
      sourceParent: '',
    });
    if (e === 'Batch Mode') {
      setIsBatchMode(true);
    } else {
      setIsBatchMode(false);
    }
  };

  const handleGenerateMapping = () => {
    setGenerateFlag(true);
    let formData = new FormData();
    formData.append('target_profile_id', createMappings.targetVersion);
    formData.append('model', 'RL-2');
    formData.append('type', createMappings.sourceControlType);
    formData.append('name', createMappings.name);
    formData.append('version', createMappings.version);
    formData.append('title', createMappings.description || '');
    window.analytics.track('Started Process', {
      'user.email': getEmailID(),
      'user.bluemixId': getEmailID(),
      productTitle: 'Crosswalks Tool',
      name: 'Create Mapping',
      processType: 'Generate Mapping',
      process: 'Generate',
      resultValue: 'Running mapping generation',
      successFlag: true,
      category: createMappings.name + '_' + createMappings.version,
      object: JSON.stringify(Object.fromEntries(formData)),
      milestoneName: 'Running mapping generation',
      url: getHost(),
    });
    if (isBatchMode) {
      formData.append('control_text_field', createMappings.controlText);
      formData.append('control_id_field', createMappings.controlId);
      formData.append('control_title_field', createMappings.controlTitle || '');
      formData.append(
        'control_category_field',
        createMappings.controlCategory || ''
      );
      formData.append(
        'source_parent_id_field',
        createMappings.sourceParent || ''
      );
      formData.append('file', selectedFiles[0]);
    } else {
      formData.append('control_text', createMappings.controlText);
      formData.append('control_id', createMappings.controlId);
      formData.append('control_title', createMappings.controlTitle || '');
      formData.append('control_category', createMappings.controlCategory || '');
      formData.append('source_parent_id', createMappings.sourceParent || '');
    }
    createMapping(formData, acc_id)
      .then(result => {
        setGenerateFlag(false);
        window.analytics.track('Ended Process', {
          'user.email': getEmailID(),
          'user.bluemixId': getEmailID(),
          productTitle: 'Crosswalks Tool',
          name: 'Create Mapping',
          processType: 'Generate Mapping',
          process: 'Generate',
          resultValue: 'Running mapping generation',
          successFlag: true,
          category: createMappings.name + '_' + createMappings.version,
          object: JSON.stringify(Object.fromEntries(formData)),
          milestoneName: 'Mapping generation completed',
          url: getHost(),
        });
        history.push('/landingpage');
      })
      .catch(error => {
        setGenerateFlag(false);
        showErrorMessage(error?.response?.data);
      });
  };

  const addFiles = evt => {
    setSelectedFiles(evt.target.files);
    generateMappingColumn(evt.target.files);
  };

  const removeFiles = () => {
    setSelectedFiles([]);
    setColumnList([]);
  };

  function fetchXlsxInfo(data) {
    if (data && data.length > 0) {
      let formData = new FormData();
      formData.append('file', data[0]);
      formData.append('format', 'xlsx');
      fileUpload(formData)
        .then(result => {
          setColumnList(result.data.column_names);
          window.analytics.track('Ran Process', {
            'user.email': getEmailID(),
            'user.bluemixId': getEmailID(),
            productTitle: 'Crosswalks Tool',
            name: 'Generate column names',
            object: JSON.stringify(Object.fromEntries(formData)),
            processType: 'Batch',
            process: 'Inferencing',
            successFlag: true,
            resultValue: 'AI model invoked',
            milestoneName: 'Control Mapping generation triggered',
            url: getHost(),
          });
        })
        .catch(error => console.log(error));
    } else {
      console.log('Please upload a file', '11rem');
    }
  }

  const generateMappingColumn = selectedFiles => {
    fetchXlsxInfo(selectedFiles);
  };

  const validateMappingName = () => {
    showError(false);
    let formData = {
      name: createMappings.name,
      version: createMappings.version,
      account_id: acc_id,
    };
    window.analytics.track('Started Process', {
      'user.email': getEmailID(),
      'user.bluemixId': getEmailID(),
      productTitle: 'Crosswalks Tool',
      name: 'Validate Mapping',
      processType: 'Validate Mapping',
      process: 'Validate',
      resultValue: 'Validate mapping generation name',
      successFlag: true,
      category: createMappings.name + '_' + createMappings.version,
      object: formData,
      milestoneName: 'Validate mapping generation name',
      url: getHost(),
    });
    validateMapping(formData)
      .then(result => {
        let { success = '' } = result.data || {};
        if (success === true) {
          setCurrentIndex(currentIndex + 1);
        } else {
          showError(true);
        }
        window.analytics.track('Ended Process', {
          'user.email': getEmailID(),
          'user.bluemixId': getEmailID(),
          productTitle: 'Crosswalks Tool',
          name: 'Validate Mapping',
          processType: 'Validate Mapping',
          process: 'Validate',
          resultValue: 'Validate mapping generation name',
          successFlag: true,
          category: createMappings.name + '_' + createMappings.version,
          object: formData,
          milestoneName: 'Validate mapping generation name completed',
          url: getHost(),
        });
      })
      .catch(err => {
        console.log(err);
        showError(true);
      });
  };

  let targetProfileVersion = targetVersions.find(
    target => target.profile_id === createMappings.targetVersion
  );

  const Buttons = () => {
    return (
      <Row>
        <Column lg={12}>
          <div className={`${COMPONENT_NAME}--buttons`}>
            <Button
              kind={'ghost'}
              size="xl"
              className={`${COMPONENT_NAME}--cancel`}
              onClick={() => history.push('/landingpage')}>
              Cancel
            </Button>

            <Button
              size="xl"
              className={`${COMPONENT_NAME}--next`}
              disabled={
                currentIndex === 0
                  ? !createMappings.name || !createMappings.version
                  : currentIndex === 1
                  ? !createMappings.sourceControlType ||
                    !createMappings.targetType
                  : currentIndex === 2
                  ? !createMappings.controlId || !createMappings.controlText
                  : currentIndex === 3
                  ? generateFlag
                  : false
              }
              onClick={() => {
                if (currentIndex < 3) {
                  if (currentIndex === 0) {
                    validateMappingName();
                  } else setCurrentIndex(currentIndex + 1);
                } else {
                  handleGenerateMapping();
                }
              }}>
              {currentIndex === 3 ? 'Create' : 'Next'}
            </Button>
            {currentIndex !== 0 && (
              <Button
                size="xl"
                kind="secondary"
                className={`${COMPONENT_NAME}--back`}
                onClick={() => setCurrentIndex(currentIndex - 1)}>
                Back
              </Button>
            )}
          </div>
        </Column>
      </Row>
    );
  };

  const mappingOptions = columnList.map((column, index) => (
    <SelectItem key={index} text={column} value={column} />
  ));

  return (
    <div id="create" className={`${COMPONENT_NAME}`}>
      <CustomBreadcrumb title="Create Mapping" id="create" />
      <div className={`${COMPONENT_NAME}--section`}>
        <div style={{ width: '60%' }}>
          <ProgressIndicator currentIndex={currentIndex} spaceEqually={true}>
            <ProgressStep
              label="Details"
              description="The progress indicator will listen for clicks on the steps"
            />
            <ProgressStep
              label="Type"
              description="The progress indicator will listen for clicks on the steps"
            />
            <ProgressStep
              label="Mode"
              description="The progress indicator will listen for clicks on the steps"
            />
            <ProgressStep
              label="Review"
              description="The progress indicator will listen for clicks on the steps"
            />
          </ProgressIndicator>
          {error ? (
            <div className="m-top">
              <InlineNotification
                lowContrast
                timeout={6000}
                kind="warning"
                title="Mapping name already exists!"
                subtitle=""
              />
            </div>
          ) : (
            <>
              {errorMessage && errorMessage.err && (
                <div className="m-top">
                  <InlineNotification
                    lowContrast
                    timeout={3000}
                    kind="warning"
                    title={errorMessage.err}
                    subtitle=""
                  />
                </div>
              )}{' '}
            </>
          )}

          <Form>
            <Stack gap={5}>
              {currentIndex === 0 ? (
                <div className={`${COMPONENT_NAME}--form-items`}>
                  <div className="m-top" />
                  <p
                    style={{
                      fontSize: '13px',
                      marginBottom: '1rem',
                    }}>
                    Provide a unique name and a description that will help you
                    and other members of your organization to know what is
                    included in the mapping. For example, IBM Cloud services.
                  </p>
                  <TextInput
                    id="name"
                    required
                    labelText="Name*"
                    onChange={e => handleCreateMappingChange(e)}
                    value={createMappings.name}
                  />
                  <div className="m-top" />
                  <TextInput
                    id="version"
                    required
                    labelText="Version*"
                    onChange={e => handleCreateMappingChange(e)}
                    value={createMappings.version}
                  />
                  <div className="m-top" />
                  <TextArea
                    id="description"
                    labelText="Description (optional)"
                    maxCount={100}
                    enableCounter={true}
                    onChange={e => handleCreateMappingChange(e)}
                    value={createMappings.description}
                  />
                </div>
              ) : currentIndex === 1 ? (
                <div className={`${COMPONENT_NAME}--form-items`}>
                  <div className="m-top">
                    <Select
                      defaultValue="placeholder-item"
                      id="sourceControlType"
                      onChange={e => handleCreateMappingChange(e)}
                      labelText="Source Control Type"
                      value={createMappings.sourceControlType}
                      size="md">
                      <SelectItem
                        disabled
                        hidden
                        text="Choose an option"
                        value="placeholder-item"
                      />
                      {SOURCE_CONTROL_TYPES.map((mapping, index) => (
                        <SelectItem
                          text={mapping.text}
                          value={mapping.value}
                          key={index}
                        />
                      ))}
                    </Select>
                  </div>
                  <div>
                    <div className="m-top">
                      <Select
                        defaultValue="placeholder-item"
                        id="targetType"
                        onChange={e => {
                          let targetVersionList = targetProfiles?.find(
                            x => x.profile_name === e.target.value
                          );
                          setTargetVersions(
                            targetVersionList?.profile_versions
                          );
                          setCreateMappings({
                            ...createMappings,
                            targetType: e.target.value,
                            targetVersion:
                              targetVersionList?.profile_versions[0]
                                ?.profile_id,
                          });
                        }}
                        labelText="Target Type"
                        value={createMappings.targetType}
                        size="md">
                        <SelectItem
                          disabled
                          hidden
                          text="Choose an option"
                          value="placeholder-item"
                        />
                        {targetProfiles?.map((target, index) => (
                          <SelectItem
                            key={index}
                            text={target.profile_name}
                            value={target.profile_name}
                          />
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="m-top">
                    <Select
                      defaultValue="placeholder-item"
                      id="targetVersion"
                      onChange={e => {
                        setCreateMappings({
                          ...createMappings,
                          targetVersion: e.target.value,
                        });
                      }}
                      labelText="Target Version"
                      value={createMappings.targetVersion}
                      size="md">
                      <SelectItem
                        disabled
                        hidden
                        text="Choose an option"
                        value="placeholder-item"
                      />
                      {targetVersions.map((target, index) => (
                        <SelectItem
                          key={index}
                          text={target.Version}
                          value={target.profile_id}
                        />
                      ))}
                    </Select>
                  </div>
                </div>
              ) : currentIndex === 2 ? (
                <div className={`${COMPONENT_NAME}--form-items`}>
                  <div className="m-top">
                    <div>
                      <p className="f-weight">
                        Select mode to generate mapping
                      </p>
                      <FormGroup>
                        <RadioButtonGroup
                          legendTest="Group Legend"
                          name="radio-button-group"
                          defaultSelected="Interactive Mode"
                          onChange={e => handleRadioButtonChange(e)}
                          orientation="vertical">
                          <RadioButton
                            id="radio-1"
                            labelText="Interactive mode"
                            value="Interactive Mode"
                          />
                          <RadioButton
                            id="radio-2"
                            labelText="Batch mode"
                            value="Batch Mode"
                          />
                        </RadioButtonGroup>
                      </FormGroup>
                    </div>
                    {isBatchMode ? (
                      <div className="m-top">
                        <div className="cds--file__container">
                          <FileUploader
                            labelTitle="Select a file"
                            buttonLabel="Select file"
                            labelDescription="only .xlsx files"
                            filenameStatus="edit"
                            multiple={false}
                            buttonKind="tertiary"
                            onChange={addFiles}
                            onDelete={removeFiles}
                            onClick={removeFiles}
                            iconDescription="Clear file"
                            accept={['.xlsx']}
                          />
                        </div>
                        {columnList.length > 0 && (
                          <div>
                            <p className="m-top f-weight">
                              Please select matching columns from the Excel for
                              control id, title and description
                            </p>
                            <div>
                              <div className="m-top">
                                <Select
                                  id="controlId"
                                  onChange={e => handleCreateMappingChange(e)}
                                  labelText="Select matching column for control id"
                                  value={
                                    createMappings.controlId ||
                                    'placeholder-item'
                                  }
                                  size="md">
                                  <SelectItem
                                    disabled
                                    hidden
                                    text="Choose an option"
                                    value="placeholder-item"
                                  />
                                  {mappingOptions}
                                </Select>
                              </div>
                            </div>
                            <div>
                              <div className="m-top">
                                <Select
                                  id="controlTitle"
                                  defaultSelected="placeholder-item"
                                  onChange={e => handleCreateMappingChange(e)}
                                  labelText="Select matching column for control title (optional)"
                                  value={
                                    createMappings.controlTitle ||
                                    'placeholder-item'
                                  }
                                  size="md">
                                  <SelectItem
                                    disabled
                                    hidden
                                    text="Choose an option"
                                    value="placeholder-item"
                                  />
                                  {mappingOptions}
                                </Select>
                              </div>
                            </div>
                            <div>
                              <div className="m-top">
                                <Select
                                  id="controlText"
                                  defaultSelected="placeholder-item"
                                  onChange={e => handleCreateMappingChange(e)}
                                  labelText="Select matching column for control text"
                                  value={
                                    createMappings.controlText ||
                                    'placeholder-item'
                                  }
                                  size="md">
                                  <SelectItem
                                    disabled
                                    hidden
                                    text="Choose an option"
                                    value="placeholder-item"
                                  />
                                  {mappingOptions}
                                </Select>
                              </div>
                            </div>
                            <div>
                              <div className="m-top">
                                <Select
                                  id="controlCategory"
                                  defaultSelected="placeholder-item"
                                  onChange={e => handleCreateMappingChange(e)}
                                  labelText="Select matching column for control category (optional)"
                                  value={
                                    createMappings.controlCategory ||
                                    'placeholder-item'
                                  }
                                  size="md">
                                  <SelectItem
                                    disabled
                                    hidden
                                    text="Choose an option"
                                    value="placeholder-item"
                                  />
                                  {mappingOptions}
                                </Select>
                              </div>
                            </div>
                            <div>
                              <div className="m-top">
                                <Select
                                  id="sourceParent"
                                  defaultSelected="placeholder-item"
                                  onChange={e => handleCreateMappingChange(e)}
                                  labelText="Select matching column for source parent(optional)"
                                  value={
                                    createMappings.sourceParent ||
                                    'placeholder-item'
                                  }
                                  size="md">
                                  <SelectItem
                                    disabled
                                    hidden
                                    text="Choose an option"
                                    value="placeholder-item"
                                  />
                                  {mappingOptions}
                                </Select>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div>
                          <div className="m-top">
                            <TextInput
                              id="controlId"
                              labelText="Input control id"
                              onChange={e => handleCreateMappingChange(e)}
                              value={createMappings.controlId}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="m-top">
                            <TextInput
                              id="controlTitle"
                              labelText="Input control title (optional)"
                              onChange={e => handleCreateMappingChange(e)}
                              value={createMappings.controlTitle}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="m-top">
                            <TextInput
                              id="controlText"
                              labelText="Input control text"
                              onChange={e => handleCreateMappingChange(e)}
                              value={createMappings.controlText}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="m-top">
                            <TextInput
                              id="controlCategory"
                              labelText="Input control category (optional)"
                              onChange={e => handleCreateMappingChange(e)}
                              value={createMappings.controlCategory}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="m-top">
                            <TextInput
                              id="sourceParent"
                              labelText="Input control parent (optional)"
                              onChange={e => handleCreateMappingChange(e)}
                              value={createMappings.sourceParent}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="m-top" />
                  <Card>
                    <CardHeader
                      title="Details"
                      onEditClick={() => {
                        setCurrentIndex(0);
                      }}
                      onSaveClick={() => {}}
                      onCancelClick={() => {}}
                      editText="Edit"
                      saveText="Save"
                    />
                    <CardBody>
                      <Row>
                        <Column lg={3}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Name
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {createMappings.name}
                          </div>
                        </Column>
                        <Column lg={3}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Version
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {createMappings.version || '-'}
                          </div>
                        </Column>
                        <Column lg={6}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Description
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {createMappings.description || '-'}
                          </div>
                        </Column>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader
                      title="Type"
                      onEditClick={() => {
                        setCurrentIndex(1);
                      }}
                      onSaveClick={() => {}}
                      onCancelClick={() => {}}
                      editText="Edit"
                      saveText="Save"
                    />
                    <CardBody>
                      <Row>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Source type
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {createMappings.sourceControlType.replaceAll(
                              '_',
                              ' '
                            )}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Target type
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {createMappings.targetType || '-'}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Target version
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {targetProfileVersion.Version || '-'}
                          </div>
                        </Column>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader
                      title="Mode"
                      onEditClick={() => {
                        setCurrentIndex(2);
                      }}
                      onSaveClick={() => {}}
                      onCancelClick={() => {}}
                      editText="Edit"
                      saveText="Save"
                    />
                    <CardBody>
                      <Row>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Mode
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {isBatchMode ? 'Batch mode' : 'Interactive mode'}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Control id
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {createMappings.controlId || '-'}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Control title
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {createMappings.controlTitle || '-'}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Control category
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {createMappings.controlCategory || '-'}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Source Parent
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {createMappings.sourceParent || '-'}
                          </div>
                        </Column>
                        {isBatchMode && (
                          <Column lg={4}>
                            <div className={`${COMPONENT_NAME}--review-title`}>
                              Filename
                            </div>
                            <div
                              className={`${COMPONENT_NAME}--review-details`}>
                              {selectedFiles[0].name}
                            </div>
                          </Column>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </>
              )}
            </Stack>
          </Form>
          <Buttons />
        </div>
      </div>
    </div>
  );
};
export default Create;
