import React, { useState } from 'react';
import {
  TextInput,
  Form,
  Select,
  SelectItem,
  TextArea,
  ProgressIndicator,
  ProgressStep,
  Stack,
  InlineNotification,
} from '@carbon/react';
import { Card, CardHeader, CardBody } from '@console/pal/Components';
import {
  Button,
  Row,
  Column,
  Toggle,
} from '@console/pal/carbon-components-react';
import CustomBreadcrumb from '../common/Breadcrumb';
import { useHistory, useParams } from 'react-router-dom';
import { exportProfileDetails, getMappingName } from '../../api/ExportProfile';
import { AVAILABLE_FORMAT } from './exportProfileConfig';
import { getEmailID } from '../../api/common';
import './exportProfile.scss';
import { getHost } from '../../helpers/urls';

const COMPONENT_NAME = 'export-profile';

const ExportProfile = () => {
  const history = useHistory();
  const { mid, type } = useParams();
  const [exportProfile, setExportProfile] = useState({
    downloadProfile: false,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showToast, setToastNotification] = useState('');

  const validateMappingName = () => {
    let queryParams = {
      mapping_type: type.toUpperCase(),
      is_export: exportProfile.downloadProfile,
    };
    if (exportProfile.downloadProfile === false) {
      queryParams = {
        ...queryParams,
        account_id: exportProfile.accountId,
        api_key: exportProfile.apiKey,
      };
    }

    getMappingName(mid, queryParams)
      .then(result => {
        return result.data;
      })
      .then(data => {
        let updatedDetails = {
          ...exportProfile,
          name: data.profile_export_name,
        };
        setExportProfile(updatedDetails);
      })
      .catch(err => console.log('err ', err));
  };

  const handleCreateMappingChange = e => {
    setExportProfile({
      ...exportProfile,
      [e.target.id]: e.target.value,
    });
  };

  const handleGenerateMapping = () => {
    let formData = new FormData();
    if (exportProfile.downloadProfile === false) {
      formData.append('api_key', exportProfile.apiKey);
      formData.append('account_id', exportProfile.accountId);
    }
    formData.append('download_file', exportProfile.downloadProfile || false);
    formData.append('mapping_type', type);
    formData.append('export_profile_type', exportProfile.supportedFormat);
    formData.append('ibm_goals', exportProfile.ibmGoals || false);
    formData.append('controls_with_goals', exportProfile.controlGoals || false);

    exportProfileDetails(formData, mid)
      .then(response => {
        setToastNotification('success');
        window.analytics.track('Exported Object', {
          'user.email': getEmailID(),
          'user.bluemixId': getEmailID(),
          productTitle: 'Crosswalks Tool',
          name: 'Export Profile (V1)',
          object: JSON.stringify(Object.fromEntries(formData)),
          data: JSON.stringify(Object.fromEntries(formData)),
          objectType: 'csv',
          successFlag: true,
          resultValue: 'Export Profile (V1)',
          milestoneName: 'Export Profile details downloaded',
          url: getHost(),
        });
        if (exportProfile.downloadProfile === true) {
          const blob = new Blob([response.data], {
            type: 'data:text/csv;charset=utf-8,',
          });
          const blobURL = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.download = `${exportProfile.name}.csv`;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            'text/csv',
            anchor.download,
            anchor.href,
          ].join(':');
          anchor.click();
        }
      })
      .catch(err => {
        setToastNotification('failure');
        console.error(err);
      });
  };

  const Buttons = () => {
    return (
      <Row>
        <Column lg={16}>
          <div className={`${COMPONENT_NAME}--buttons`}>
            <Button
              kind={'ghost'}
              size="xl"
              className={`${COMPONENT_NAME}--cancel`}
              onClick={() => history.push('/landingpage')}>
              Cancel
            </Button>

            <Button
              size="xl"
              className={`${COMPONENT_NAME}--next`}
              disabled={
                currentIndex === 0
                  ? !exportProfile.supportedFormat ||
                    (exportProfile.downloadProfile === false
                      ? !exportProfile.accountId || !exportProfile.apiKey
                      : false)
                  : currentIndex === 1
                  ? !exportProfile.name
                  : false
              }
              onClick={() => {
                if (currentIndex < 1) {
                  validateMappingName();
                  setCurrentIndex(currentIndex + 1);
                } else if (currentIndex < 2) {
                  setCurrentIndex(currentIndex + 1);
                } else {
                  handleGenerateMapping();
                }
              }}>
              {currentIndex === 2 ? 'Export' : 'Next'}
            </Button>
            {currentIndex !== 0 && (
              <Button
                size="xl"
                kind="secondary"
                className={`${COMPONENT_NAME}--back`}
                onClick={() => setCurrentIndex(currentIndex - 1)}>
                Back
              </Button>
            )}
          </div>
        </Column>
      </Row>
    );
  };

  return (
    <div id="create" className={`${COMPONENT_NAME}`}>
      <CustomBreadcrumb title="Export profile (v1)" mid={mid} />
      <div className={`${COMPONENT_NAME}--section`}>
        {showToast && (
          <InlineNotification
            lowContrast
            style={{ marginBottom: '20px' }}
            timeout={6000}
            kind={showToast === 'success' ? 'success' : 'warning'}
            title={showToast === 'success' ? 'Success' : 'Warning'}
            subtitle={
              showToast === 'success'
                ? exportProfile.downloadProfile === true
                  ? `${exportProfile.name} profile is exported successfully.`
                  : 'Export profile is successful.'
                : 'Oops! there was some issue while exporting the profile, please try again!'
            }
          />
        )}
        <div style={{ width: '60%' }}>
          <ProgressIndicator currentIndex={currentIndex} spaceEqually={true}>
            <ProgressStep
              label="Format"
              description="The progress indicator will listen for clicks on the steps"
            />
            <ProgressStep
              label="Details"
              description="The progress indicator will listen for clicks on the steps"
            />

            <ProgressStep
              label="Review"
              description="The progress indicator will listen for clicks on the steps"
            />
          </ProgressIndicator>

          <Form>
            <Stack gap={5}>
              {currentIndex === 0 ? (
                <div className={`${COMPONENT_NAME}--form-items`}>
                  <div className="m-top">
                    <Select
                      defaultValue="placeholder-item"
                      id="supportedFormat"
                      onChange={e => handleCreateMappingChange(e)}
                      labelText="Supported format"
                      value={exportProfile.supportedFormat}
                      size="md">
                      <SelectItem
                        disabled
                        hidden
                        text="Choose an option"
                        value="placeholder-item"
                      />
                      {AVAILABLE_FORMAT.map((format, index) => (
                        <SelectItem text={format} value={format} key={index} />
                      ))}
                    </Select>
                  </div>
                  <div>
                    <div className="m-top">
                      <Toggle
                        labelA="No"
                        labelB="Yes"
                        toggled={exportProfile.ibmGoals}
                        id="ibmGoals"
                        labelText="IBM goals"
                        onToggle={e =>
                          setExportProfile({
                            ...exportProfile,
                            ibmGoals: e,
                          })
                        }
                      />
                    </div>
                    <div className="m-top">
                      <Toggle
                        toggled={exportProfile.controlGoals}
                        labelA="No"
                        labelB="Yes"
                        id="controlGoals"
                        labelText="Control with goals"
                        onToggle={e =>
                          setExportProfile({
                            ...exportProfile,
                            controlGoals: e,
                          })
                        }
                      />
                    </div>
                    <div className="m-top">
                      <Toggle
                        labelA="No"
                        labelB="Yes"
                        toggled={exportProfile.downloadProfile}
                        id="downloadProfile"
                        labelText="Download profile"
                        onToggle={e =>
                          setExportProfile({
                            ...exportProfile,
                            downloadProfile: e,
                            accountId: '',
                            apiKey: '',
                          })
                        }
                      />
                    </div>
                    {exportProfile.downloadProfile === false && (
                      <>
                        <div className="m-top">
                          <TextInput
                            id="accountId"
                            required
                            labelText="Account id"
                            onChange={e => handleCreateMappingChange(e)}
                            value={exportProfile.accountId}
                          />
                        </div>
                        <div className="m-top">
                          <TextInput
                            id="apiKey"
                            required
                            type="password"
                            labelText="API key"
                            onChange={e => handleCreateMappingChange(e)}
                            value={exportProfile.apiKey}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : currentIndex === 1 ? (
                <div className={`${COMPONENT_NAME}--form-items`}>
                  <div className="m-top" />
                  <p
                    style={{
                      fontSize: '13px',
                      marginBottom: '1rem',
                    }}>
                    Provide a unique description that will help you and other
                    members of your organization to know what is included in the
                    custom profile. For example, IBM Cloud services.
                  </p>
                  <TextInput
                    disabled
                    id="name"
                    required
                    labelText="Mapping name"
                    onChange={e => handleCreateMappingChange(e)}
                    value={exportProfile.name}
                  />
                  <div className="m-top" />
                  <TextArea
                    id="description"
                    labelText="Profile Description (optional)"
                    maxCount={100}
                    enableCounter={true}
                    onChange={e => handleCreateMappingChange(e)}
                    value={exportProfile.description}
                  />
                </div>
              ) : (
                <>
                  <div className="m-top" />
                  <Card>
                    <CardHeader
                      title="Type"
                      onEditClick={() => {
                        setCurrentIndex(0);
                      }}
                      onSaveClick={() => {}}
                      onCancelClick={() => {}}
                      editText="Edit"
                      saveText="Save"
                    />
                    <CardBody>
                      <Row>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Supported format
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {exportProfile.supportedFormat}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            IBM goals
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {exportProfile.ibmGoals === true ? 'Yes' : 'No'}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Control goals
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {exportProfile.controlGoals === true ? 'Yes' : 'No'}
                          </div>
                        </Column>
                        <Column lg={4}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Download profile
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {exportProfile.downloadProfile === true
                              ? 'Yes'
                              : 'No'}
                          </div>
                        </Column>
                        {exportProfile.downloadProfile === false && (
                          <>
                            <Column lg={6}>
                              <div
                                className={`${COMPONENT_NAME}--review-title`}>
                                Account id
                              </div>
                              <div
                                className={`${COMPONENT_NAME}--review-details`}>
                                {exportProfile.accountId}
                              </div>
                            </Column>
                            <Column lg={8}>
                              <div
                                className={`${COMPONENT_NAME}--review-title`}>
                                API key
                              </div>
                              <div
                                className={`${COMPONENT_NAME}--review-details`}>
                                {exportProfile.apiKey.split('').map(x => '*')}
                              </div>
                            </Column>
                          </>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader
                      title="Details"
                      onEditClick={() => {
                        setCurrentIndex(1);
                      }}
                      onSaveClick={() => {}}
                      onCancelClick={() => {}}
                      editText="Edit"
                      saveText="Save"
                    />
                    <CardBody>
                      <Row>
                        <Column lg={6}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Name
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {exportProfile.name}
                          </div>
                        </Column>
                        <Column lg={6}>
                          <div className={`${COMPONENT_NAME}--review-title`}>
                            Description
                          </div>
                          <div className={`${COMPONENT_NAME}--review-details`}>
                            {exportProfile.description || '-'}
                          </div>
                        </Column>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              )}
            </Stack>
          </Form>
          <Buttons />
        </div>
      </div>
    </div>
  );
};
export default ExportProfile;
