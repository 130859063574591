import React, { useEffect } from 'react';
import { Tile, Button } from '@carbon/react';
import { Login } from '@carbon/react/icons';
import { deleteAllCookies } from '../../api/common';

const LoginPage = () => {
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    deleteAllCookies();
  }, []);

  return (
    <div style={{ position: 'absolute', top: '25vh', left: '37vw' }}>
      <Tile
        light={false}
        style={{
          backgroundColor: '#fff',
          maxWidth: '26vw',
          borderColor: '#0f62fe',
          borderWidth: '1px',
          borderStyle: 'solid',
        }}>
        <p
          style={{
            color: '#0f62fe',
            fontWeight: '400',
            fontSize: '1.35rem',
            lineHeight: '1.29',
          }}>
          <br />
          Welcome to IBM Crosswalk - Controls Management Framework.
        </p>
        <br />
        <br />
        <a
          href={'/api/appid/login'}
          id="Login"
          style={{ color: '#000', textDecoration: 'none' }}>
          <Button kind="tertiary" size="field" renderIcon={Login}>
            Sign In
          </Button>
        </a>
        <br />
        <br />
        <br />
        <hr style={{ borderWidth: '0.1px' }} />
        <p
          style={{
            color: '#0f62fe',
            fontWeight: '400',
            fontSize: '.875rem',
            lineHeight: '1.43',
          }}>
          <br />
          Don't have the access to the tool or need help?
          <br />
          Contact taijindersingh@ibm.com or
          Polepeddi.L.V.Sainadha.Rakesh@ibm.com.
        </p>
        <br />
      </Tile>
    </div>
  );
};

export default LoginPage;
