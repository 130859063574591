import axios from '../helpers/apiHelper';
import { BASE_URL } from '../helpers/urls';

export const getMappings = async queryParams => {
  return await axios.get(`${BASE_URL}/resource/mappings/?${queryParams}`);
};

export const oscalDownload = async profile_id => {
  return await axios.post(`${BASE_URL}/profile/oscal/catalog/`, null, {
    params: {
      profile_id,
    },
  });
};

export const mappingDownload = async (profile_id, mapping_id) => {
  return await axios.get(
    `${BASE_URL}/profile/oscal/profile/${profile_id}/${mapping_id}`
  );
};

export const deleteMapping = async (mapping_id, mapping_type) => {
  return await axios.delete(
    `${BASE_URL}/resource/mappings/${mapping_id}`,
    null,
    {
      params: {
        mapping_type,
      },
    }
  );
};

export const getUserRoles = async accountId => {
  return await axios.get(`${BASE_URL}/accessgroup/users/${accountId}`);
};

export const assignUserRole = async (formData, mid) => {
  return await axios.post(
    `${BASE_URL}/resource/mapping-request/assign/${mid}`,
    formData
  );
};

export const getCrosswalkProfiles = async queryParams => {
  return await axios.get(`${BASE_URL}/crosswalk/profiles?${queryParams}`);
};

export const createCrosswalk = async (acc_id, formData) => {
  return await axios.post(
    `${BASE_URL}/crosswalk/request?account_id=${acc_id}`,
    formData
  );
};

export const getMappingHistory = async (mid, status) => {
  return await axios.get(
    `${BASE_URL}/resource/mappings-request/history/${mid}?history_type=${status}`
  );
};
