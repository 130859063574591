import React from 'react';
import { Modal } from '@carbon/react';

const SaveDataModal = ({ saveData, saveModal, setSaveModal }) => {
  return (
    <Modal
      open={saveModal}
      onRequestClose={() => setSaveModal(false)}
      onRequestSubmit={() => {
        saveData();
        setSaveModal(false);
      }}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      modalHeading="We highly recommend saving your data to ensure that your changes are intact."
    />
  );
};

export default SaveDataModal;
