import React from 'react';
import LandingContent from './content';
import Layout from '../common/Layout';

const LandingPage = props => {
  return (
    <Layout>
      <LandingContent accountDetails={props.accountDetails} />
    </Layout>
  );
};

export default LandingPage;
